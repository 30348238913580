import { Navigate, useRoutes } from "react-router-dom";
//CONFIG
import route_config from "../config/route_config";

// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

import ParentRegistration from "../pages/parent-registration/index";

//import requireAuth from './services/auth/auth-guard';

//LOGINS
import AdminLogin from "../pages/login/admin-login/index";
import ExpertLogin from "../pages/login/expert-login-v2/index";
import LearnerLogin from "../pages/login/learner-login-v2/index";
import ParentLogin from "../pages/login/parent-login-v2/index";
import JoinDemoSessionForm from "../pages/login/join-demo-session-form/index";
import JoinDemoSessionFormDev from "../pages/login/join-demo-session-form-dev/index";

import ForgotPassword from "../pages/forgot-password/forgot-password";

import BecomeAnExpert from "../pages/become-an-expert/index";
//MEMBERS
import ResetPassword from "../pages/reset-password/reset-password";

//HOME
//import Home from '../pages/home/home';
import MainCourseInfo from "../pages/home/courses-information/courses-information";
import HomeCourses from "../pages/home/courses/courses";

//ADMIN
import Parents from "../pages/admin/users/parents";
import Experts from "../pages/admin/users/experts";
import Learners from "../pages/admin/users/learners";
import Courses from "../pages/admin/course/courses/courses";
import AdminPods from "../pages/admin/pods/pods";

import Parent_Detail from "../pages/admin/users/parents-details/parents-details";
import CourseStepper from "../pages/admin/course/create-course/create-course-stepper";
import AdminCourseDetail from "../pages/admin/course/course-detail/course-detail";
import AdminCourseInfo from "../pages/admin/course/course-detail/course-info/course-info";
import AdminCourseContent from "../pages/admin/course/course-detail/course-content/course-content";
import AdminCoursePod from "../pages/admin/course/course-detail/course-pod/course-pod";
import Quiz from "../pages/admin/quiz/quiz";
import WhiteboardMaster from "../pages/admin/whiteboard-templates/whiteboard-templates";
import WhiteboardDetail from "../pages/admin/whiteboard-templates/whiteboard-template-detail";
import BlockRequests from "../pages/admin/users/block-requests/block-requests";
import ImageLibrary from "../pages/admin/image-library/image-library.js";
import ImageLibraryDetail from "../pages/admin/image-library/image-library-detail";
//EXPERTS
import MyCourses from "../pages/experts/my-courses/index";
import MySchedule from "../pages/experts/my-schedule/my-schedule";
import Pods from "../pages/experts/my-courses/pods/pods";
//import ManageGroup from '../pages/experts/manage-group/manage-group';
import ManageGroupTaskAssing from "../pages/experts/manage-group/group-space/task-assign"; //This one using as Manage Group in Experts
import Curriculum from "../pages/experts/curriculum/curriculum";
import MyProfile from "../pages/experts/my-profile/my-profile";
import ExpertsDetails from "../pages/admin/users/experts-details/experts-details";

//PARENTS
import MyKids from "../pages/parents/my-kids/my-kids";
import ParentRegistration_2 from "../pages/parents/register/register";
import ParentsMyCourses from "../pages/parents/my-courses/my-courses";
import ParentsPayments from "../pages/parents/payments/payments";
import ParentAllCourses from "../pages/parents/all-courses/all-courses";
import ParentCourseDetail from "../pages/parents/all-courses/course-detail/course-detail";
import ParentSelfLearning from "../pages/parents/self-learning/course-detail/course-detail";
import ParentSelfLearningBoards from "../pages/parents/self-learning/whiteboards/course-detail";
import ParentHome from "../pages/parents/home/home";

//LEARNERS
import LearnerMyCourses from "../pages/learners/my-courses/my-courses";
import CourseDetailWhiteBoard from "../pages/learners/self-learning/course-detail-white-board/course-detail-white-board";
import SelfLearningIndCourse from "../pages/learners/self-learning/courses-details-new/courses-details-new";
import CourseDetailGame from "../pages/learners/self-learning/course-detail-game/course-detail-game";
import LearnerMySchedule from "../pages/learners/my-schedule/my-schedule";
import Group_Space from "../pages/learners/group-space/task-assign";
import GroupSpaceV2 from "../pages/learners/group-space-v2/group-space-v2";
import LearnerPods from "../pages/learners/pods/pods";
import LearnersDetails from "../pages/admin/users/learners-details/learners-details";
import CoursesDescription from "../pages/learners/self-learning/courses-description-v4/courses-description-v4";
//GAMES
import Games from "../pages/admin/games/games";

//CUSTOMER DATA
import CustomersData from "../pages/admin/customer-data/customer-data";

//COMMON COURSE DETAIL
import CourseDetail from "../pages/courses/courses-details/course-details";
import ActivateUser from "../pages/activate-user/activate-user";
import InviteUser from "../pages/invite-user/invite-user";

import NotFound from "../pages/page404";
import Layout_2 from "../layouts/layout_2";
//new
import Layout_V2 from "../layouts/layout_v2";
import ClassRoom from "../pages/experts/sessions/class-room/class-room";
import ClassRoomV2 from "../pages/experts/sessions/class-room-v2/class-room";
import BreakOut from "../pages/experts/sessions/breakout-room/breakout-room";

import LearnerBreakoutRoom from "../pages/learners/sessions/breakout-room/breakout-room";
//import LearnerClassRoom from '../pages/learners/sessions/class-room/class-room';
import LearnerClassRoom from "../pages/learners/sessions/class-room-v2/class-room";
import DemoClassRoom from "../pages/learners/sessions/demo-class-room/demo-class-room";

import UserProfile from "../pages/user-profile/user-profile";

//WHITEBOARD
import Whiteboard from "../components/white-board/container/Container.jsx";

import PrivacyPolicy from "../pages/privacy-policy/privacy-policy";
//HOME PAGE VERSION 2
import Home from "../pages/home-v2/home";
import DynTabs from "../pages/learners/dyn-tabs/dyn-tabs";
import Homev3 from "../pages/home-v3/home-v3";
import HomeSelfLearning from "../pages/home-self-learning/home-self-learning";
// ----------------------------------------------------------------------

import Checkout from "../pages/payments/checkout";
import PaymentSuccess from "../pages/payments/payment-success";

import StaticContnet from "../pages/content/content";

import CourseDetailsV2 from "../pages/courses/courses-details-v2/course-details-v2";
import ParentRegisterV2 from "../pages/parents/register-v2/register-v2";

import SuccessPage from "../components/success-page/success-page";
import ShareLicensesSuccessPage from "../components/share-licenses-success-page/share-licenses-success-page.js";

import TermsAndConditions from "../pages/terms-and-conditions/terms-and-conditions.js";

import ShareLicensesDetails from "../pages/parents/share-licenses-details/share-licenses-details.js";
import Sessions from "../pages/admin/sessions/sessions.js";
import SessionsDetails from "../pages/admin/sessions/sesssions-details.js";
import ZoomSessionLogs from "../pages/admin/sessions/zoom-session-logs.js";

import SelfLearningParentRegister from "../pages/parents/self-learning-parent-register/self-learning-parent-register.js";

import Subscription from "../components/subscription/subscription.js";

import SelfLearningSuccessPage from "../components/self-learning-success-page/self-learning-success-page.js";

import Homev4 from "../pages/home-v4/home-v4.js";

import UserGuide from "../pages/user-guide/user-guide.js";
import WallPost from "../pages/learners/self-learning/wall-posts/wall-posts.js";
import AllWallPostsForAdmin from "../pages/admin/all-wall-posts-for-admin/all-wall-posts-for-admin.js";

export default function Router() {
  return useRoutes([
    // {
    //   path: 'dyn-tabs',
    //   element: <DynTabs />,
    // },
    // {
    //   path: 'home-v2',
    //   element: <Home />,
    // },
    //HOME
    {
      path: "content",
      element: <StaticContnet />,
    },
    {
      path: "c-detail",
      element: <SelfLearningIndCourse />,
    },

    {
      path: route_config.home.termsAndConditions,
      element: <TermsAndConditions />,
    },

    {
      path: route_config.home.userGuide,
      element: <UserGuide />,
    },
    // {
    //   path: 'home',
    //   element: <Home />,
    // },
    // {
    //   path: "home",
    //   element: <Homev3 />,
    // },
    {
      path: "home",
      element: <Homev4 />,
    },
    {
      path: "home-v3",
      element: <HomeSelfLearning />,
    },
    {
      path: "subscription",
      element: <Subscription />,
    },
    {
      path: "self-learning-success",
      element: <SelfLearningSuccessPage />,
    },
    {
      path: "course-detail/:slug",
      element: <CourseDetailsV2 />,
    },
    {
      path: route_config.parent.registration,
      element: <ParentRegisterV2 />,
    },
    {
      path: "checkout",
      element: <Checkout />,
    },
    {
      path: "payment-success",
      element: <PaymentSuccess />,
    },
    {
      path: "courses/:token",
      element: <HomeCourses />,
    },
    //COURSE INFO
    {
      path: "course-info",
      element: <MainCourseInfo />,
    },
    // ADMIN
    {
      path: route_config.admin.users.main_route_name,
      element: <DashboardLayout />,
      children: [
        { path: route_config.admin.users.parents, element: <Parents /> },
        { path: route_config.admin.users.experts, element: <Experts /> },
        { path: route_config.admin.users.learners, element: <Learners /> },
        {
          path: route_config.admin.users.parents + "/:id",
          element: <Parent_Detail />,
        },
        {
          path: route_config.admin.users.block_requests,
          element: <BlockRequests />,
        },
        {
          path: route_config.admin.users.learners + "/:id",
          element: <LearnersDetails />,
        },
        { path: "expert-details", element: <ExpertsDetails /> },
        { path: "learner-details", element: <LearnersDetails /> },
      ],
    },
    {
      path: route_config.admin.pods,
      element: <DashboardLayout />,
      children: [{ path: "", element: <AdminPods /> }],
    },
    {
      path: route_config.admin.games,
      element: <DashboardLayout />,
      children: [{ path: "", element: <Games /> }],
    },
    {
      path: route_config.admin.quiz,
      element: <DashboardLayout />,
      children: [{ path: "", element: <Quiz /> }],
    },
    {
      path: route_config.admin.whiteboard_templates,
      element: <DashboardLayout />,
      children: [{ path: "", element: <WhiteboardMaster /> }],
    },
    {
      path: "whiteboard-detail/:id",
      element: <WhiteboardDetail />,
    },
    {
      path: "image-library-preview/:slug",
      element: <ImageLibraryDetail />,
    },

    {
      path: route_config.admin.imageLibrary,
      element: <DashboardLayout />,
      children: [{ path: "", element: <ImageLibrary /> }],
    },
    {
      path: route_config.admin.courses.main_route_name,
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Courses /> },
        {
          path: route_config.admin.courses.create_course,
          element: <CourseStepper />,
        },
        {
          path: route_config.admin.courses.course_info + "/:courseid",
          element: <AdminCourseInfo />,
        },
        {
          path: route_config.admin.courses.course_content + "/:courseid",
          element: <AdminCourseContent />,
        },
        {
          path: route_config.admin.courses.course_pod + "/:courseid",
          element: <AdminCoursePod />,
        },
        {
          path: route_config.admin.courses.course_detail + "/:courseid",
          element: <AdminCourseDetail />,
        },
      ],
    },
    {
      path: route_config.admin.customers,
      element: <DashboardLayout />,
      children: [{ path: "", element: <CustomersData /> }],
    },

    {
      path: route_config.admin.share_licenses_details,
      element: <DashboardLayout />,
      children: [{ path: "", element: <ShareLicensesDetails /> }],
    },
    {
      path: route_config.admin.sessions,
      element: <DashboardLayout />,
      children: [{ path: "", element: <Sessions /> }],
    },

    {
      path: route_config.admin.wall,
      element: <DashboardLayout />,
      children: [{ path: "", element: <AllWallPostsForAdmin /> }],
    },

    {
      path: route_config.admin.sessions + "/:id",
      element: <DashboardLayout />,
      children: [{ path: "", element: <SessionsDetails /> }],
    },

    {
      path: "zoom-session-logs/:slug",
      element: <DashboardLayout />,
      children: [{ path: "", element: <ZoomSessionLogs /> }],
    },
    // PARENT
    {
      path: route_config.parent.main_route_name,
      element: <DashboardLayout />,
      children: [
        { path: route_config.parent.my_kids, element: <MyKids /> },
        {
          path: route_config.parent.all_courses,
          element: <ParentAllCourses />,
        },
        { path: route_config.parent.my_courses, element: <ParentsMyCourses /> },
        { path: route_config.parent.self_learning, element: <ParentSelfLearning /> },
        { path: route_config.parent.self_learning_boards, element: <ParentSelfLearningBoards /> },

        { path: route_config.parent.payments, element: <ParentsPayments /> },
        {
          path: route_config.parent.all_courses_detail + "/:courseid",
          element: <ParentCourseDetail />,
        },
        { path: route_config.parent.my_profile, element: <UserProfile /> },

        {
          path: route_config.parent.share_licenses_details,
          element: <ShareLicensesDetails />,
        },
        {
          path: route_config.parent.courses_description + "/:gameName",
          element: <CoursesDescription />,
        },

      ],
    },
    // EXPERT
    {
      path: route_config.expert.main_route_name,
      element: <DashboardLayout />,
      children: [
        { path: route_config.expert.my_courses, element: <MyCourses /> },
        { path: route_config.expert.my_schedule, element: <MySchedule /> },
        { path: route_config.expert.course_detail, element: <CourseDetail /> },
        { path: route_config.expert.pods + "/:id", element: <Pods /> },
        {
          path: route_config.expert.manage_groups + "/:courseid/:streamId",
          element: <ManageGroupTaskAssing />,
        },
        {
          path: route_config.expert.curriculum + "/:streamId",
          element: <Curriculum />,
        },
        //{ path: route_config.expert.my_profile, element: <MyProfile /> },
        { path: route_config.expert.my_profile, element: <UserProfile /> },
      ],
    },
    {
      path: "expert/group-space/:slug",
      element: <GroupSpaceV2 />,
    },
    // LEARNER
    {
      path: "learner/group-space/:slug",
      element: <GroupSpaceV2 />,
    },
    {
      path: route_config.learner.main_route_name,
      element: <DashboardLayout />,
      children: [
        { path: route_config.learner.course_detail, element: <CourseDetail /> },
        {
          path: route_config.learner.my_courses,
          element: <LearnerMyCourses />,
        },

        {
          path: route_config.learner.challenges,
          element: <CourseDetailWhiteBoard />,
        },
        {
          path: route_config.learner.wall_posts,
          element: <WallPost />,
        },
        {
          path: route_config.learner.missions,
          element: <CourseDetailGame />,
        },

        {
          path: route_config.learner.courses_description + "/:gameName",
          element: <CoursesDescription />,
        },

        // {
        //   path: route_config.learner.my_schedule,
        //   element: <LearnerMySchedule />,
        // },
        { path: route_config.learner.pods, element: <LearnerPods /> },
        //{ path: route_config.learner.group_space + '/:slug', element: <GroupSpaceV2 /> },
        {
          path: route_config.learner.group_space + "/:streamId",
          element: <Group_Space />,
        },
        { path: route_config.learner.my_profile, element: <UserProfile /> },
      ],
    },
    {
      path: "admin-login",
      element: <AdminLogin />,
    },
    {
      path: "expert-login",
      element: <ExpertLogin />,
    },
    {
      path: "learner-login",
      element: <LearnerLogin />,
    },
    {
      path: "expert-session-v1",
      element: <Layout_2 />,
      children: [
        { path: "break-out-room", element: <BreakOut /> },
        { path: "classroom/:slug", element: <ClassRoom /> },
      ],
    },
    {
      path: "expert-session",
      element: <Layout_V2 />,
      children: [
        { path: "break-out-room", element: <BreakOut /> },
        { path: "classroom/:slug", element: <ClassRoomV2 /> },
      ],
    },
    // {
    //   path: "learner-session",
    //   element: <Layout_2 />,
    //   children: [
    //     { path: ":slug", element: <LearnerClassRoom /> },
    //     { path: "LearnerBreakoutRoom", element: <LearnerBreakoutRoom /> },
    //   ],
    // },
    {
      path: "learner-session",
      children: [
        { path: ":slug", element: <LearnerClassRoom /> },
        { path: "LearnerBreakoutRoom", element: <LearnerBreakoutRoom /> },
      ],
    },
    // {
    //   path: 'join-demo/:streamId/:slug',
    //   element: <JoinDemoSessionForm />,
    // },
    {
      path: "join-demo-dev/:streamId/:slug",
      element: <JoinDemoSessionFormDev />,
    },
    {
      path: "demo-session",
      children: [
        { path: ":slug", element: <LearnerClassRoom /> },
        { path: "LearnerBreakoutRoom", element: <LearnerBreakoutRoom /> },
      ],
    },
    // {
    //   path: "demo-session",
    //   element: <Layout_2 />,
    //   children: [
    //     { path: ":slug", element: <LearnerClassRoom /> },
    //     { path: "LearnerBreakoutRoom", element: <LearnerBreakoutRoom /> },
    //   ],
    // },
    {
      path: "parent-login",
      element: <ParentLogin />,
    },
    {
      path: "whiteboard",
      element: <Whiteboard />,
    },
    {
      path: "activate-user/:id",
      element: <ActivateUser />,
    },
    // {
    //   path: 'parent-registration',
    //   element: <ParentRegistration />,
    // },
    {
      path: "success",
      element: <SuccessPage />,
    },
    {
      path: "share-licenses-success/:token",
      element: <ShareLicensesSuccessPage />,
    },
    {
      path: "parent-registration-2",
      element: <ParentRegistration_2 />,
    },
    {
      path: "self-learning-parent-registration",
      element: <SelfLearningParentRegister />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "reset-password/:token",
      element: <ResetPassword />,
    },
    {
      path: "become-expert",
      element: <BecomeAnExpert />,
    },
    {
      path: "invite-user/:token",
      element: <InviteUser />,
    },
    {
      path: route_config.home.privacyPolicy,
      element: <PrivacyPolicy />,
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/home" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
