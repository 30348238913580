import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

const WallPostConfirmationDialog = ({ open, onClose, onConfirm, message }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
                <p>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    No
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WallPostConfirmationDialog;
