import React from "react";
import { Container, Button, Box, Card, Typography, Avatar, IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { getCurrentUserData } from "../../utils/util";
import roleConfig from "../../config/conditional_config";
import "./wall-post-common-card.css";
import WallPostConfirmationDialog from "./wall-post-confirmation-dialog";

export default function WallPostCommonCard({
    paginatedPosts,
    itemsPerPage,
    wallPosts,
    currentPage,
    handlePageChange,
    deleteYourPost,
    updatePostStatePuplished,
    updatePostStateDeactivate,
}) {
    // Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // States for delete and deactivate
    const [postIdToDelete, setPostIdToDelete] = React.useState(null);
    const [postIdToDeactivate, setPostIdToDeactivate] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDeactivateDialog, setOpenDeactivateDialog] = React.useState(false);

    // Menu Handlers
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Dialog Handlers for Delete
    const handleOpenDialog = (postId) => {
        setPostIdToDelete(postId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPostIdToDelete(null);
        handleClose();
    };

    const handleConfirmDelete = () => {
        if (postIdToDelete) {
            deleteYourPost(postIdToDelete);
        }
        handleCloseDialog();
    };

    // Dialog Handlers for Deactivate
    const handleOpenDeactivateDialog = (postId) => {
        setPostIdToDeactivate(postId);
        setOpenDeactivateDialog(true);
    };

    const handleCloseDeactivateDialog = () => {
        setOpenDeactivateDialog(false);
        setPostIdToDeactivate(null);
        handleClose();
    };

    const handleConfirmDeactivate = () => {
        if (postIdToDeactivate) {
            updatePostStateDeactivate(postIdToDeactivate);
        }
        handleCloseDeactivateDialog();
    };

    return (
        <div className="self-learning-main-container pt-3 pb-3">
            <Box sx={{ width: "100%", mt: 3 }}>
                {paginatedPosts.length > 0 ? (
                    paginatedPosts.map((post) => (
                        <Card key={post.id} className="wall-post-card">
                            <div className="wall-card-text-div pb-0">
                                <div className="d-flex align-items-center">
                                    <Avatar src={post.documentPath} alt={post.userName} />
                                    <div className="ms-2">
                                        <p className="wall-post-user-name mb-0">{post.userName}</p>
                                        <p className="wall-post-created-time mb-0">
                                            {post.formatted_created_at}
                                        </p>
                                    </div>

                                    <div className="d-flex align-items-center ms-auto">
                                        {getCurrentUserData().role == roleConfig.role.admin && (
                                            <div>
                                                {post.post_states == 1 && (
                                                    <Button variant="contained"
                                                        className="post-publish-Button me-2"
                                                        onClick={() => updatePostStatePuplished(post.id)}
                                                    >
                                                        Publish
                                                    </Button>
                                                )}
                                                {post.post_states == 2 && (
                                                    <Button color="error" variant="contained"
                                                        className="post-deactivate-Button me-2"
                                                        onClick={() => handleOpenDeactivateDialog(post.id)} // Open deactivate dialog
                                                    >
                                                        Deactivate
                                                    </Button>
                                                )}
                                            </div>
                                        )}

                                        {getCurrentUserData().id === post.user_id && (
                                            <div>
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-Button"
                                                    aria-controls={open ? "long-menu" : undefined}
                                                    aria-expanded={open ? "true" : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        "aria-labelledby": "long-Button",
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem
                                                        className="menu-list-for-wall-post"
                                                        onClick={() => handleOpenDialog(post.id)} // Open delete dialog
                                                    >
                                                        Delete your post
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <p className="wall-post-des mt-3 mb-3">{post.title}</p>
                            </div>
                            {post.media_url && (
                                <div className="wall-post-image-div">
                                    <img
                                        className="wall-post-image"
                                        src={post.media_url}
                                        alt={post.file_name}
                                    />
                                </div>
                            )}
                        </Card>
                    ))
                ) : (
                    <Typography variant="body1" sx={{ color: "gray" }}>
                        No wall posts available.
                    </Typography>
                )}
            </Box>

            {wallPosts.length > itemsPerPage && (
                <Box display="flex" justifyContent="center" mt={3} mb={3}>
                    <Pagination
                        count={Math.ceil(wallPosts.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}

            {/* Confirmation Dialog for Delete */}
            <WallPostConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this post?"
            />

            {/* Confirmation Dialog for Deactivate */}
            <WallPostConfirmationDialog
                open={openDeactivateDialog}
                onClose={handleCloseDeactivateDialog}
                onConfirm={handleConfirmDeactivate}
                message="Are you sure you want to deactivate this post?"
            />
        </div>
    );
}
