import http from "../router/http-common";
import authHeader from "./auth/auth-header";
import api_path from "../config/api";



const getAllWallPost = () => {
    return http.get(api_path.getAllWallPost, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllPublishedWallPosts = () => {
    return http.get(api_path.getAllPublishedWallPosts, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};


// Function to delete a post
const deletePost = (id) => {
    return http.delete(api_path.deletePost.replace(":id", id), { headers: authHeader() })
        .then(response => {
            return response.data; // you can return the response or any success message
        })
        .catch(error => {
            console.error("Error deleting post", error);
            throw error; // You can handle the error in the component where this function is used
        });
};

// Function to update the state of a post
const updatePostState = (id, state) => {
    return http.post(
        api_path.updatePostState,
        { id: id, state: state }, // Data in the request body
        { headers: authHeader() }
    );
};






const WallPostService = {
    getAllWallPost,
    getAllPublishedWallPosts,
    updatePostState,
    deletePost,
};
export default WallPostService;