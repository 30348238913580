const api_route_config = {
  adminPath: "/admin",
  coursePath: "/courses",
  expertPath: "/expert",
  parentPath: "/parents",
  learnerPath: "/learners",
  documentPath: "/document",
  quizTemplate: "/quiztemplate",
  activeLookUpPath: "/lookups/getAllActive",
  puzzle: "/puzzle",
  memberPath: "/members",
  membersAccessPath: "/membersaccess",
  memberCoursePath: "/membercourses",
  memberCourseGroup: "/membercoursegroup",
  memberCourseAssignment: "/membercourseassignment",
  whiteboardTemplates: "/whiteboard-templates",
  memberStreamBoard: "/member-stream-whiteboard",
  lookups: "/lookups",
  sessionChats: "/sessionChats",
  sessionQuiz: "/sessionQuiz",
  imageLibraries: "/image-libraries",
  tempCustomersPath: "/tempcustomers",
  shareLicensesPath: "/sharelicenses",
  zoomSessionLogsPath: "/zoomSessionLogs",
  selfLearningPath: "/self-learning",
  userVerificationOtpsPath: "/userVerificationOtps",
  wallPostPath: "/wall-post-details"
};

export default api_route_config;
