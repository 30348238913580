import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import slugify from 'react-slugify';
import * as Yup from 'yup';
import * as nsfwjs from 'nsfwjs';
import Filter from 'bad-words';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import "./course-detail-game.css"
import SelfLearningService from "../../../../services/self-learning-service";
import { checkIsEmdedCode, copyToClipBoardWithMsg, findTypeOfMessage, getChats, getCurrentUserData, getInitialsFromFullName, groupBy, storeChats, validateEmail, copyToClipBoardWithMsgWithOutAlert, isAddressIncluded, checkForPlacesInfo } from "../../../../utils/util";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from "@mui/icons-material/Close";
import games from "../../../../config/games";
import assets from "../../../../config/assets";
import conditional_configs from "../../../../config/conditional_config";
import UtilService from "../../../../services/util.service";
import game_config from "../../../../config/game_config";
import io from "socket.io-client";
import url_configs from "../../../../config/url";
import GameService from "../../../../services/games-service";
import { fDate } from "../../../../utils/formatTime";
import { copyToClipBoard } from "../../../../utils/util"
import FirebaseService from "../../../../services/firebase-service";
import Iconify from "../../../../components/Iconify";
import icons_config from "../../../../config/icons";
import SessionService from "../../../../services/session-service";
import storage_configs from "../../../../config/storage-config";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PaymentService from "../../../../services/payment-service";
import navigate_route_config from "../../../../config/navigate_route_config";
import { CircularProgress } from '@mui/material'; // For the loader spinner
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import HelpIcon from '@mui/icons-material/Help';
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";

import { useLocation } from 'react-router-dom';
import TourManager from "../../../../components/tour-manager/tour-manager";


let b_room_socket;
let chat_server_socket;
let friends_server_socket;

//#region MUI TABS
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//#endregion

//#region MUI Card
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
//#endregion
export default function CourseDetailGame() {
  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;
  const { enqueueSnackbar } = useSnackbar();

  //#region framer motion

  const [showHelp, setShowHelp] = useState(false);
  const [clickedGameDetail, setClickedGameDetail] = useState(null);
  const [activeHelpContentDiv, setActiveHelpContentDiv] = useState("");

  const backdropVariants = {
    visible: {
      opacity: [0, 0.5, 1],
      scale: [1, 1.05, 1],
      transition: { duration: 0.8, ease: "easeInOut" }
    },
    hidden: {
      opacity: [1, 0.5, 0],
      scale: [1, 0.95, 0.9],
      transition: { duration: 0.8, ease: "easeInOut" }
    },
  };

  const modalVariants = {
    hidden: {
      y: "-100vh",
      rotate: -10,
      opacity: 0,
      scale: 0.8
    },
    visible: {
      y: "0",
      rotate: [0, 5, -3, 0],
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 8,
        duration: 1
      }
    },
  };


  useEffect(() => {
    // Set the initial active button based on the available buttons
    if (clickedGameDetail?.help_for_game_setting) {
      setActiveHelpContentDiv("save-settings");
    } else {
      // Default to the next available button, like 'how-to-play'
      setActiveHelpContentDiv("how-to-play");
    }
  }, [clickedGameDetail?.help_for_game_setting]);

  // Function to handle button click
  const handleButtonClick = (tabName) => {
    setActiveHelpContentDiv(tabName);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }



  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };



  //#endregion framer motion


  // const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [myGames, setMyGames] = React.useState([]);
  const [myBoards, setMyBoards] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [gameResults, setGameResults] = React.useState([]);
  const [openGameShareCodePopup, setGameShareCodePopup] = useState(false);
  const [openedContent, setOpenedContent] = useState();
  const [openedGroupData, setOpenedGroupData] = useState();
  const [openJoinWithCodePopup, setOpenJoinWithCodePopup] = useState(false);
  const [openGameSettings, setOpenGameSettings] = useState(false);
  const [openLeaderBoard, setOpenLeaderBoard] = useState(false);
  const [gameSettingLink, setGameSettingLink] = useState();

  const [openJoinWithCodeGame, setOpenJoinWithCodeGame] = useState();

  //WHITEBOARD TAB DECLARATIONS
  const [openBoardShareCodePopup, setOpenBoardShareCodePopup] = useState(false);
  const [openWhiteboardTabScreen, setOpenWhiteboardTabScreen] = useState(false);
  const [whiteBoardTabList, setWhiteBoardTabList] = React.useState([]);
  const [whiteboardLink, setWhiteboardLink] = React.useState();
  const [whiteboardKey, setWhiteboardKey] = React.useState();

  //CHATS
  const [enableChatWindow, setEnableChatWindow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [privateMessageCount, setPrivateMessageCount] = useState([]);
  const [groupMessageCount, setGroupMessageCount] = useState([]);
  const [planMessagecount, setplanMessagecount] = useState(0);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState();
  const [remainingMessageCount, setRemainingMessageCount] = useState();

  const [isLoading, setIsLoading] = useState(true); // Loader state


  //MY FRIENDS LIST
  const [myIntractedFriendsList, setMyIntractedFriendsList] = React.useState([]);
  const [myChatFriendsList, setMyChatFriendsList] = React.useState([]);
  const [myFriendsAnchorEl, setMyFriendsAnchorEl] = useState();
  const [myFriendsCtrlMenu, setMyFriendsCtrlMenu] = useState(false);
  const [openIndividualChat, setOpenIndividualChat] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [inviteFriendPopup, setInviteFriendPopup] = useState(false);
  const [userName, setUserName] = useState(false);

  const [myChatInviteList, setMyChatInviteList] = useState([]);

  const [subscriptionStatus, setSubscriptionStatus] = useState();

  const [showSuccessImage, setShowSuccessImage] = useState(false);

  //LEADERBOARD
  const [currentLeaderBoard, setCurrentLeaderBoard] = useState([]);

  const [joinWithCodeErrorMessage, setJoinWithCodeErrorMessage] = useState("");



  const navigate = useNavigate();
  const filter = new Filter();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const game_card_img = {
    backgroundImage: `url(${assets.course_v4.game_card_img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "15px",
    paddingBottom: "50px",
    width: "100%",
    height: "100%"
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //Get course details
  useEffect(() => {
    getCourseDetailByLearnerId();
    socketConnections();
    getChatFriends();
    getIntractedFriends();
    getMyInvites();
    getLeaderBoardForGamesByKid();

    // Clear the is-currently-playing-game
    localStorage.removeItem("is-currently-playing-game");

    // Initial fetch
    fetchMessageCounts();
  }, []);

  useEffect(() => {
    friends_server_socket = io(url_configs.chat_server);
    friends_server_socket.on('private_message', (data) => {
      console.log(data);
      const { sender_id, message } = data;
      console.log(`Received message from ${sender_id}: ${message}`);
      enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      //update unread message count
      getChatFriends();
      // Only display messages from the friend
      if (sender_id === openIndividualChat.learner.id) {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    });
    getMySubscriptionDetail();
    return () => {
      friends_server_socket.disconnect();
    };
  }, [openIndividualChat ? openIndividualChat.learner.id : ""]);


  // Function to fetch message counts at intervals
  const fetchMessageCounts = () => {
    getCurrentUserPrivateMessageById();
    getCurrentUserGroupMessageById();
  };

  /** SOCKET CONNECTIONS */
  const socketConnections = () => {
    b_room_socket = io(url_configs.breakout_room_server);
    // b_room_socket.emit("join", { name: getCurrentUserData().name, sessionSlug: openedGroupData.code, room: null, role: 0 }, (error) => {
    //   if (error) {
    //     console.log(error)
    //     //alert(error);
    //   }
    // });

    // b_room_socket.on("auto-play-game", (obj) => {
    //   let tempGameObj = getGameDetail(obj.data.game.id);

    //   //setIsLoading(true);
    //   setTimeout(() => {
    //     getGameLink(tempGameObj);
    //     // let tempScreenData = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.currentScreenData));
    //     // if (tempScreenData) {
    //     //   tempScreenData.screentype = "startgame";
    //     //   setBreakoutCurrentScreenData(tempScreenData);
    //     //   localStorage.setItem(storage_configs.localStorage.sessions.currentScreenData, JSON.stringify(tempScreenData));
    //     // }
    //   }, conditional_configs.loader.timeout);
    // });


    b_room_socket.on("auto-play-game", (obj) => {

      const isCurrentlyPlayingGame = localStorage.getItem('is-currently-playing-game');

      if (isCurrentlyPlayingGame !== 'true' || !isCurrentlyPlayingGame) {
        localStorage.setItem('is-currently-playing-game', true);
        let tempGameObj = getGameDetail(obj.data.game.id);
        setTimeout(() => {
          getGameLink(tempGameObj);
        }, conditional_configs.loader.timeout);
      }
    });


    b_room_socket.on("joined-self-learner", (obj) => {
      getPlayersList();
    });
  }

  /** GET GAME PLAY LINK */
  const getGameLink = (gameData) => {
    if (gameData) {
      const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
      const tempOpenedContent = JSON.parse(localStorage.getItem('openedContent'));
      //Group Detail
      let groupDetail = {
        id: tempOpenedGroupData.id,
        name: tempOpenedGroupData.code,
      }

      //Learner Detail
      let learnerDetail = {
        id: getCurrentUserData().id,
        name: getCurrentUserData().name,
        groupDetail: groupDetail
      }

      //Game Detail
      let gameDetail = {
        id: tempOpenedContent.game_id,
        name: getGameDetail(tempOpenedContent?.game_id)?.name
      }

      //Game Settings
      let gameSettings = {};

      GameService.GetGameSettingByGroupId(groupDetail.id, gameDetail.id).then(response => {
        console.log(response)
        if (response && response.data && response.data.length > 0) {
          gameSettings = response.data[0].learnersettings[0];
          console.log('Game Settings');
          console.log(gameSettings);


          let tempJsonObj = {
            learnerDetail: learnerDetail,
            gameDetail: gameDetail,
            userType: game_config.userType.student,
            gameSettings: gameSettings,
            slug: tempOpenedGroupData.code,
            courseStreamId: null,
            isInSession: true
          }


          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList
            }
            console.log('My Game Obj')
            console.log(model);
            UtilService.encryptJsonValue(model).then(response => {
              console.log(response)
              if (response && response.data) {
                setGameSettingLink("");
                let tempGameLink = '';
                tempGameLink = gameData.route + response.data.token;
                setTimeout(() => {
                  setGameSettingLink(tempGameLink);
                }, conditional_configs.loader.timeout);
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        } else {
          //gameSettings = response.data[0].learnersettings[0];
          console.log('Game Settings');
          console.log(gameSettings);

          let tempJsonObj = {
            learnerDetail: learnerDetail,
            gameDetail: gameDetail,
            userType: game_config.userType.student,
            gameSettings: gameSettings,
            slug: tempOpenedGroupData.code,
            courseStreamId: null,
            isInSession: true
          }
          console.log('My Game Obj')
          console.log(tempJsonObj);
          if (tempJsonObj) {
            let tempJsonValueList = [];
            tempJsonValueList.push(tempJsonObj);

            let model = {
              jsonvalue: tempJsonValueList
            }

            console.log(model);
            UtilService.encryptJsonValue(model).then(response => {
              console.log(response)
              if (response && response.data) {
                setGameSettingLink("");
                let tempGameLink = '';
                tempGameLink = gameData.route + response.data.token;
                setTimeout(() => {
                  setGameSettingLink(tempGameLink);
                }, conditional_configs.loader.timeout);
              }
            })
              .catch(e => {
                console.log(e);
              });
          }
        }
      })
        .catch(e => {
          console.log(e);
        });

    }
  }

  /** GET COURSE DETAIL */
  const getCourseDetailByLearnerId = () => {
    setIsLoading(true); // Show loader while fetching data
    SelfLearningService.getCourseDetailByLearnerId(getCurrentUserData().id).then(response => {
      console.log('getCourseDetailByLearnerId::', response);
      if (response && response.success == "true") {
        setMyGames(response.data.games);
        setMyBoards(response.data.boards)
      }
    })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader after data is fetched
      });
  }

  const getGameDetail = (id) => {
    if (id)
      return games.gameList.filter(e => e.id == id)[0];
  }

  const getPlayersList = () => {
    const tempOpenedData = JSON.parse(localStorage.getItem('openedGroupData'));
    if (tempOpenedData) {
      var shareId;
      if (tempOpenedData.content_type == conditional_configs.curriculam_types.games) {
        shareId = tempOpenedData.game_id
      }

      if (tempOpenedData.content_type == conditional_configs.curriculam_types.whiteboard) {
        shareId = tempOpenedData.self_learning_board_id
      }
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(tempOpenedData.course_id, getCurrentUserData().id, shareId, tempOpenedData.content_type).then(response => {
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const location = useLocation();


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('openDialog') === 'true') {

      setTimeout(() => {
        const clickedDetail = JSON.parse(localStorage.getItem("clickedGameDetail"));
        if (clickedDetail) {

          const obj = clickedDetail;
          setClickedGameDetail(clickedDetail);
          if (obj) {
            setGameShareCodePopup(true);
            setEnableChatWindow(false);
            setOpenedContent(obj);
            setPlayers([]);

            if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.singlPlayerWebsite) {
              setGameSettingLink("");
              setOpenGameSettings(true);
              setGameSettingLink(getGameDetail(obj.game_id).route);
              return;
            }
            SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.game_id, conditional_configs.curriculam_types.games).then(response => {
              console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
              if (response && response.data && response.success == "true") {
                setPlayers(response.data.players);
                setOpenedGroupData(response.data.groupData);
                localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
                if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.mutiPlayerGame) {
                  socketConnections();
                }
                // joinToGeneralChat();
              }
            })
              .catch(e => {
                console.log(e);
              });
          }

        }

      }, 1000)

    }
  }, [location.search]);


  /** HANDLE OPEN **GAME** TAB SHARE CODE POPUP */
  const handleOpenGameShareCodePopup = (e, obj) => {
    if (((obj.isFree && subscriptionStatus == "trialing") || subscriptionStatus == "active") && getGameDetail(obj.game_id).domainName !== "looper") {
      setEnableChatWindow(false);
      setGameShareCodePopup(true);
      setOpenedContent(obj);

      // Add query parameter
      const currentPath = window.location.pathname;
      navigate(`${currentPath}?openDialog=true`);

      localStorage.setItem('clickedGameDetail', JSON.stringify(obj));

      setTimeout(() => {
        const clickedDetail = JSON.parse(localStorage.getItem("clickedGameDetail"));
        if (clickedDetail) {
          setClickedGameDetail(clickedDetail);
        }
      }, 1000)

      setPlayers([]);
      if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.singlPlayerWebsite) {
        setGameSettingLink("");
        setOpenGameSettings(true);
        setGameSettingLink(getGameDetail(obj.game_id).route);
        return;
      }
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.game_id, conditional_configs.curriculam_types.games).then(response => {
        console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
          setOpenedGroupData(response.data.groupData);
          localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
          if (getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.mutiPlayerGame) {
            socketConnections();
          }
          joinToGeneralChat();
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  };

  /** HANDLE CLOSE **GAME** TAB SHARE CODE POPUP */
  const handleGamesShareCodePopupClose = () => {
    setGameShareCodePopup(false);
    setOpenedGroupData(null);
    setCopyMessage('');
    localStorage.removeItem("openedGroupData");
    localStorage.removeItem("clickedGameDetail");

    // Remove query parameter
    const currentPath = window.location.pathname;
    navigate(currentPath, { replace: true });

    setEnableChatWindow(false);

    disconnectChatServer();

    if (openGameSettings) {
      setOpenGameSettings(false);
      window.location.reload();
      // Clear the is-currently-playing-game
      localStorage.removeItem("is-currently-playing-game");
    }
  };

  /** HANDLE OPEN **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleOpenBoardShareCodePopup = (e, obj) => {
    if ((obj.isFree && subscriptionStatus == "trialing") || subscriptionStatus == "active") {
      setEnableChatWindow(false);
      setOpenBoardShareCodePopup(true);
      setOpenedContent(obj);
      setPlayers([]);
      localStorage.setItem('openedContent', JSON.stringify(obj));
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.id, conditional_configs.curriculam_types.whiteboard).then(response => {
        console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
          setOpenedGroupData(response.data.groupData);
          localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
          joinToGeneralChat();
          SelfLearningService.getBoardsTemplatesById(response.data.groupData.self_learning_board_id)
            .then((response) => {
              console.log("getBoardsTemplatesById", response);
              if (response.data) {
                localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(response.data.whiteboardTemplates));
              }
            })
            .catch((error) => {
              console.log(error);
            });
          socketConnections();
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** HANDLE CLOSE **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleBoardShareCodePopupClose = () => {
    setOpenBoardShareCodePopup(false);
    localStorage.removeItem("openedGroupData");
    setOpenedGroupData(null);
    setEnableChatWindow(false);

    if (openWhiteboardTabScreen) {
      setOpenWhiteboardTabScreen(false);
      window.location.reload();
    }
  };

  const disconnectChatServer = () => {
    if (chat_server_socket) {
      chat_server_socket.close();
      console.log("Chat server connection closed");
    }
  };

  /** SHARE CODE */
  const shareCode = (e, type) => {
    e.stopPropagation();
    var createGroupModel = {
      course_id: openedContent.course_id,
      group_created_by: getCurrentUserData().id,
      group_name: "",
      code: "",
      content_type: type,
      game_id: null,
      self_learning_board_id: null,
      status: 1,
      createdby: getCurrentUserData().id,
      updatedby: null
    }
    if (type == conditional_configs.curriculam_types.games) {
      createGroupModel.game_id = openedContent.game_id;
    } else if (type == conditional_configs.curriculam_types.whiteboard) {
      createGroupModel.self_learning_board_id = openedContent.id;
    } else {
      createGroupModel.game_id = null;
      createGroupModel.self_learning_board_id = null;
    }
    console.log(createGroupModel);
    SelfLearningService.shareCode(createGroupModel).then(response => {
      console.log('shareCode::', response);
      setOpenedGroupData(response.data);
      localStorage.setItem('openedGroupData', JSON.stringify(response.data));

      /** IF SHARE CODE FOR WHITEBOARD */
      if (type == conditional_configs.curriculam_types.whiteboard) {
        SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(openedContent.course_id, getCurrentUserData().id, openedContent.id, conditional_configs.curriculam_types.whiteboard).then(resp => {
          console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId-sharecode::', resp);
          if (resp && resp.data && resp.success == "true") {
            setPlayers(resp.data.players);
            setOpenedGroupData(resp.data.groupData);
            localStorage.setItem('openedGroupData', JSON.stringify(resp.data.groupData));
            SelfLearningService.getBoardsTemplatesById(resp.data.groupData.self_learning_board_id)
              .then((res) => {
                console.log("getBoardsTemplatesById-resp", res);
                if (res.data) {
                  localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(res.data.whiteboardTemplates));
                }
                // const actualWhiteboardTemplates = JSON.parse(localStorage.getItem('actualWhiteboardTemplates'));
                for (var i = 0; i < res.data.whiteboardTemplates.length; i++) {
                  setWhiteboardLink("");
                  console.log(res.data.whiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + resp.data.groupData.code.toLowerCase())
                  FirebaseService.cloneDocument("scenes", res.data.whiteboardTemplates[i]?.slug, res.data.whiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + response.data.code.toLowerCase());
                }
                openBoard('');
              })
              .catch((error) => {
                console.log(error);
              });
            socketConnections();
          }
        })
          .catch(e => {
            console.log(e);
          });
      }
      getPlayersList();
    })
      .catch(e => {
        console.log(e);
      });
  }

  const handleOpenJoinWithCodePopup = (e) => {
    e.stopPropagation();
    setOpenJoinWithCodePopup(true);
    setGameSettingLink("");
    setEnableChatWindow(false);
  }

  const handleCloseJoinWithCodePopup = (e) => {
    e.stopPropagation();
    setOpenJoinWithCodePopup(false);
    setJoinWithCodeErrorMessage("");
    localStorage.removeItem("openedGroupData");
    setOpenedGroupData(null);
    setEnableChatWindow(false);
    if (openJoinWithCodeGame) {
      setOpenJoinWithCodeGame();
      window.location.reload();
    }
  }

  //#region SHARE CODE FORM AND SCHEMA
  const ShareCodeSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code: '',
  };

  const methods = useForm({
    resolver: yupResolver(ShareCodeSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;
  //#endregion

  const [contentType, setContentType] = useState("");

  /** JOIN WITH CODE SUBMIT EVENT */
  const onJoinWithCodeSubmit = data => {
    console.log('onJoinWithCodeSubmit')

    // Sanitize the code to remove extraneous spaces and newline characters
    const sanitizedCode = data.code.trim();

    console.log('Sanitized Code:', sanitizedCode);

    b_room_socket = io(url_configs.breakout_room_server);
    console.log(data);
    const createGroupDetailModel = {
      group_id: null,
      learner_id: getCurrentUserData().id,
      code: sanitizedCode,
      isMaster: false,
      status: 1,
      createdby: getCurrentUserData().id,
      updatedby: null
    }

    SelfLearningService.joinWithCode(createGroupDetailModel).then(response => {
      console.log('joinWithCode::', response);

      if (response && response.data && response.success == "true") {

        setShowSuccessImage(true);  // Show success image
        setTimeout(() => {
          setShowSuccessImage(false);  // Hide success image after 2 seconds
        }, 3000);


        localStorage.setItem('clickedGameDetail', JSON.stringify(response.data.joinCodeSectionDetailData));

        setTimeout(() => {
          const clickedDetail = JSON.parse(localStorage.getItem("clickedGameDetail"));
          if (clickedDetail) {
            setClickedGameDetail(clickedDetail);
          }
        }, 1000)

        setContentType(response.data.groupData.content_type);

        localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
        joinToGeneralChat();
        if (response.data.groupData.content_type == conditional_configs.curriculam_types.games) {
          setOpenJoinWithCodeGame(conditional_configs.curriculam_types.games);
          const tempGameData = getGameDetail(response.data.groupData.game_id);
          var tempContent = {
            game_id: response.data.groupData.game_id,
            name: tempGameData.name
          }
          localStorage.setItem('openedContent', JSON.stringify(tempContent));
          getGameLink(tempGameData);
          b_room_socket.emit("on-joined-self-learner", { data: createGroupDetailModel }, (error) => {
            if (error) {
              console.log(error);
            }
          });
        } else if (response.data.groupData.content_type == conditional_configs.curriculam_types.whiteboard) {
          setOpenJoinWithCodeGame(conditional_configs.curriculam_types.whiteboard);
          const groupData = JSON.parse(localStorage.getItem('openedGroupData'));
          SelfLearningService.getBoardsTemplatesById(groupData.self_learning_board_id)
            .then((resp) => {
              console.log("getBoardsTemplatesById", resp);
              if (resp.data) {
                localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(resp.data.whiteboardTemplates));
                if (resp.data.whiteboardTemplates && resp.data.whiteboardTemplates.length > 0) {
                  const openBoardsModel = {
                    group_id: groupData.id,
                    code: groupData.code,
                    createdby: getCurrentUserData().id,
                    self_learning_board_id: groupData.self_learning_board_id
                  }
                  console.log("openBoardsModel", openBoardsModel)
                  SelfLearningService.openBoards(openBoardsModel)
                    .then((res) => {
                      console.log("openBoards", res);
                      if (res && res.success == "true" && res.data) {
                        // setWhiteBoardTabList(res.data.whiteboardTemplates)

                        let templates = res.data.whiteboardTemplates;
                        // Set the first tab as active
                        templates = templates.map((template, index) => ({
                          ...template,
                          active: index === 0,
                        }));

                        setWhiteBoardTabList(templates);


                        // Automatically open the first tab
                        if (templates.length > 0) {
                          openWhiteBoard(null, templates[0], 0);
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setJoinWithCodeErrorMessage(response.data)
        // alert(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });

  }

  /** SHOW RESULTS */
  const onShowResultSubmit = data => {
    console.log('onShowResultSubmit', data)
    if (data) {
      getGameResultBySlugAndUserId(data.code);
    }
  }

  /** GET **GAME** RESULTS BY SHARED CODE */
  const getGameResultBySlugAndUserId = (code) => {
    GameService.getGameResultBySlugAndUserId(code)
      .then((response) => {
        console.log("Gameresults", response);
        setGameResults([]);
        if (response && response.data && response.data.length > 0) {
          setGameResults(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** START GAME BUTTON EVENT */
  const startGame = (e) => {
    //Expert Detail
    let expertDetail = {
      id: getCurrentUserData().id,
      name: getCurrentUserData().name
    }

    //Game Detail
    let gameDetail = {
      id: openedContent.game_id,
      name: getGameDetail(openedContent?.game_id)?.name
    }

    var groupDetail = [];
    //Group Detail
    let groupsObj = {
      id: openedGroupData.id,
      name: openedGroupData.code,
      students: players
    }

    groupDetail.push(groupsObj);

    let tempJsonObj = {
      expertDetail: expertDetail,
      gameDetail: gameDetail,
      groupDetail: groupDetail,
      userType: game_config.userType.teacher,
      slug: openedGroupData.code,
      courseStreamId: null,
      isInSession: true
    }

    if (tempJsonObj) {
      let tempJsonValueList = [];
      tempJsonValueList.push(tempJsonObj);

      let model = {
        jsonvalue: tempJsonValueList
      }

      console.log(model);
      localStorage.setItem('openedGroupData', JSON.stringify(openedGroupData));
      localStorage.setItem('openedContent', JSON.stringify(openedContent));
      UtilService.encryptJsonValue(model).then(response => {
        if (response && response.data) {
          var gameLink = getGameDetail(openedContent?.game_id).route + response.data.token
          setGameSettingLink(gameLink);
          setOpenGameSettings(true);
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** OPEN BOARD BUTTON EVENT */
  const openBoard = (e) => {
    setOpenWhiteboardTabScreen(true);
    setWhiteboardLink("");
    const tempData = JSON.parse(localStorage.getItem('openedGroupData'));
    const actualWhiteboardTemplates = JSON.parse(localStorage.getItem('actualWhiteboardTemplates'));
    if (actualWhiteboardTemplates && actualWhiteboardTemplates.length > 0) {
      // for (var i = 0; i < actualWhiteboardTemplates.length; i++) {
      //   console.log(actualWhiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + tempData.code.toLowerCase())
      //   FirebaseService.cloneDocument("scenes", actualWhiteboardTemplates[i]?.slug, actualWhiteboardTemplates[i]?.slug.toLowerCase() + '-div-' + tempData.code.toLowerCase());
      // }
      const openBoardsModel = {
        group_id: tempData.id,
        code: tempData.code,
        createdby: getCurrentUserData().id,
        self_learning_board_id: tempData.self_learning_board_id
      }
      console.log("openBoardsModel", openBoardsModel)
      SelfLearningService.openBoards(openBoardsModel)
        .then((response) => {
          console.log("openBoards", response);
          if (response && response.success == "true" && response.data) {
            setWhiteBoardTabList(response.data.whiteboardTemplates)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const openWhiteBoard = (e, obj, index) => {
    if (e) {
      e.stopPropagation();
    }
    setWhiteboardLink("");
    let data = [...whiteBoardTabList];
    data.forEach((element) => {
      element.active = false;
    });
    setWhiteboardKey(obj.id);
    var generateBoardLink;
    if (openedContent) {
      generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "&self_learning_board_id=" + openedContent.id + "#room=" + obj.board_slug.toLowerCase() + ",-4-Gf1BCSf5ns_M4ChSamw";
    } else {
      const groupData = JSON.parse(localStorage.getItem('openedGroupData'));
      generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "&self_learning_board_id=" + groupData.self_learning_board_id + "#room=" + obj.board_slug.toLowerCase() + ",-4-Gf1BCSf5ns_M4ChSamw";
    }

    console.log("generateBoardLink", generateBoardLink)
    setTimeout(() => {
      setWhiteboardLink(generateBoardLink);
    }, 500);
    data[index].active = true;
    setWhiteBoardTabList(data);
  };




  //#region CHAT
  const openChatWindow = (e) => {
    e.stopPropagation();
    setEnableChatWindow(true);
    // chat_server_socket = io(url_configs.chat_server);
    // joinToGeneralChat();
  }

  const closeChat = (e) => {
    e.stopPropagation();
    setEnableChatWindow(false);
  }

  const renderChatWindow = () => {
    let msgs = [...messages];
    msgs = getChats();
    if (msgs) {
      msgs = msgs.reverse();
    }

    return (
      <div className="group-chats-main-div-cgv4">
        {msgs?.map((val, i) => (
          <div key={i} style={{ marginBottom: '10px' }}>
            {val.userId === getCurrentUserData().id ? (
              <div>
                <div className="chat-my-msg-container-ind-chat-cgv4">
                  <div className="rvr-msg-container-ind-chat-cgv4">
                    <p className="my-name-ind-chat-cgv4 mb-0">Me</p>
                    <span className="rvr-name-ind-chat-cgv4">
                      {findTypeOfMessage(val.text) !== true ? (
                        val.text
                      ) : (
                        <img src={val.text} />
                      )}</span>
                  </div>
                </div>
              </div>
            ) : (

              <div className="rvr-container-ind-chat">
                <div className="rvr-avatar-container-ind-chat">{getInitialsFromFullName(val.user)}</div>
                <div className="w-100 rvr-msg-container-div-ind-chat">

                  <div className="chat-rvr-msg-container-ind-chat-cgv4">
                    <div className="rvr-msg-container-ind-chat-cgv4">
                      <p className="chat-rvr-name-ind-chat-cgv4 mb-0"> {val.user}&nbsp;
                        {val?.userType && val?.userType == "expert"
                          ? "(Expert)"
                          : val?.userType && val?.userType == "admin"
                            ? "(Admin)"
                            : ""}</p>
                      <span className="chat-rvr-msg-ind-chat-cgv4"> {findTypeOfMessage(val.text) !== true ? (
                        val.text
                      ) : (
                        <img src={val.text} />
                      )}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderIndividualChatWindow = () => {
    let msgs = [...messages];
    if (msgs) {
      msgs = msgs.reverse();
    }

    return (
      <div className="individual-chats-main-div-cgv4">
        {msgs?.length === 0 ? (
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_icon}
              ></img>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                className=""
                src={assets.course_v4.no_messege_found_text}
              ></img>
            </div>
          </div>
        ) : (msgs.map((msg, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            {msg.sender_id === getCurrentUserData().id ? (
              // <div style={{ textAlign: 'right', color: 'blue' }}>
              //   <strong>You:</strong> {msg.message}
              // </div>
              <div>
                <div className="chat-my-msg-container-ind-chat-cgv4">
                  <div className="rvr-msg-container-ind-chat-cgv4">
                    <p className="my-name-ind-chat-cgv4 mb-0">You</p>
                    <span className="rvr-name-ind-chat-cgv4">{checkMessageString(msg.message) ? <img width={"100px"} src={msg.message}></img> : msg.message}</span>
                  </div>
                </div>
              </div>
            ) : (
              // <div style={{ textAlign: 'left', color: 'green' }}>
              //   <strong>Friend:</strong> {msg.message}
              // </div>
              <div className="rvr-container-ind-chat">
                <div className="rvr-avatar-container-ind-chat">{getInitialsFromFullName(msg.sender_name)}</div>
                <div className="w-100 rvr-msg-container-div-ind-chat">

                  <div className="chat-rvr-msg-container-ind-chat-cgv4">
                    <div className="rvr-msg-container-ind-chat-cgv4">
                      <p className="chat-rvr-name-ind-chat-cgv4 mb-0">{msg.sender_name}</p>
                      <span className="chat-rvr-msg-ind-chat-cgv4">{checkMessageString(msg.message) ? <img width={"100px"} src={msg.message}></img> : msg.message}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
        ))}
      </div>
    )
  }

  const handleChatSubmit = (e) => {
    e.preventDefault();


    // Check if the chat server socket is connected; if not, rejoin the general chat
    if (!chat_server_socket || !chat_server_socket.connected) {
      joinToGeneralChat();
      alert("Reconnecting to the chat server. Please wait...");
      return;
    }



    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // get remaining messages
    getRemainingMessageCount();

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (filter.isProfane(message)) {
      alert('Message not sent. Please avoid using profane language.');
      return;
    }

    if (checkForPlacesInfo(message)) {
      alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
      return;
    }

    if (isAddressIncluded(message)) {
      alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
      return;
    }


    const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
    let chatModel = {
      group: null,
      isInSession: false,
      courseStreamId: null,
      room: tempOpenedGroupData.code,
      slug: tempOpenedGroupData.code,
      text: message,
      user: getCurrentUserData().name,
      userId: getCurrentUserData().id,
      userType:
        getCurrentUserData().role == conditional_configs.role.learner
          ? "learner"
          : getCurrentUserData().role == conditional_configs.role.expert
            ? "expert"
            : "admin",
    };
    if (message) {
      chat_server_socket.emit("sendMessage", {
        message: message,
        group: tempOpenedGroupData.code,
        slug: tempOpenedGroupData.code,
        userId: getCurrentUserData().id,
        userType: chatModel.userType,
      });
      chatModel.group = tempOpenedGroupData.code;
      setMessage("");

      //add group chart for session_chats table

      SessionService.addChats(chatModel)
        .then((response) => { })
        .catch((error) => {
          console.log(error);
        });
      // get message count
      setTimeout(() => {
        fetchMessageCounts();
      }, 3000); // 3 minutes delay in milliseconds

      // addChats(chatModel);
    } else alert("empty input");

  }

  const joinToGeneralChat = () => {
    console.log("joinToGeneralChat");
    const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));

    let room = tempOpenedGroupData.code;
    //setChatRoom(room);
    chat_server_socket = io(url_configs.chat_server);

    chat_server_socket.emit("join", { name: getCurrentUserData().name, room: room }, (error) => {
      if (error) {
        alert(error);
      }
    });

    chat_server_socket.on("message", (message) => {
      if (message.slug == room) {
        let generalChats = [...messages];
        let storedChats = getChats();
        if (storedChats && storedChats.length > 0) {
          generalChats = storedChats;
        }
        generalChats.push(message)
        setMessages(generalChats);
        storeChats(generalChats);

        if (message && message.user != getCurrentUserData().name) {
          enqueueSnackbar('Message received', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

          let tempNofications = JSON.parse(localStorage.getItem(storage_configs.localStorage.sessions.chatNotification));
          if (!tempNofications) {
            tempNofications = [];
          }
          tempNofications.push(message);
          localStorage.setItem(storage_configs.localStorage.sessions.chatNotification, JSON.stringify(tempNofications));
        }
      }
    });

  }

  //#endregion

  //#region MY FRIENDS LIST

  const handleOpenInviteFriendPopup = (e) => {
    setInviteFriendPopup(true);
  }

  const handleCloseInviteFriendPopup = (e) => {
    setInviteFriendPopup(false);
  }

  const getChatFriends = () => {
    SelfLearningService.getChatFriends().then(response => {
      console.log('getChatFriends::', response);
      if (response && response.success == "true") {
        setMyChatFriendsList(response.data);
      }
      getMyInvites();
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** MY INTRACTED FRIENDS LIST */
  const getIntractedFriends = () => {
    SelfLearningService.getIntractedFriends().then(response => {
      console.log('getIntractedFriends::', response);
      if (response && response.success == "true") {
        setMyIntractedFriendsList(response.data);
      }
      //getMyInvites();
    })
      .catch(e => {
        console.log(e);
      });
  }

  const getMyPrivateMessageByRecipientId = (recipient_id) => {
    SelfLearningService.getMyPrivateMessageByRecipientId(recipient_id).then(response => {
      console.log('getMyPrivateMessageByRecipientId::', response);
      if (response && response.success == "true") {
        setMessages(response.data);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  // get current user private messages by id

  const getCurrentUserPrivateMessageById = () => {
    SelfLearningService.getCurrentUserPrivateMessageById(getCurrentUserData().id).then(response => {
      console.log('getCurrentUserPrivateMessageById::', response);
      if (response && response.success == "true") {
        setPrivateMessageCount(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  // get current user group messages by id

  const getCurrentUserGroupMessageById = () => {
    SelfLearningService.getCurrentUserGroupMessageById(getCurrentUserData().id).then(response => {
      console.log('getCurrentUserGroupMessageById::', response);
      if (response && response.success == "true") {
        setGroupMessageCount(response.data)
      }
    })
      .catch(e => {
        console.log(e);
      });
  }


  const handleMyFriendsCtrlMenuOpen = (event) => {
    setMyFriendsCtrlMenu(true);
    setMyFriendsAnchorEl(event.currentTarget);
    setOpenIndividualChat(null);
    var rootContainer = document.getElementById('root');
    rootContainer.style.overflow = 'hidden'; // Show the modal container
    getChatFriends();
  };

  const handleMyFriendsCtrlMenuClose = () => {
    setMyFriendsCtrlMenu(false);
    setOpenIndividualChat(null);
    var rootContainer = document.getElementById('root');
    rootContainer.style.overflow = 'auto'; // Show the modal container
    window.location.reload();
    //
    getChatFriends();
  };

  const backToMyFriendsList = (e) => {
    setMyFriendsCtrlMenu(true);
    setOpenIndividualChat(null);
    //
    getChatFriends();
  }



  const getRemainingMessageCount = (e) => {
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    let remainingMessages = 0;

    if (subscriptionStatus === "trialing") {
      // Set remaining messages for trialing plans (e.g., 50 messages for trial pack)
      remainingMessages = currentPlan?.trialing_message_count - totalMessages;
    } else {
      // Set remaining messages for active plans
      remainingMessages = currentPlan?.active_message_count - totalMessages;
    }

    setRemainingMessageCount(remainingMessages); // Set the remaining messages count
  };


  const chatToFriend = (e, obj) => {
    e.stopPropagation();
    setOpenIndividualChat(obj);
    getMyPrivateMessageByRecipientId(obj.learner.id);
    getRemainingMessageCount();
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const textClips = ["Hi", "Good Morning", "Hello", "Yes", "No", "Thank you", "Goodbye"];


  const sendTextClip = (clip) => {

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    // get remaining messages
    getRemainingMessageCount();

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (clip.trim() !== '') {
      const insertIndividualMsgModel = {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: clip,
        createdby: getCurrentUserData().id
      };
      sendPrivateMessage(insertIndividualMsgModel);
      friends_server_socket.emit('send_private_message', insertIndividualMsgModel);
      setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
    }
    // get message count
    setTimeout(() => {
      fetchMessageCounts();
    }, 3000); // 3 minutes delay in milliseconds
  };

  const sendTextClipForGroup = (clip) => {

    // Check if the chat server socket is connected; if not, rejoin the general chat
    if (!chat_server_socket || !chat_server_socket.connected) {
      joinToGeneralChat();
      alert("Reconnecting to the chat server. Please wait...");
      return;
    }

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    // get remaining messages
    getRemainingMessageCount();

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (clip.trim() !== '') {
      const tempOpenedGroupData = JSON.parse(localStorage.getItem('openedGroupData'));
      let chatModel = {
        group: null,
        isInSession: false,
        courseStreamId: null,
        room: tempOpenedGroupData.code,
        slug: tempOpenedGroupData.code,
        text: message,
        user: getCurrentUserData().name,
        userId: getCurrentUserData().id,
        userType:
          getCurrentUserData().role == conditional_configs.role.learner
            ? "learner"
            : getCurrentUserData().role == conditional_configs.role.expert
              ? "expert"
              : "admin",
      };
      if (clip) {
        chat_server_socket.emit("sendMessage", {
          message: clip,
          group: tempOpenedGroupData.code,
          slug: tempOpenedGroupData.code,
          userId: getCurrentUserData().id,
          userType: chatModel.userType,
        });
        chatModel.group = tempOpenedGroupData.code;
        setMessage("");

        //add group chart for session_chats table

        SessionService.addChats(chatModel)
          .then((response) => { })
          .catch((error) => {
            console.log(error);
          });

        // get message count
        setTimeout(() => {
          fetchMessageCounts();
        }, 3000); // 3 minutes delay in milliseconds

        // addChats(chatModel);
      } else alert("empty input");
    }
  };



  const sendMessage = (e) => {
    e.preventDefault();

    // Calculate the total message count
    const totalMessages = privateMessageCount?.length + groupMessageCount?.length;

    // get remaining messages
    getRemainingMessageCount();

    // Find the subscription plan based on the subscriptionPlanId
    const currentPlan = url_configs.plans.find(plan => plan.id === subscriptionPlanId);

    if (totalMessages >= planMessagecount) {
      // Check if the user is on a trial or active plan
      if (planMessagecount === currentPlan?.trialing_message_count) {
        alert("You are on a trial plan. Sending messages is not allowed.");
      } else if (planMessagecount === currentPlan?.active_message_count) {
        alert(`You have reached the maximum limit of ${currentPlan.active_message_count} messages.`);
      }
      return;
    }

    if (message.trim() !== '') {

      if (filter.isProfane(message)) {
        alert('Message not sent. Please avoid using profane language.');
        return;
      }

      if (checkForPlacesInfo(message)) {
        alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
        return;
      }
      if (isAddressIncluded(message)) {
        alert('Message not sent. Sharing physical addresses or including numbers is not allowed.');
        return;
      }


      // Send private message to the friend
      friends_server_socket.emit('send_private_message', {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: message,
        createdby: getCurrentUserData().id
      });

      var insertIndividualMsgModel = {
        sender_id: getCurrentUserData().id,
        sender_name: getCurrentUserData().name,
        recipient_id: openIndividualChat.learner.id,
        recipient_name: openIndividualChat.learner.firstname,
        message: message,
        createdby: getCurrentUserData().id
      }
      sendPrivateMessage(insertIndividualMsgModel);
      // Add your own message to the UI
      setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
      setMessage('');
      // get message count

      setTimeout(() => {
        fetchMessageCounts();
      }, 3000); // 3 minutes delay in milliseconds
    }
  };

  const checkMessageString = (msg) => {
    if (msg) {
      const mimeType = msg.match(/data:([^;]+);base64,/);

      if (mimeType && mimeType[1]) {
        return true;
      } else {
        return false
      }
    }
    return false
  }

  // const navigateToCoursesDescription = (gameName, Data) => {
  //   localStorage.setItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails, JSON.stringify(Data))
  //   navigateTo(navigate_route_config.learner.courses_description + '/' + gameName);
  // }

  const navigateToCoursesDescription = (gameName, Data) => {
    localStorage.setItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails, JSON.stringify(Data));

    const currentPath = window.location.pathname;
    navigate(`${navigate_route_config.learner.courses_description}/${gameName}?returnTo=${encodeURIComponent(currentPath)}`);
  };


  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setMessage('');
    if (file && file.size <= 2 * 1024 * 1024) { // Check if file size is <= 2MB
      // setLoading(true);
      const reader = new FileReader();

      reader.onload = async () => {
        const base64Image = new Image();
        base64Image.src = reader.result;

        base64Image.onload = async () => {
          const model = await nsfwjs.load();
          const predictions = await model.classify(base64Image);

          // Convert probabilities to percentages
          const predictionsWithPercentages = predictions.map(prediction => ({
            className: prediction.className,
            probability: (prediction.probability * 100).toFixed(2) // Convert to percentage and format to 2 decimal places
          }));
          e.target.value = null;
          const validateImage = predictionsWithPercentages.filter(e => (e.className == "Porn" || e.className == "Sexy" || e.className == "Hentai") && Number(e.probability) >= 10);
          if (validateImage.length > 0) {
            return alert('This content cannot be shared.');
          }
          setMessage(base64Image.src);
          // Prevent default behavior (new line) and submit the form
          // Send private message to the friend
          friends_server_socket.emit('send_private_message', {
            sender_id: getCurrentUserData().id,
            sender_name: getCurrentUserData().name,
            recipient_id: openIndividualChat.learner.id,
            recipient_name: openIndividualChat.learner.firstname,
            message: base64Image.src,
            createdby: getCurrentUserData().id
          });

          var insertIndividualMsgModel = {
            sender_id: getCurrentUserData().id,
            sender_name: getCurrentUserData().name,
            recipient_id: openIndividualChat.learner.id,
            recipient_name: openIndividualChat.learner.firstname,
            message: base64Image.src,
            createdby: getCurrentUserData().id
          }
          sendPrivateMessage(insertIndividualMsgModel);
          // Add your own message to the UI
          setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
          setMessage('');
          // setLoading(false);
        };
      };

      reader.readAsDataURL(file);
    } else {
      alert('File size exceeds 2MB limit.');
    }
  };

  const handleImageUpload_old = (e) => {
    setMessage('');
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) { // Check if file size is <= 2MB
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result;
        console.log(base64Image);
        setMessage(base64Image);
        // Prevent default behavior (new line) and submit the form
        // Send private message to the friend
        friends_server_socket.emit('send_private_message', {
          sender_id: getCurrentUserData().id,
          sender_name: getCurrentUserData().name,
          recipient_id: openIndividualChat.learner.id,
          recipient_name: openIndividualChat.learner.firstname,
          message: base64Image,
          createdby: getCurrentUserData().id
        });

        var insertIndividualMsgModel = {
          sender_id: getCurrentUserData().id,
          sender_name: getCurrentUserData().name,
          recipient_id: openIndividualChat.learner.id,
          recipient_name: openIndividualChat.learner.firstname,
          message: base64Image,
          createdby: getCurrentUserData().id
        }
        sendPrivateMessage(insertIndividualMsgModel);
        // Add your own message to the UI
        setMessages((prevMessages) => [...prevMessages, insertIndividualMsgModel]);
        setMessage('');
      };
      reader.readAsDataURL(file);
    } else {
      alert('File size exceeds 2MB limit.');
    }
  };

  const sendPrivateMessage = (data) => {
    SelfLearningService.sendPrivateMessage(data)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Prevent default behavior (new line) and submit the form
      e.preventDefault();
      sendMessage(e);
    }
  };

  const handleKeyPressForGroup = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Prevent default behavior (new line) and submit the form
      e.preventDefault();
      handleChatSubmit(e);
    }
  };

  const handleInviteFriendUserNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
  };


  // ON SUBMIT INVITE FRIEND
  const onSubmitInviteFriend = (e) => {
    e.preventDefault();

    var inviteFriendModel = {
      invited_by: getCurrentUserData().id,
      invite_user_type: "learner",
      email: null,
      username: userName,
      friend_id: null,
      status: "1",
      createdby: getCurrentUserData().id,
      updatedby: null
    };
    if (validateEmail(inviteFriendModel.username)) {
      inviteFriendModel.email = inviteFriendModel.username;
      inviteFriendModel.username = null;
      inviteFriendModel.invite_user_type = "parent";
    }

    console.log(inviteFriendModel);
    SelfLearningService.inviteFriendToChat(inviteFriendModel).then(response => {
      console.log('inviteFriendToChat::', response);
      if (response && response.success == "true") {
        // setInviteFriendPopup(false);
        handleMyFriendsCtrlMenuClose();
        setUserName(''); // Clear the form
        enqueueSnackbar("Invited successfully!", { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else if (response && response.success == "false") {
        enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        return;
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  const inviteInteractedFriend = (e, obj) => {
    console.log(obj)
    e.stopPropagation();
    const inviteFriendModel = {
      invited_by: getCurrentUserData().id,
      invite_user_type: "learner",
      email: null,
      username: obj.username,
      friend_id: null,
      status: "1",
      createdby: getCurrentUserData().id,
      updatedby: null
    };
    console.log(inviteFriendModel);
    SelfLearningService.inviteFriendToChat(inviteFriendModel).then(response => {
      console.log('inviteFriendToChat::', response);
      if (response && response.success == "true") {
        setInviteFriendPopup(false);
        enqueueSnackbar("Invited successfully!", { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        getChatFriends();
        getIntractedFriends();
      } else if (response && response.success == "false") {
        enqueueSnackbar(response.data, { variant: 'warning', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        return;
      }
    })
      .catch(e => {
        console.log(e);
      });
  }


  const getMyInvites = () => {
    SelfLearningService.getMyInvites().then(response => {
      if (response && response.success == "true") {
        console.log('getMyInvites::', response);
        setMyChatInviteList(response.data);
      }
    })
      .catch(e => {
        console.log(e);
      });
  }
  //#endregion

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [learnerToRemove, setLearnerToRemove] = useState(null);
  const [showRejectConfirmModal, setShowRejectConfirmModal] = useState(false);
  const [showAcceptConfirmModal, setShowAcceptConfirmModal] = useState(false);
  const [inviteToFriendDetail, setInviteToFriendDetail] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');



  // remove Learner From Group

  const removeLearnerFromGroup = (learner_id) => {
    const removeData = {
      learner_id: learner_id,
      group_id: openedGroupData.id
    }
    const tempPlayers = [...players];
    const filteredPlayers = tempPlayers.filter(e => e.id !== learner_id);
    console.log(removeData);
    SelfLearningService.removeLearnerFromGroup(removeData).then(response => {
      console.log('getCourseDetailByLearnerId::', response);
      if (response && response.success == "true") {
        setPlayers([]);
        setPlayers(filteredPlayers);
        enqueueSnackbar('Player removed successfully', { variant: 'success', key: 'topright', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
      .catch(e => {
        console.log(e);
      });
  }


  const handleRemovePlayerClick = (e, learner_id) => {
    e.stopPropagation();
    setLearnerToRemove(learner_id);
    setShowConfirmModal(true);
  };

  const handleConfirmPlayerRemove = () => {
    removeLearnerFromGroup(learnerToRemove);
    setShowConfirmModal(false);
    setLearnerToRemove(null);
  };

  const handleCancelPlayerRemove = () => {
    setShowConfirmModal(false);
    setLearnerToRemove(null);
  };

  // remove Learner From Group end

  // accept Friend Invite
  const acceptChatInvite = (e, obj) => {
    if (obj) {
      setInviteToFriendDetail(obj);
      setShowAcceptConfirmModal(true);
    }
  }

  const handleConfirmAcceptFriendInvite = () => {
    if (inviteToFriendDetail) {
      SelfLearningService.acceptChatInvite(inviteToFriendDetail.inviteDetail)
        .then(response => {
          getChatFriends();
        })
        .catch(error => {
          console.log(error)
        });
    }
    setShowAcceptConfirmModal(false);
  };

  const handleCancelAcceptFriendInvite = () => {
    setShowAcceptConfirmModal(false);
  };

  // accept Friend Invite end


  // Reject Friend Invite

  const rejectChatInvite = (e, obj) => {
    if (obj) {
      setInviteToFriendDetail(obj);
      setShowRejectConfirmModal(true);
    }
  };

  const handleConfirmRejectFriendInvite = () => {
    if (inviteToFriendDetail) {
      var inviteDetailModel = inviteToFriendDetail.inviteDetail;
      inviteDetailModel.updatedby = getCurrentUserData().id;
      SelfLearningService.rejectChatInvite(inviteDetailModel)
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    }
    setShowRejectConfirmModal(false);
  };


  const handleCancelRejectFriendInvite = () => {
    setShowRejectConfirmModal(false);
  };

  // Reject Friend Invite end

  const getMySubscriptionDetail = () => {
    PaymentService.getMySubscriptionDetailByKid()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data && response.data.length > 0) {
          setSubscriptionStatus(response.data[0].subscription.status);
          setSubscriptionPlanId(response.data[0].product.id)
        }

        let message_count = 0;
        // Loop through the subscription plans
        for (let i = 0; i < url_configs.plans.length; i++) {
          const checkProducts = response.data.filter(
            (e) =>
              e.product.id === url_configs.plans[i].id &&
              (e.subscription.status === "active" || e.subscription.status === "trialing")
          );

          if (checkProducts && checkProducts.length > 0) {
            // Check if the subscription is 'active' or 'trialing' and apply the corresponding message count
            if (checkProducts[0].subscription.status === "active") {
              message_count += url_configs.plans[i].active_message_count;
            } else if (checkProducts[0].subscription.status === "trialing") {
              message_count += url_configs.plans[i].trialing_message_count;
            }
          }
        }

        setplanMessagecount(message_count); // Set the calculated total message count
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const checkSubscriptionDetail = () => {
    if (subscriptionStatus == "active" || subscriptionStatus == "trialing") {
      return true;
    } else {
      return false;
    }
  }

  const navigateTo = (link) => {
    navigate(link);
  }


  const getCardStyles = (gameDetail) => {
    if (!gameDetail) return {};

    switch (gameDetail.name) {
      case 'Robo Finder':
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
      case 'Looper':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'Blocky Game':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'The Drill':
        return { backgroundColor: "CC333333", boxShadow: "0px 0px 90px 0px #CC3333CC inset" };
      default:
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
    }
  }

  //#region LEADER BOARD
  const getLeaderBoardForGamesByKid = () => {
    SelfLearningService.getLeaderBoardForGamesByKid()
      .then((response) => {
        console.log("getLeaderBoardForGamesByKid", response);
        if (response && response.data && response.data.length > 0) {
          localStorage.setItem('leader-board-data', JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openLeaderBoardEvent = (e) => {
    setOpenGameSettings(true);
    setOpenLeaderBoard(true);
    const leaderBoardData = JSON.parse(localStorage.getItem('leader-board-data'));
    if (leaderBoardData && leaderBoardData.length > 0) {
      const currentLeaderBoard = leaderBoardData.filter(e => e.game_id == openedContent.game_id);
      if (currentLeaderBoard && currentLeaderBoard.length > 0) {
        const groupedData = groupBy(currentLeaderBoard[0].results, "userid");
        setCurrentLeaderBoard(groupedData);
      } else {
        setCurrentLeaderBoard(null);
      }
    }
  }


  const copyShareCodeLink = () => {
    const copyingData = url_configs.frontend + "self-learning-parent-registration?ref_code=" + openedGroupData.code
    copyToClipBoardWithMsgWithOutAlert(copyingData)
    setCopyMessage("Share this code with friends to play.");
  }

  const handlePaste = (event) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted content
    const pastedText = event.clipboardData.getData('text');
    if (checkIsEmdedCode(pastedText) == true) {
      setValue("code", pastedText, {
        shouldValidate: true,
        shouldDirty: true
      });
    } else {
      var splitLink = pastedText.split("?")[1];
      if (splitLink) {
        var ref_code = splitLink.split("ref_code=")[1];
        if (ref_code) {
          setValue("code", ref_code, {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      }
    }
  };

  // Function to clear the error message
  const handleCloseError = () => {
    setJoinWithCodeErrorMessage("");
  };



  //#endregion


  return (

    <div>
      {isLoading ? (
        // Loader Spinner
        <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        // Main Content

        <div>
          <img
            className="" width={'100%'}
            src={assets.course_v4.lets_play_learn_banner}
          ></img>


          <Container className="self-learning-main-container pt-3">
            <div className="d-flex justify-content-end align-items-center">
              {checkSubscriptionDetail() == true ? <Button className="join-with-code-btn-cgv4-1 mb-2 me-2" onClick={(e) => handleMyFriendsCtrlMenuOpen(e)} >My Friends
                {myChatInviteList.length > 0 ? <span className="my-friends-count-badge">{myChatInviteList.length}</span> : ""}
              </Button> : ""}
              {checkSubscriptionDetail() == true ? <Button className="join-with-code-btn-cgv4-1 mb-2" onClick={(e) => handleOpenJoinWithCodePopup(e)}>Join with code</Button> : ""}
            </div>

            <p className="missions-text-title-cgv4 mt-2">MISSIONS</p>

            <Box sx={{ width: '100%' }}>
              {/* GAMES */}
              <div>
                {/* New card  */}
                {myGames.length > 0 ? <div className="row">
                  {
                    myGames.sort((a, b) => {
                      // Sort 'coming_soon' cards last
                      if (a.coming_soon && !b.coming_soon) return 1;
                      if (!a.coming_soon && b.coming_soon) return -1;
                      // Sort by 'sequence' if 'coming_soon' status is the same
                      return a.sequence - b.sequence;
                    }).map((obj, index) => {
                      const gameDetail = getGameDetail(obj.game_id);
                      const cardStyles = getCardStyles(gameDetail);
                      return (
                        <div key={`self-learning-games-` + obj.id} className="col-12 col-lg-3 col-md-4 col-sm-12 pb-5">

                          {obj.coming_soon ? (
                            // Coming Soon Card

                            <Card
                              className="cursor-pointer missions_game_card_cv4"
                              style={{ backgroundColor: "rgb(90 104 96 / 36%);", boxShadow: "#111112cf 0px 0px 90px 0px inset" }} onClick={(e) => navigateToCoursesDescription(getGameDetail(obj.game_id)?.name, obj)}
                            >
                              <div className="missions_game_card_img_cv4">
                                <img
                                  className="game_card_img_cv4"
                                  width={'100%'}
                                  src={obj?.image || getGameDetail(obj.game_id)?.img}
                                  alt={getGameDetail(obj.game_id)?.name}
                                ></img>
                              </div>
                              <div className="missions_game_card_content">
                                <p className="missions_game_card_title">
                                  {getGameDetail(obj.game_id)?.name}
                                </p>
                                {subscriptionStatus == "trialing" ? <div className="d-flex align-items-center justify-content-center">
                                  <button className="comimg_soon_trialing_st_btn">
                                    <p className="mb-0 me-2 btn-text-hv4">Coming soon</p>
                                    <img
                                      className=""
                                      width={40}
                                      src={assets.homev4.white_with_blue_arrow}
                                    ></img>
                                  </button>
                                </div> : ''}
                              </div>

                              {subscriptionStatus == "active" ? <div className="d-flex align-items-center justify-content-center comimg_soon_active_st_btn">
                                <p className="mb-0 btn-text-hv4">Coming soon</p>
                              </div> : ''}
                            </Card>
                          ) : (
                            // Regular Game Card
                            <Card className={`cursor-pointer missions_game_card_cv4 ${obj.isFree && subscriptionStatus == "trialing" ? '' : subscriptionStatus == "active" ? '' : 'dim-opacity'}`} style={cardStyles}
                              onClick={(e) => {
                                if (obj.isFree && subscriptionStatus === "trialing") {
                                  handleOpenGameShareCodePopup(e, obj); // Click event for free trial
                                } else if (!obj.isFree && subscriptionStatus === "trialing") {
                                  navigateToCoursesDescription(getGameDetail(obj.game_id)?.name, obj)
                                } else {
                                  handleOpenGameShareCodePopup(e, obj); // Click event for active subscription or other cases
                                }
                              }} >
                              <div className="missions_game_card_img_cv4">
                                {getGameDetail(obj.game_id)?.type == conditional_configs.gameTypes.singlPlayerWebsite ?
                                  <span className="solo-player-text">Solo game</span> : ""}
                                <img
                                  className="game_card_img_cv4" width={'100%'}
                                  src={obj?.image || getGameDetail(obj.game_id)?.img}
                                  // src={getGameDetail(obj.game_id)?.img}
                                  alt={getGameDetail(obj.game_id)?.name}
                                ></img>
                              </div>

                              <div className="missions_game_card_content">
                                <p className="missions_game_card_title">{getGameDetail(obj.game_id)?.name}</p>

                                {
                                  obj.isFree && subscriptionStatus == "trialing" ?
                                    <div className="d-flex align-items-center justify-content-center">
                                      {getGameDetail(obj.game_id)?.domainName !== "looper" ?
                                        <button className="start-for-free-cv4">
                                          <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                            className="" width={40}
                                            src={assets.homev4.white_with_blue_arrow}
                                          ></img>
                                        </button> :
                                        <button className="start-for-free-cv4">
                                          <p className="mb-0 me-2 btn-text-hv4">Coming soon</p>  <img
                                            className="" width={40}
                                            src={assets.homev4.white_with_blue_arrow}
                                          ></img>
                                        </button>}
                                    </div> : subscriptionStatus == "active" ? ''
                                      :
                                      <div className="d-flex align-items-center justify-content-center">
                                        {getGameDetail(obj.game_id)?.domainName !== "looper" ? <button className="start-for-free-cv4">
                                          <p className="mb-0 me-2 btn-text-hv4">Purchase</p>  <IconButton aria-label="locked">
                                            <LockTwoToneIcon />
                                          </IconButton>
                                        </button> :
                                          <button className="start-for-free-cv4">
                                            <p className="mb-0 me-2 btn-text-hv4">Coming soon</p>  <img
                                              className="" width={40}
                                              src={assets.homev4.white_with_blue_arrow}
                                            ></img>
                                          </button>}
                                      </div>
                                }

                              </div>
                            </Card>
                          )}
                        </div>
                      )
                    })
                  }
                </div> :
                  <div className="row">
                    <p>No records found. Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
                  </div>}
                {subscriptionStatus == "canceled" ? <div className="row">
                  <p>Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
                </div> : ""}
              </div>
            </Box>

            {/* FRIENDS LIST CHAT */}
            {myFriendsCtrlMenu ?
              <div>
                {!openIndividualChat ?
                  <div className="my-friends-list-main-div">
                    <div className="my-friends-list-inner-div-cgv4">
                      <div>
                        <div className="d-flex align-items-center pt-2 pb-3">
                          <div className="my-friends-invite-title-cvg4">INVITE & CHAT</div>

                          <span className="close_icon_cgv4_1" onClick={handleMyFriendsCtrlMenuClose}>
                            <img
                              className="" width={'30px'}
                              src={assets.course_v4.close_icon}
                            ></img>
                          </span>

                        </div>

                        <div className="content-cgv4 mb-2" onSubmit={onSubmitInviteFriend}>
                          <form className="subscription-cgv4" >
                            <input
                              id={'invite-friend-id'}
                              required
                              autoComplete="off"
                              className="add-email-cgv4"
                              type="text"
                              placeholder="Type Username/Email"
                              name="invite-friend-name"
                              onChange={handleInviteFriendUserNameChange}
                            />
                            <button
                              className="submit-email-cgv4"
                              type="submit"
                            >
                              <span className="before-submit-cgv4">Send invite</span>
                            </button>
                          </form>
                        </div>

                        {/* <div className="border-bottom-style"></div> */}
                        {/* <div className="d-flex align-items-center justify-content-between pb-2">
                    <div className="my-friends-title">My Friends</div>
                    <Button variant="contained" onClick={handleOpenInviteFriendPopup}>Invite Friend</Button>
                  </div> */}

                        {/* MY FRIENDS LIST */}

                        {myChatFriendsList?.sort((a, b) => {
                          // Sort based on lastChatTime in descending order
                          const dateA = new Date(a.lastChatTime || 0);  // If no lastChatTime, default to 0
                          const dateB = new Date(b.lastChatTime || 0);
                          return dateB - dateA;  // Most recent first (descending)
                        }).map((obj, Index) => {
                          return (
                            <div>
                              <div className="d-flex align-items-center my-friends-list-ul" key={`my-friends-key-` + Index} onClick={(e) => chatToFriend(e, obj)}>
                                <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.learner?.firstname)}</div>
                                <div className="my-friends-name">
                                  {obj?.learner?.firstname}
                                </div>

                                {obj?.unreadMessageCount ? <div className="ms-auto unread_message_div">
                                  <p className="unread_message_count_text mb-0">{obj?.unreadMessageCount}</p>
                                </div> : ""}

                              </div>
                              <div className="border-bottom-style"></div>
                            </div>

                          );
                        })}

                        {myChatFriendsList.length == 0 && myChatInviteList.length == 0 ? <div className="my-friends-no-records-found">No records found!</div> : ""}

                        {/* INVITED USERS LIST */}
                        {myChatInviteList?.map((obj, Index) => {
                          return (
                            <div className="d-flex align-items-center my-friends-list-ul justify-content-between pt-3" key={`my-invited-friends-key-` + Index}>
                              <div className="d-flex align-items-center">
                                <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.invitedUser?.firstname)}</div>
                                <div className="my-friends-name">
                                  {obj?.invitedUser?.firstname}
                                </div>
                              </div>
                              <div>
                                <button className="accept-btn-cgv4 me-1" onClick={(e) => acceptChatInvite(e, obj)} >Accept</button>
                                <button className="reject-btn-cgv4" onClick={(e) => rejectChatInvite(e, obj)}>Reject</button>
                              </div>
                            </div>
                          );
                        })}

                        {/* show Accept Confirm Modal */}

                        {showAcceptConfirmModal && (
                          <div className="modal-overlay-cgv4">
                            <div className="modal-content-cgv4">
                              <div className="d-flex">
                                <p className="Join-with-code-text-cgv4 mt-1 mb-0">FRIEND INVITE</p>
                                <span className="cursor-pointer ms-auto" onClick={handleCancelAcceptFriendInvite}>
                                  <img
                                    className="" width={'30px'}
                                    src={assets.course_v4.close_icon}
                                  ></img>
                                </span>
                              </div>

                              <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to accept friend invite?</p>


                              <div className="d-flex justify-content-center align-items-center pb-3">
                                <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelAcceptFriendInvite}>
                                  <p className="mb-0">No</p>
                                </button>
                                <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmAcceptFriendInvite}>
                                  <p className="mb-0 me-2">Yes</p> <img
                                    className="" width={40}
                                    src={assets.homev4.white_with_blue_arrow}
                                  ></img>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* show Reject Confirm Modal */}

                        {showRejectConfirmModal && (
                          <div className="modal-overlay-cgv4">
                            <div className="modal-content-cgv4">
                              <div className="d-flex">
                                <p className="Join-with-code-text-cgv4 mt-1 mb-0">FRIEND INVITE</p>
                                <span className="cursor-pointer ms-auto" onClick={handleCancelRejectFriendInvite}>
                                  <img
                                    className="" width={'30px'}
                                    src={assets.course_v4.close_icon}
                                  ></img>
                                </span>
                              </div>

                              <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to reject friend invite?</p>


                              <div className="d-flex justify-content-center align-items-center pb-3">
                                <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelRejectFriendInvite}>
                                  <p className="mb-0">No</p>
                                </button>
                                <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmRejectFriendInvite}>
                                  <p className="mb-0 me-2">Yes</p> <img
                                    className="" width={40}
                                    src={assets.homev4.white_with_blue_arrow}
                                  ></img>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="my-friends-title">My Interacted Friends</div>
                </div> */}

                        {/* MY INTRACTED FRIENDS LIST */}
                        {/* {myIntractedFriendsList?.map((obj, Index) => {
                  return (
                    <div className="d-flex align-items-center my-friends-list-ul justify-content-between" key={`my-intracted-friends-key-` + Index}>
                      <div className="d-flex align-items-center">
                        <div className="my-friend-avatar-ind-chat">{getInitialsFromFullName(obj?.learner?.firstname)}</div>
                        <div className="my-friends-name">
                          {obj?.learner?.firstname}
                        </div>
                      </div>
                      <div>

                       {obj.inviteStatus == null? 
                       <Button variant="contained" className="me-1" onClick={(e) => inviteInteractedFriend(e, obj)}>Invite</Button>
                       :
                       obj.inviteStatus == "invited" ?
                       <Button variant="contained" className="me-1">Invited</Button>
                       :""
                  }
                      </div>
                    </div>
                  );
                })} */}

                        {/* {myIntractedFriendsList.length == 0 ? <div className="my-friends-no-records-found">No records found!</div> : ""} */}


                      </div>
                    </div>
                  </div>
                  :
                  <div className="my-friends-list-main-div-message">
                    <div className="my-friends-list-inner-div-message-cgv4">
                      {/* <div className="background-color-blue-cgv4"></div> */}
                      <div className="h-100 p-2">
                        <div className="d-flex align-items-center">
                          <div className="my-friends-title ms-2">{openIndividualChat.learner.firstname}</div>
                          <span className="close_icon_cgv4" onClick={handleMyFriendsCtrlMenuClose} >
                            <img
                              className="" width={'30px'}
                              src={assets.course_v4.close_icon}
                            ></img>
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <IconButton aria-label="Back" type="button" onClick={(e) => backToMyFriendsList(e)} >
                            <img
                              className="" width={''}
                              src={assets.course_v4.left_arrow}
                            ></img>
                          </IconButton>
                          <div className="my-friends-invite-title-cvg4">CHAT</div>
                        </div>
                        <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>

                        <div className="h-100">
                          <div className="h-100">
                            {renderIndividualChatWindow()}
                            <div className="my-friends-chat-text-box-main-div">
                              <div className="text-clips-container">
                                {textClips.map((clip, index) => (
                                  <button key={index} className="text-clip-button" onClick={() => sendTextClip(clip)}>
                                    {clip}
                                  </button>
                                ))}
                              </div>

                              <form className="w-100 d-flex align-items-center" onSubmit={(e) => sendMessage(e)}>
                                {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                              <label for="image-file-input-1">
                                <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                              </label>
                              <input
                                id="image-file-input-1"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                              />
                            </div> */}
                                <textarea
                                  id="ind-chat-input-id"
                                  type="text"
                                  rows={"1"}
                                  value={message}
                                  onChange={handleMessageChange}
                                  onKeyPress={handleKeyPress}
                                  placeholder="Type here..."
                                  className="ind-chat-input-cgv4"
                                  disabled={remainingMessageCount === 0}
                                />
                                <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                                  <IconButton type="submit" disabled={remainingMessageCount === 0}>
                                    <img
                                      className="" width={'40px'}
                                      src={assets.course_v4.send_arrow}
                                    ></img>
                                  </IconButton>
                                </div>

                              </form>
                              {/* Display message */}

                              <p className="mb-0 message-limited-text-cgv4">
                                {remainingMessageCount > 0
                                  ? `You have ${remainingMessageCount} messages left.`
                                  : "You have reached your message limit."}
                              </p>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                }
              </div>
              : ""}

            {/* JOIN WITH CODE */}
            <Dialog
              open={openJoinWithCodePopup}
              onClose={handleCloseJoinWithCodePopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen={openJoinWithCodeGame == conditional_configs.curriculam_types.games ? true : openJoinWithCodeGame == conditional_configs.curriculam_types.whiteboard ? true : false}
              fullWidth={openJoinWithCodeGame !== conditional_configs.curriculam_types.games ? true : openJoinWithCodeGame !== conditional_configs.curriculam_types.whiteboard ? true : false}
            >
              <DialogTitle id="alert-dialog-title" >
                <p className="Join-with-code-text-cgv4 mt-3 mb-0">JOIN WITH CODE</p>
                <span className="close_icon_cgv4" onClick={handleCloseJoinWithCodePopup}>
                  <img
                    className="" width={'30px'}
                    src={assets.course_v4.close_icon}
                  ></img>
                </span>
              </DialogTitle>
              <DialogContent>

                {/* show Success Image */}
                {showSuccessImage && (
                  <div className="success-image-overlay">
                    <img width={'100%'} src={assets.course_v4.code_entered_successfully} alt="Code entered successfully!" />
                  </div>
                )}

                {/* Modal Backdrop */}
                <AnimatePresence>
                  {showHelp && (
                    <motion.div
                      className="backdrop-cgv4"
                      variants={backdropVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      onClick={() => setShowHelp(false)}
                    >
                      <motion.div
                        className="modal-content-for-cgv4"
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={(e) => e.stopPropagation()}
                      >


                        {/* <div className="helper-content-cgv4-cat-img-div">
                         
                          <motion.img
                            alt="Dynamic Cat 1"
                            src={assets.login.login_cat_gif}
                            className="login_cat"
                            initial={{ x: 0, y: 0, scale: 1, opacity: 1 }}
                            animate={{
                              x: [0, -200, 180, 50],
                              y: [0, -100, 120, 50],
                              scale: [1, 1.5, 0.8, 1], // Big to small effect
                              opacity: [1, 0.8, 1, 0.5, 1], // Opacity variation
                              rotate: [0, 20, -20, 0], // Slight rotation
                            }}
                            transition={{
                              duration: 5,
                              repeat: Infinity,
                              ease: "easeInOut",
                            }}
                          />
                        </div> */}



                        <span
                          className="close_icon_for_helper_cgv4"
                          onClick={() => setShowHelp(false)}
                        >
                          <img
                            className=""
                            width={'30px'}
                            src={assets.course_v4.close_icon}
                            alt="Close Icon"
                          />
                        </span>




                        <div className="button-container">
                          {contentType == conditional_configs.curriculam_types.games ?
                            <div
                              className={`circle-button-for-helper ${activeHelpContentDiv === "save-settings" ? "active-circle-button" : ""}`}
                              onClick={() => handleButtonClick("save-settings")}
                              aria-label="Save Settings"
                              title="Save Settings"
                            >
                              <div className="circle-for-helper"></div>
                              <div className="rect-button">Save Settings</div>
                            </div> : <div
                              className={`circle-button-for-helper ${activeHelpContentDiv === "white-board-video" ? "active-circle-button" : ""
                                }`}
                              onClick={() => handleButtonClick("save-settings")}
                            >
                              <div className="circle-for-helper"></div>
                              <div className="rect-button">Whiteboard</div>
                            </div>
                          }


                          <div
                            className={`circle-button-for-helper ${activeHelpContentDiv === "how-to-play" ? "active-circle-button" : ""
                              }`}
                            onClick={() => handleButtonClick("how-to-play")}
                          >
                            <div className="circle-for-helper"></div>
                            <div className="rect-button">Instructions</div>
                          </div>

                          <div
                            className={`circle-button-for-helper ${activeHelpContentDiv === "reach-us" ? "active-circle-button" : ""
                              }`}
                            onClick={() => handleButtonClick("reach-us")}
                          >
                            <div className="circle-for-helper"></div>
                            <div className="rect-button">Reach Us</div>
                          </div>
                        </div>


                        {/* Buttons with motion effects */}



                        {/* Divs */}
                        <div className="helper-content-container-cgv4">
                          {contentType == conditional_configs.curriculam_types.games ? <div>

                            {activeHelpContentDiv === "save-settings" && (
                              <motion.div
                                className="helper-content-for-setting-cgv4"
                                initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                                animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                                transition={{
                                  duration: 1.5,
                                  ease: "easeOut",
                                }}
                                style={{
                                  padding: "18px",
                                  borderRadius: "10px",
                                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                  backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                  backgroundSize: "cover", // Cover the entire div
                                  backgroundPosition: "center", // Center the background
                                  backgroundRepeat: "no-repeat", // Prevent repeating
                                  color: "white", // Ensure text is visible on the background
                                  position: "relative", // For layering text over the image
                                }}
                              >
                                {/* Intro Text with Fade-In and Scale Animation */}
                                {clickedGameDetail.help_for_game_setting && (
                                  <>
                                    <motion.img
                                      alt="Dynamic Cat 1"
                                      src={assets.login.login_cat_gif}
                                      className="login_cat_img_for_cgv4"
                                    // animate={{
                                    //   rotate: [0, 360], // Rotate the image continuously
                                    // }}
                                    // transition={{
                                    //   duration: 6, // Rotate animation duration
                                    //   ease: "easeInOut",
                                    //   repeat: 0, // Rotate only once (no loop)
                                    // }}
                                    />
                                    {/* <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 1.5,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "27px",
                                      fontWeight: "900",
                                      color: "#9add64",
                                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                    }}
                                  >
                                    {clickedGameDetail.help_for_game_setting.title}
                                  </motion.p> */}



                                    <motion.p
                                      initial={{ opacity: 0, scale: 0.8 }}
                                      animate={{ opacity: 1, scale: 1 }}
                                      transition={{
                                        delay: 1.5,
                                        duration: 1,
                                        ease: "easeOut",
                                      }}
                                      style={{
                                        fontSize: "21px",
                                        fontWeight: "900",
                                        color: "#1e0e81",
                                        textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                      }}
                                    >
                                      {clickedGameDetail.help_for_game_setting.intro}
                                    </motion.p>

                                    {/* Steps List with staggered animations */}
                                    {clickedGameDetail.help_for_game_setting.steps.map((step, index) => (
                                      <motion.li
                                        key={index}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{
                                          delay: index * 0.5,
                                          duration: 0.5,
                                          ease: "easeOut",
                                        }}
                                        style={{
                                          marginBottom: "10px",
                                          color: "rgb(6 6 6)",
                                          fontWeight: "700",
                                          fontSize: "18px",
                                          listStyle: "none",
                                          display: "flex",
                                          alignItems: "center",
                                          textShadow: "1px 1px 2px rgba(255, 255, 255, 0.6)", // Improve text visibility
                                        }}
                                      >
                                        <motion.img
                                          className="help-icon"
                                          width="25px"
                                          src={assets.course_v4.small_cat}
                                          alt="Small Cat"
                                          animate={{
                                            rotate: [0, 360], // Rotate the image continuously
                                          }}
                                          transition={{
                                            duration: 6, // Rotate animation duration
                                            ease: "easeInOut",
                                            repeat: 0, // Rotate only once (no loop)
                                          }}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                        {step}
                                      </motion.li>
                                    ))}

                                    {/* Footer Text with Fade-In and Scale Animation */}
                                    <motion.p
                                      initial={{ opacity: 0, scale: 0.8 }}
                                      animate={{ opacity: 1, scale: 1 }}
                                      transition={{
                                        delay: 3,
                                        duration: 1,
                                        ease: "easeOut",
                                      }}
                                      style={{
                                        marginTop: "20px",
                                        marginBottom: "0px",
                                        fontSize: "18px",
                                        color: "#D55200",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {clickedGameDetail.help_for_game_setting.footer}
                                    </motion.p>
                                  </>
                                )}
                              </motion.div>
                            )}

                          </div> : <div>
                            {activeHelpContentDiv === "save-settings" && (
                              <motion.div
                                className="helper-content-for-setting-cgv4"
                                initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                                animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                                transition={{
                                  duration: 1.5,
                                  ease: "easeOut",
                                }}
                                style={{
                                  padding: "15px",
                                  borderRadius: "10px",
                                  background: '#4910b724',
                                  position: "relative", // For layering text over the image
                                }}
                              >

                                <motion.img
                                  alt="Dynamic Cat 1"
                                  src={assets.login.login_cat_gif}
                                  className="login_cat_img_for_cgv4"
                                  style={{
                                    marginRight: "10px",
                                  }} />

                                <div className="mt-2">

                                  <video style={{ borderRadius: '16px' }}
                                    width="100%"
                                    controls
                                    autoPlay
                                    muted
                                    loop
                                  >
                                    <source src={assets.videos.whiteboard_tools_sample} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>

                                </div>

                              </motion.div>
                            )}</div>}

                          {activeHelpContentDiv === "how-to-play" && (
                            <motion.div
                              className="helper-content-for-setting-cgv4"
                              initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                              animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                              transition={{
                                duration: 1.5,
                                ease: "easeOut",
                              }}
                              style={{
                                padding: "18px",
                                borderRadius: "10px",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                backgroundSize: "cover", // Cover the entire div
                                backgroundPosition: "center", // Center the background
                                backgroundRepeat: "no-repeat", // Prevent repeating
                                color: "white", // Ensure text is visible on the background
                                position: "relative", // For layering text over the image
                              }}
                            >
                              {/* Intro Text */}
                              {clickedGameDetail.how_to_play && (
                                <>
                                  <motion.img
                                    alt="Dynamic Cat 1"
                                    src={assets.login.login_cat_gif}
                                    className="login_cat_img_for_cgv4"
                                  // animate={{
                                  //   rotate: [0, 360], // Rotate the image continuously
                                  // }}
                                  // transition={{
                                  //   duration: 6, // Rotate animation duration
                                  //   ease: "easeInOut",
                                  //   repeat: 0, // Rotate only once (no loop)
                                  // }}
                                  />


                                  <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 1.5,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{

                                      fontSize: "21px",
                                      fontWeight: "900",
                                      color: "#1e0e81",
                                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                    }}
                                  >
                                    {clickedGameDetail.how_to_play.title}
                                  </motion.p>


                                  {/* Carousel */}

                                  <div className="slider-container" style={{ width: "90%", }}>
                                    <Slider ref={sliderRef} {...settings}>
                                      {clickedGameDetail?.how_to_play.steps.map((robot, index) => (
                                        <div>
                                          <div key={index} className="carousel-slide">
                                            <div className="robot-name">{robot.step}</div>
                                            <div className="robot-description"> <motion.img
                                              className="help-icon"
                                              width="25px"
                                              src={assets.course_v4.small_cat}
                                              alt="Small Cat"
                                              animate={{
                                                rotate: [0, 360], // Rotate the image continuously
                                              }}
                                              transition={{
                                                duration: 6, // Rotate animation duration
                                                ease: "easeInOut",
                                                repeat: 0, // Rotate only once (no loop)
                                              }}
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />{robot.description}</div>



                                            {/* footer */}
                                            <motion.p
                                              initial={{ opacity: 0, scale: 0.8 }}
                                              animate={{ opacity: 1, scale: 1 }}
                                              transition={{
                                                delay: 3,
                                                duration: 1,
                                                ease: "easeOut",
                                              }}
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "0px",
                                                fontSize: "15px",
                                                color: "#D55200",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {clickedGameDetail.how_to_play.footer}
                                            </motion.p>
                                          </div>
                                        </div>
                                      ))}
                                    </Slider>
                                    <div className="footer-buttons" style={{ marginTop: "10px", textAlign: "end" }}>
                                      <button
                                        onClick={() => sliderRef.current.slickPrev()}
                                        style={{
                                          padding: "4px 15px",
                                          margin: "0 5px",
                                          backgroundColor: "#ff5722",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Prev
                                      </button>
                                      <button
                                        onClick={() => sliderRef.current.slickNext()}
                                        style={{
                                          padding: "4px 15px",
                                          margin: "0 1px",
                                          backgroundColor: "#4caf50",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </>
                              )}
                            </motion.div>
                          )}



                          {activeHelpContentDiv === "reach-us" && (
                            <motion.div
                              className="helper-content-for-setting-cgv4"
                              initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                              animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                              transition={{
                                duration: 1.5,
                                ease: "easeOut",
                              }}
                              style={{
                                padding: "20px",
                                borderRadius: "10px",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                backgroundSize: "cover", // Cover the entire div
                                backgroundPosition: "center", // Center the background
                                backgroundRepeat: "no-repeat", // Prevent repeating
                                color: "white", // Ensure text is visible on the background
                                position: "relative", // For layering text over the image
                              }}
                            >

                              <motion.img
                                alt="Dynamic Cat 1"
                                src={assets.login.login_cat_gif}
                                className="login_cat_img_for_cgv4"
                                // animate={{
                                //   rotate: [0, 360], // Rotate the image continuously
                                // }}
                                // transition={{
                                //   duration: 6, // Rotate animation duration
                                //   ease: "easeInOut",
                                //   repeat: 0, // Rotate only once (no loop)
                                // }}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              <div style={{ paddingTop: "100px", paddingBottom: "100px", textAlign: "center" }}>
                                <h1 style={{ color: "#D55200" }}>admin@meeku.world</h1>
                              </div>

                            </motion.div>
                          )}

                        </div>

                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>

                {!openJoinWithCodeGame ?
                  <div className="row">
                    <div className="col-lg-12">
                      {/* Display error message if it exists */}
                      {joinWithCodeErrorMessage && (
                        <div className="join-with-code-error-msg" style={{ color: "red", display: "flex", alignItems: "center", padding: "8px", backgroundColor: '#ede4e4', borderRadius: '4px' }}>
                          <span>{joinWithCodeErrorMessage}</span>
                          <button className="ms-auto"
                            onClick={handleCloseError}
                            style={{
                              marginLeft: "10px",
                              border: "none",
                              background: "transparent",
                              color: "red",
                              cursor: "pointer",
                              fontSize: "16px",
                              justifyContent: 'end'
                            }}
                            aria-label="Close"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                      <div className="image-container-cgv4">
                        <img
                          className="join_with_code_img_cgv4" width={'100%'}
                          src={assets.course_v4.join_with_code_text_box_background}
                        ></img>

                        <FormProvider methods={methods}>
                          <Stack spacing={3} sx={{ my: 2 }} className="join_with_code_input_cgv4">
                            <RHFTextField name="code" onPaste={handlePaste} label="Enter code" />
                          </Stack>
                          {gameResults?.map((resultObj, index) => (
                            <div>
                              <Accordion
                                key={"learner_game_" + index}
                                id={"learner_game_id_" + index}
                                elevation={12}
                                className="mt-2"
                              >
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  variant="outlined"
                                >
                                  <Typography>
                                    <Stack
                                      className="align-items-center"
                                      direction="row"
                                      spacing={1}
                                    >
                                      <span className="gsp-quiz-question-pop">
                                        {resultObj?.gamename}
                                      </span>
                                      <p className="gsp-quiz-question-pop">
                                        ({fDate(resultObj.createdAt)})
                                      </p>
                                    </Stack>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {resultObj?.puzzleresultsdetail.map(
                                    (lObj, aIndex) => (
                                      <Card
                                        key={`game-id-` + aIndex}
                                        className="mb-2 p-2"
                                      >
                                        <div>
                                          {Object.entries(lObj?.results[0]).map(
                                            ([property, value]) => (
                                              <div
                                                key={property}
                                                className="align-items-center"
                                              >
                                                <span className="gsp-game-property-text">
                                                  {property}:{" "}
                                                  <span className="gsp-quiz-question-pop">
                                                    {value}
                                                  </span>
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Card>
                                    )
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          ))}

                          <div className="d-flex justify-content-end align-items-center">
                            <button type="submit" className="Leader-board-btn-cgv4 me-2" onClick={handleSubmit(onShowResultSubmit)}>
                              <p className="mb-0 me-2">Leader board</p> <img
                                className="" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button>
                            <button type="submit" className="Join-with-code-btn-cgv4 pe-0 me-2" loading={isSubmitting} onClick={handleSubmit(onJoinWithCodeSubmit)}>
                              <p className="mb-0 me-2">Join with code</p>
                            </button>
                          </div>
                        </FormProvider>
                      </div>
                    </div>
                  </div> :
                  <div className="h-100 w-100">
                    {openJoinWithCodeGame == conditional_configs.curriculam_types.games ?
                      <iframe id="self-learning-game-play" width="100%" height="100%" src={gameSettingLink}></iframe>
                      :
                      openJoinWithCodeGame == conditional_configs.curriculam_types.whiteboard ?
                        <div className="h-100">
                          <div className="h-100">
                            <div className="whiteboard-tabs-gsp">
                              {whiteBoardTabList.map((wTab, wIndex) => {
                                return (
                                  <div
                                    key={"id_whiteboard-" + wIndex}
                                    className="whiteboard-tab-container-gsp"
                                  >
                                    <div
                                      className={
                                        wTab?.active
                                          ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                                          : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                                      }
                                      onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                                    >
                                      <div className="whiteboard-tab-name-gsp">
                                        {wTab.tabName}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            </div>
                            {whiteboardLink ? <iframe
                              id={"l-pod-grpsp-board-" + whiteboardKey}
                              className="self-learning-whiteboard-iframe-gsp"
                              height="93%"
                              src={whiteboardLink}
                            ></iframe> : <div className="self-learning-empty-board">Please wait</div>}
                          </div>
                        </div> : ""}
                  </div>}
              </DialogContent>
              {/* <DialogActions >
          <Button className="share-code-start-game-btn mb-3">Join</Button>
        </DialogActions> */}

              {enableChatWindow ?
                <div className="l-pods-chat-container-cwv4">
                  <div className="l-pods-chat-main">
                    <div className="l-pods-chat-header">
                      <div className="d-flex align-items-center">
                        <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                          <img
                            className="me-1" width={''}
                            src={assets.course_v4.left_arrow}
                          ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                        </IconButton>
                        <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                          <img
                            className="" width={'30px'}
                            src={assets.course_v4.close_icon}
                          ></img>
                        </span>
                      </div>
                      <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                    </div>
                    <div className="l-pods-chat-body">{renderChatWindow()}</div>
                    <div className="my-friends-chat-text-box-main-div">
                      <div className="text-clips-container">
                        {textClips.map((clip, index) => (
                          <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                            {clip}
                          </button>
                        ))}
                      </div>
                      <form
                        className="w-100 d-flex align-items-center"
                        action=""
                        onSubmit={(e) => handleChatSubmit(e)}
                      >
                        {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                    <label for="image-file-input-2">
                                <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                              </label>
                              <input
                                id="image-file-input-2"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                              />
                    </div> */}
                        <textarea
                          id="ind-chat-input-id"
                          rows={"1"}
                          className="form-control ind-chat-input-cgv4"
                          value={message}
                          onKeyPress={handleKeyPressForGroup}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type here..."
                          disabled={remainingMessageCount === 0}
                        />
                        <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                          <IconButton type="submit" disabled={remainingMessageCount === 0}>
                            <img
                              className="" width={'40px'}
                              src={assets.course_v4.send_arrow}
                            ></img>
                          </IconButton>
                        </div>
                      </form>
                      {/* Display message */}
                      <p className="mb-0 message-limited-text-cgv4">
                        {remainingMessageCount > 0
                          ? `You have ${remainingMessageCount} messages left.`
                          : "You have reached your message limit."}
                      </p>
                    </div>
                  </div>
                </div>
                : ""}
              {openJoinWithCodeGame ?
                <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
                  {getIcon(icons_config.chat)}
                </IconButton> : ""}

              {openJoinWithCodeGame ?
                <Tooltip title={"Help"}><IconButton className="helper_btn_cgv4" onClick={() => setShowHelp(true)}><img
                  width="30px"
                  src={assets.course_v4.small_cat}
                ></img></IconButton></Tooltip> : ""}

            </Dialog>

            {/* OPEN SHARE CODE GAME POPUP */}
            <Dialog
              open={openGameShareCodePopup}
              onClose={handleGamesShareCodePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              fullScreen={openGameSettings}
            >
              <DialogTitle id="alert-dialog-title">
                <p className="Join-with-code-text-cgv4 text-align-center mt-1 mb-0"> {getGameDetail(openedContent?.game_id)?.name}</p>

                <span className="close_icon_cgv4" onClick={handleGamesShareCodePopupClose}>
                  <img
                    className="" width={'30px'}
                    src={assets.course_v4.close_icon}
                  ></img>
                </span>
                {/* {!openGameSettings ? <span className="close_icon_cgv4" onClick={handleGamesShareCodePopupClose}>
            <CloseIcon />
          </span> :
            <span className="close_icon_cgv4" onClick={setOpenGameSettings(false)}>
              <CloseIcon />
            </span>
            } */}
              </DialogTitle>

              <DialogContent>

                {openGameSettings == false ?
                  <p className="people-you-know-text-cgv4 mb-1">Invite friends using the share code</p>
                  : ""}

                {openGameSettings == false ?
                  <div className="tour-btn-div-cgv4"> <TourManager></TourManager></div>
                  : ""}

                {/* Modal Backdrop */}
                <AnimatePresence>
                  {showHelp && (
                    <motion.div
                      className="backdrop-cgv4"
                      variants={backdropVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      onClick={() => setShowHelp(false)}
                    >
                      <motion.div
                        className="modal-content-for-cgv4"
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={(e) => e.stopPropagation()}
                      >


                        {/* <div className="helper-content-cgv4-cat-img-div">
                         
                          <motion.img
                            alt="Dynamic Cat 1"
                            src={assets.login.login_cat_gif}
                            className="login_cat"
                            initial={{ x: 0, y: 0, scale: 1, opacity: 1 }}
                            animate={{
                              x: [0, -200, 180, 50],
                              y: [0, -100, 120, 50],
                              scale: [1, 1.5, 0.8, 1], // Big to small effect
                              opacity: [1, 0.8, 1, 0.5, 1], // Opacity variation
                              rotate: [0, 20, -20, 0], // Slight rotation
                            }}
                            transition={{
                              duration: 5,
                              repeat: Infinity,
                              ease: "easeInOut",
                            }}
                          />
                        </div> */}



                        <span
                          className="close_icon_for_helper_cgv4"
                          onClick={() => setShowHelp(false)}
                        >
                          <img
                            className=""
                            width={'30px'}
                            src={assets.course_v4.close_icon}
                            alt="Close Icon"
                          />
                        </span>




                        <div className="button-container">
                          {clickedGameDetail.help_for_game_setting && (
                            <div
                              className={`circle-button-for-helper ${activeHelpContentDiv === "save-settings" ? "active-circle-button" : ""}`}
                              onClick={() => handleButtonClick("save-settings")}
                              aria-label="Save Settings"
                              title="Save Settings"
                            >
                              <div className="circle-for-helper"></div>
                              <div className="rect-button">Save Settings</div>
                            </div>
                          )}


                          <div
                            className={`circle-button-for-helper ${activeHelpContentDiv === "how-to-play" ? "active-circle-button" : ""
                              }`}
                            onClick={() => handleButtonClick("how-to-play")}
                          >
                            <div className="circle-for-helper"></div>
                            <div className="rect-button">Instructions</div>
                          </div>

                          <div
                            className={`circle-button-for-helper ${activeHelpContentDiv === "reach-us" ? "active-circle-button" : ""
                              }`}
                            onClick={() => handleButtonClick("reach-us")}
                          >
                            <div className="circle-for-helper"></div>
                            <div className="rect-button">Reach Us</div>
                          </div>
                        </div>


                        {/* Buttons with motion effects */}



                        {/* Divs */}
                        <div className="helper-content-container-cgv4">
                          {activeHelpContentDiv === "save-settings" && (
                            <motion.div
                              className="helper-content-for-setting-cgv4"
                              initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                              animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                              transition={{
                                duration: 1.5,
                                ease: "easeOut",
                              }}
                              style={{
                                padding: "18px",
                                borderRadius: "10px",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                backgroundSize: "cover", // Cover the entire div
                                backgroundPosition: "center", // Center the background
                                backgroundRepeat: "no-repeat", // Prevent repeating
                                color: "white", // Ensure text is visible on the background
                                position: "relative", // For layering text over the image
                              }}
                            >
                              {/* Intro Text with Fade-In and Scale Animation */}
                              {clickedGameDetail.help_for_game_setting && (
                                <>
                                  <motion.img
                                    alt="Dynamic Cat 1"
                                    src={assets.login.login_cat_gif}
                                    className="login_cat_img_for_cgv4"
                                  // animate={{
                                  //   rotate: [0, 360], // Rotate the image continuously
                                  // }}
                                  // transition={{
                                  //   duration: 6, // Rotate animation duration
                                  //   ease: "easeInOut",
                                  //   repeat: 0, // Rotate only once (no loop)
                                  // }}
                                  />
                                  {/* <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 1.5,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "27px",
                                      fontWeight: "900",
                                      color: "#9add64",
                                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                    }}
                                  >
                                    {clickedGameDetail.help_for_game_setting.title}
                                  </motion.p> */}



                                  <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 1.5,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: "900",
                                      color: "#1e0e81",
                                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                    }}
                                  >
                                    {clickedGameDetail.help_for_game_setting.intro}
                                  </motion.p>

                                  {/* Steps List with staggered animations */}
                                  {clickedGameDetail.help_for_game_setting.steps.map((step, index) => (
                                    <motion.li
                                      key={index}
                                      initial={{ opacity: 0, x: -20 }}
                                      animate={{ opacity: 1, x: 0 }}
                                      transition={{
                                        delay: index * 0.5,
                                        duration: 0.5,
                                        ease: "easeOut",
                                      }}
                                      style={{
                                        marginBottom: "10px",
                                        color: "rgb(6 6 6)",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        listStyle: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        textShadow: "1px 1px 2px rgba(255, 255, 255, 0.6)", // Improve text visibility
                                      }}
                                    >
                                      <motion.img
                                        className="help-icon"
                                        width="25px"
                                        src={assets.course_v4.small_cat}
                                        alt="Small Cat"
                                        animate={{
                                          rotate: [0, 360], // Rotate the image continuously
                                        }}
                                        transition={{
                                          duration: 6, // Rotate animation duration
                                          ease: "easeInOut",
                                          repeat: 0, // Rotate only once (no loop)
                                        }}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                      {step}
                                    </motion.li>
                                  ))}

                                  {/* Footer Text with Fade-In and Scale Animation */}
                                  <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 3,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "0px",
                                      fontSize: "18px",
                                      color: "#D55200",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {clickedGameDetail.help_for_game_setting.footer}
                                  </motion.p>
                                </>
                              )}
                            </motion.div>
                          )}



                          {activeHelpContentDiv === "how-to-play" && (
                            <motion.div
                              className="helper-content-for-setting-cgv4"
                              initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                              animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                              transition={{
                                duration: 1.5,
                                ease: "easeOut",
                              }}
                              style={{
                                padding: "18px",
                                borderRadius: "10px",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                backgroundSize: "cover", // Cover the entire div
                                backgroundPosition: "center", // Center the background
                                backgroundRepeat: "no-repeat", // Prevent repeating
                                color: "white", // Ensure text is visible on the background
                                position: "relative", // For layering text over the image
                              }}
                            >
                              {/* Intro Text */}
                              {clickedGameDetail.how_to_play && (
                                <>
                                  <motion.img
                                    alt="Dynamic Cat 1"
                                    src={assets.login.login_cat_gif}
                                    className="login_cat_img_for_cgv4"
                                  // animate={{
                                  //   rotate: [0, 360], // Rotate the image continuously
                                  // }}
                                  // transition={{
                                  //   duration: 6, // Rotate animation duration
                                  //   ease: "easeInOut",
                                  //   repeat: 0, // Rotate only once (no loop)
                                  // }}
                                  />


                                  <motion.p
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 1.5,
                                      duration: 1,
                                      ease: "easeOut",
                                    }}
                                    style={{

                                      fontSize: "21px",
                                      fontWeight: "900",
                                      color: "#1e0e81",
                                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
                                    }}
                                  >
                                    {clickedGameDetail.how_to_play.title}
                                  </motion.p>


                                  {/* Carousel */}

                                  <div className="slider-container" style={{ width: "90%", }}>
                                    <Slider ref={sliderRef} {...settings}>
                                      {clickedGameDetail?.how_to_play.steps.map((robot, index) => (
                                        <div>
                                          <div key={index} className="carousel-slide">
                                            <div className="robot-name">{robot.step}</div>
                                            <div className="robot-description"> <motion.img
                                              className="help-icon"
                                              width="25px"
                                              src={assets.course_v4.small_cat}
                                              alt="Small Cat"
                                              animate={{
                                                rotate: [0, 360], // Rotate the image continuously
                                              }}
                                              transition={{
                                                duration: 6, // Rotate animation duration
                                                ease: "easeInOut",
                                                repeat: 0, // Rotate only once (no loop)
                                              }}
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />{robot.description}</div>



                                            {/* footer */}
                                            <motion.p
                                              initial={{ opacity: 0, scale: 0.8 }}
                                              animate={{ opacity: 1, scale: 1 }}
                                              transition={{
                                                delay: 3,
                                                duration: 1,
                                                ease: "easeOut",
                                              }}
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "0px",
                                                fontSize: "15px",
                                                color: "#D55200",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {clickedGameDetail.how_to_play.footer}
                                            </motion.p>
                                          </div>
                                        </div>
                                      ))}
                                    </Slider>
                                    <div className="footer-buttons" style={{ marginTop: "10px", textAlign: "end" }}>
                                      <button
                                        onClick={() => sliderRef.current.slickPrev()}
                                        style={{
                                          padding: "4px 15px",
                                          margin: "0 5px",
                                          backgroundColor: "#ff5722",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Prev
                                      </button>
                                      <button
                                        onClick={() => sliderRef.current.slickNext()}
                                        style={{
                                          padding: "4px 15px",
                                          margin: "0 1px",
                                          backgroundColor: "#4caf50",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </>
                              )}
                            </motion.div>
                          )}



                          {activeHelpContentDiv === "reach-us" && (
                            <motion.div
                              className="helper-content-for-setting-cgv4"
                              initial={{ opacity: 0, scale: 0.8, rotateX: 30 }}
                              animate={{ opacity: 1, scale: 1, rotateX: 0 }}
                              transition={{
                                duration: 1.5,
                                ease: "easeOut",
                              }}
                              style={{
                                padding: "20px",
                                borderRadius: "10px",
                                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                                backgroundImage: `url(${assets.course_v4.helper_full_steps})`, // Add the background image
                                backgroundSize: "cover", // Cover the entire div
                                backgroundPosition: "center", // Center the background
                                backgroundRepeat: "no-repeat", // Prevent repeating
                                color: "white", // Ensure text is visible on the background
                                position: "relative", // For layering text over the image
                              }}
                            >

                              <motion.img
                                alt="Dynamic Cat 1"
                                src={assets.login.login_cat_gif}
                                className="login_cat_img_for_cgv4"
                                // animate={{
                                //   rotate: [0, 360], // Rotate the image continuously
                                // }}
                                // transition={{
                                //   duration: 6, // Rotate animation duration
                                //   ease: "easeInOut",
                                //   repeat: 0, // Rotate only once (no loop)
                                // }}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              <div style={{ paddingTop: "100px", paddingBottom: "100px", textAlign: "center" }}>
                                <h1 style={{ color: "#D55200" }}>admin@meeku.world</h1>
                              </div>

                            </motion.div>
                          )}

                        </div>

                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>








                {openGameSettings == false ?
                  // SHARE CODE DESIGN
                  <div className="card game-card-cgv4">

                    <div style={game_card_img}>

                      <div className="share-code-text-cgv4">
                        <div className="row ms-0 me-0">
                          <div className="col-lg-6 col-md-5 col-sm-6 col-12">

                          </div>
                          <div className="col-lg-6 col-md-7 col-sm-6 col-12">
                            <div className="">
                              <div>
                                <p className="player-text-cgv4 player-list-tour-2">PLAYERS</p>
                                {players.length == 0 ? <p className="no-players-text-cgv4">No Players in this Group</p> : ""}
                                {players.map((obj, index) => {
                                  return (
                                    <span key={`playesrs-` + index} className="d-flex mb-2">
                                      <span className="players-status-dot me-2"></span>
                                      <div>
                                        <div>
                                          <p className="player-name-text-cgv4 mb-0 me-1">{obj.name}</p>
                                          {getCurrentUserData().id !== obj.id ?
                                            <div className="remove-player" onClick={(e) => handleRemovePlayerClick(e, obj.id)}>
                                              <img
                                                className="cursor-pointer" width={13}
                                                src={assets.course_v4.delete_icon}
                                              ></img>
                                            </div>
                                            :
                                            <span className="player-master-cgv4">You</span>
                                          }</div>
                                        {showConfirmModal && (
                                          <div className="modal-overlay-cgv4">
                                            <div className="modal-content-cgv4">
                                              <div className="d-flex">
                                                <p className="Join-with-code-text-cgv4 mt-1 mb-0">DELETE</p>
                                                <span className="cursor-pointer ms-auto" onClick={handleCancelPlayerRemove}>
                                                  <img
                                                    className="" width={'30px'}
                                                    src={assets.course_v4.close_icon}
                                                  ></img>
                                                </span>
                                              </div>

                                              <p className="are_you_sure_text_cgv4 pt-2">Are you sure you want to remove this player?</p>

                                              <div className="d-flex justify-content-center align-items-center pb-3">
                                                <button type="submit" className="No-btn-cgv4 me-2" onClick={handleCancelPlayerRemove}>
                                                  <p className="mb-0">No</p>
                                                </button>
                                                <button type="submit" className="Yes-btn-cgv4" onClick={handleConfirmPlayerRemove}>
                                                  <p className="mb-0 me-2">Yes</p> <img
                                                    className="" width={40}
                                                    src={assets.homev4.white_with_blue_arrow}
                                                  ></img>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                            {openedGroupData && openedGroupData.code ?
                              <div>
                                {/* <p className="shar-this-code-text-cgv4 mb-1">Use this code to connect.</p> */}

                                <div className="share-code-div-cgv4 share-code-tour-3">
                                  <span className="mb-0"><strong>SHARE CODE&nbsp;:</strong>&nbsp;</span>
                                  <span className="mb-0 d-flex align-items-center">{openedGroupData.code}

                                    <ContentCopyIcon
                                      className="cursor-pointer ms-1" sx={{ fontSize: 16 }}
                                      onClick={(e) => copyShareCodeLink()}
                                    />
                                    {/* <img
                                      className="cursor-pointer" width={30}
                                      src={assets.self_learning.copyLink}
                                      onClick={(e) => copyShareCodeLink()}
                                    ></img> */}
                                  </span>

                                </div>
                                {copyMessage && <span className="copy-code-text-cgv4">{copyMessage}</span>}
                                {/* <p className="copy-code-text-cgv4">Copy Code</p> */}
                              </div>
                              :
                              ""}
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="d-flex align-items-center pt-4 pb-4">
                      <img
                        className="ms-3 hide-on-mobile-view-cgv4" width={55}
                        src={assets.course_v4.plus_arrow}
                      />
                      <div className="ms-auto">
                        {!openGameSettings && openedGroupData && openedGroupData.code ?
                          <div className="d-flex align-items-center justify-content-end me-3">
                            <button className="instruction-btn-tour-4 Description-btn-cgv4 p-2 me-2" onClick={() => navigateToCoursesDescription(getGameDetail(openedContent?.game_id)?.name, openedContent)}>
                              <p className="mb-0">Instruction</p>
                            </button>


                            {/* <button className="start-game-cgv4 me-2" onClick={(e) => openLeaderBoardEvent(e)}>
                              <p className="mb-0 me-2 ">Leader Board</p>  <img
                                className="hide-on-mobile-view-cgv4" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button> */}
                            <button className="begin-btn-tour-1 start-game-cgv4" onClick={(e) => startGame(e)}>
                              <p className="mb-0 me-2 ">Start game</p>  <img
                                className="hide-on-mobile-view-cgv4" width={40}
                                src={assets.homev4.white_with_blue_arrow}
                              ></img>
                            </button>

                          </div>
                          :
                          !openGameSettings ?
                            <div className="d-flex align-items-center justify-content-end me-3">
                              <button className="instruction-btn-tour-4 Description-btn-cgv4 p-2 me-2" onClick={() => navigateToCoursesDescription(getGameDetail(openedContent?.game_id)?.name, openedContent)}>
                                <p className="mb-0">Instruction</p>
                              </button>

                              {/* <button className="start-game-cgv4 me-2" onClick={(e) => openLeaderBoardEvent(e)}>
                                <p className="mb-0 me-2 ">Leader Board</p>  <img
                                  className="hide-on-mobile-view-cgv4" width={40}
                                  src={assets.homev4.white_with_blue_arrow}
                                ></img>
                              </button> */}
                              <button className="start-game-cgv4 begin-btn-tour-1" onClick={(e) => shareCode(e, conditional_configs.curriculam_types.games)}>
                                <p className="mb-0 me-2">Begin</p>  <img
                                  className="hide-on-mobile-view-cgv4" width={40}
                                  src={assets.homev4.white_with_blue_arrow}
                                ></img>
                              </button>

                            </div>
                            : ""
                        }
                      </div>
                    </div>

                  </div> :
                  // LEADER BOARD DESIGN
                  openGameSettings == true && openLeaderBoard == true ?

                    <div className="leader-board-containder">

                      {currentLeaderBoard ? <div>
                        {Object.keys(currentLeaderBoard).map((category) => (
                          <div key={category}>
                            <h5>{currentLeaderBoard[category][0]?.name}</h5>
                            <ul>
                              {currentLeaderBoard[category].map((resultObj, index) => (
                                <div>
                                  <Accordion
                                    key={"learner_game_" + index}
                                    id={"learner_game_id_" + index}
                                    elevation={12}
                                    className="mt-2"
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1a-content"
                                      variant="outlined"
                                    >
                                      <Typography>
                                        <Stack
                                          className="align-items-center"
                                          direction="row"
                                          spacing={1}
                                        >
                                          <p className="gsp-quiz-question-pop">
                                            ({fDate(resultObj.createdAt)})
                                          </p>
                                        </Stack>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {resultObj?.puzzleresultsdetail.map(
                                        (lObj, aIndex) => (

                                          <Card
                                            key={`game-id-` + aIndex}
                                            className="mb-2 p-2"
                                          >

                                            <div>
                                              {Object.entries(lObj?.results[0]).map(
                                                ([property, value]) => (
                                                  <div
                                                    key={property}
                                                    className="align-items-center"
                                                  >
                                                    <span className="gsp-game-property-text">
                                                      {property}:{" "}
                                                      <span className="gsp-quiz-question-pop">
                                                        {value}
                                                      </span>
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </Card>
                                        )
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div> : <div>No records found</div>}
                    </div> :
                    <div className="h-100 w-100">
                      <iframe id="self-learning-game-settings" width="100%" height="100%" src={gameSettingLink}></iframe>
                    </div>

                }
              </DialogContent>

              {enableChatWindow ?
                <div className="l-pods-chat-container-cwv4">
                  <div className="l-pods-chat-main">
                    <div className="l-pods-chat-header">

                      <div className="d-flex align-items-center">
                        <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                          <img
                            className="me-1" width={''}
                            src={assets.course_v4.left_arrow}
                          ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                        </IconButton>
                        <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                          <img
                            className="" width={'30px'}
                            src={assets.course_v4.close_icon}
                          ></img>
                        </span>
                      </div>
                      <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                    </div>
                    <div className="l-pods-chat-body">{renderChatWindow()}</div>
                    <div className="my-friends-chat-text-box-main-div">
                      <div className="text-clips-container">
                        {textClips.map((clip, index) => (
                          <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                            {clip}
                          </button>
                        ))}
                      </div>
                      <form
                        className="w-100 d-flex align-items-center"
                        action=""
                        onSubmit={(e) => handleChatSubmit(e)}
                      >
                        {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                    <label for="image-file-input-3">
                                <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                              </label>
                              <input
                                id="image-file-input-3"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                              />
                    </div> */}
                        <textarea
                          id="ind-chat-input-id"
                          rows={"1"}
                          className="form-control ind-chat-input-cgv4"
                          value={message}
                          onKeyPress={handleKeyPressForGroup}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type here..."
                          disabled={remainingMessageCount === 0}
                        />
                        <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                          <IconButton type="submit" disabled={remainingMessageCount === 0}>
                            <img
                              className="" width={'40px'}
                              src={assets.course_v4.send_arrow}
                            ></img>
                          </IconButton>
                        </div>
                      </form>
                      {/* Display message */}
                      <p className="mb-0 message-limited-text-cgv4">
                        {remainingMessageCount > 0
                          ? `You have ${remainingMessageCount} messages left.`
                          : "You have reached your message limit."}
                      </p>
                    </div>
                  </div>
                </div>
                : ""}
              {openGameSettings ? <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
                {getIcon(icons_config.chat)}
              </IconButton> : ""}
              {openGameSettings || openLeaderBoard ? (
                <Tooltip title={"Help"}><IconButton className="helper_btn_cgv4" onClick={() => setShowHelp(true)}><img
                  width="30px"
                  src={assets.course_v4.small_cat}
                ></img></IconButton></Tooltip>

              ) : null}
            </Dialog>

            {/* OPEN SHARE CODE WHITEBOARD POPUP */}
            <Dialog
              open={openBoardShareCodePopup}
              onClose={handleBoardShareCodePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen={openWhiteboardTabScreen}
            >
              <DialogTitle id="alert-dialog-title" >
                <span>{openedContent?.name}</span>

                <span className="self-learning-w-close" onClick={handleBoardShareCodePopupClose}>
                  <CloseIcon />
                </span>
              </DialogTitle>
              <DialogContent>
                {!openWhiteboardTabScreen ?
                  <div>
                    {openedGroupData && openedGroupData.code ?
                      <div className="share-code-text">
                        <span>Code:&nbsp;</span>
                        <span>{openedGroupData?.code}</span>
                      </div>
                      : ""
                      // <div className="share-code-btn" onClick={(e) => shareCode(e, conditional_configs.curriculam_types.whiteboard)}>
                      //   Begin
                      // </div>
                    }
                    <div className="row">
                      <div className="col-lg-6">
                        <img width={500} height={300} alt={openedContent?.name} src={assets.whiteboard}></img>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        <div>
                          {players && players.length > 0 ? <Typography variant="h5">Members:</Typography> : ""}
                          {players.map((obj, index) => {
                            return (
                              <span key={`playesrs-` + index} className=" d-flex align-items-center">
                                <span className="players-status-dot me-2"></span>
                                {obj?.name}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="h-100">
                    <div className="share-code-text-in-detail mb-2">
                      <span>Code:&nbsp;</span>
                      <span>{openedGroupData?.code}</span>
                    </div>

                    <div className="whiteboard-tabs-gsp">
                      {whiteBoardTabList.map((wTab, wIndex) => {
                        return (
                          <div
                            key={"id_whiteboard-" + wIndex}
                            className="whiteboard-tab-container-gsp"
                          >
                            <div
                              className={
                                wTab?.active
                                  ? "whiteboard-active-gsp whiteboard-tab-button-gsp"
                                  : "whiteboard-in-active-gsp whiteboard-tab-button-gsp"
                              }
                              onClick={(e) => openWhiteBoard(e, wTab, wIndex)}
                            >
                              <div className="whiteboard-tab-name-gsp">
                                {wTab.tabName}

                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </div>
                    {whiteboardLink ? <iframe
                      id={"l-pod-grpsp-board-" + whiteboardKey}
                      className="self-learning-whiteboard-iframe-gsp"
                      height="93%"
                      src={whiteboardLink}
                    ></iframe> : <div className="self-learning-empty-board">Please wait</div>}
                  </div>}


              </DialogContent>
              {!openWhiteboardTabScreen && openedGroupData && openedGroupData.code ?
                <DialogActions >
                  <Button className="share-code-start-game-btn mb-3" onClick={(e) => openBoard(e)}>Open</Button>
                </DialogActions> :
                !openWhiteboardTabScreen ?
                  <DialogActions >
                    <Button className="share-code-start-game-btn mb-3" onClick={(e) => shareCode(e, conditional_configs.curriculam_types.whiteboard)}>Begin</Button>
                  </DialogActions> : ""
              }
              {/* {!openGameSettings ? <DialogActions >
          <Button className="share-code-start-game-btn mb-3" onClick={(e) => startGame(e)}>Start</Button>
        </DialogActions> : ""} */}
              {enableChatWindow ?
                <div className="l-pods-chat-container-cwv4">
                  <div className="l-pods-chat-main">
                    <div className="l-pods-chat-header">

                      <div className="d-flex align-items-center">
                        <IconButton aria-label="Back" type="button" onClick={(e) => closeChat(e)}>
                          <img
                            className="me-1" width={''}
                            src={assets.course_v4.left_arrow}
                          ></img> <div className="my-friends-invite-title-cvg4">CHAT</div>
                        </IconButton>
                        <span className="ms-auto cursor-pointer" onClick={(e) => closeChat(e)} >
                          <img
                            className="" width={'30px'}
                            src={assets.course_v4.close_icon}
                          ></img>
                        </span>
                      </div>
                      <p className="don-t-share-personal-text mb-0">Please avoid sharing personal information.</p>
                    </div>
                    <div className="l-pods-chat-body">{renderChatWindow()}</div>
                    <div className="my-friends-chat-text-box-main-div">
                      <div className="text-clips-container">
                        {textClips.map((clip, index) => (
                          <button key={index} className="text-clip-button" onClick={() => sendTextClipForGroup(clip)}>
                            {clip}
                          </button>
                        ))}
                      </div>
                      <form
                        className="w-100 d-flex align-items-center"
                        action=""
                        onSubmit={(e) => handleChatSubmit(e)}
                      >
                        {/* <div className="icon-blue-border-cgv4 d-flex align-items-center justify-content-center">
                    <label for="image-file-input-4">
                                <PermMediaIcon sx={{ color: "#4011C6", fontSize: 17 }}></PermMediaIcon>
                              </label>
                              <input
                                id="image-file-input-4"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleImageUpload(e)}
                              />
                    </div> */}
                        <textarea
                          id="ind-chat-input-id"
                          rows={"1"}
                          className="form-control ind-chat-input-cgv4"
                          value={message}
                          onKeyPress={handleKeyPressForGroup}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type here..."
                          disabled={remainingMessageCount === 0}
                        />

                        <div className="ind-chat-send-btn d-flex align-items-center justify-content-center">
                          <IconButton type="submit" disabled={remainingMessageCount === 0}>
                            <img
                              className="" width={'40px'}
                              src={assets.course_v4.send_arrow}
                            ></img>
                          </IconButton>
                        </div>
                      </form>
                      {/* Display message */}
                      <p className="mb-0 message-limited-text-cgv4">
                        {remainingMessageCount > 0
                          ? `You have ${remainingMessageCount} messages left.`
                          : "You have reached your message limit."}
                      </p>
                    </div>
                  </div>
                </div>
                : ""}
              {openWhiteboardTabScreen ?
                <IconButton className="chat-floating-icon" onClick={(e) => openChatWindow(e)}>
                  {getIcon(icons_config.chat)}
                </IconButton> : ""}
            </Dialog>

            {/* OPEN INVITE FRIEND POPUP */}
            <Dialog
              open={inviteFriendPopup}
              onClose={handleCloseInviteFriendPopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth="true"
            >
              <DialogTitle id="alert-dialog-title" >
                <span>Invite Friend</span>

                <span className="self-learning-w-close" onClick={(e) => handleCloseInviteFriendPopup(e)}>
                  <CloseIcon />
                </span>
              </DialogTitle>
              <DialogContent>
                <form className="mt-4">
                  <TextField
                    id={'invite-friend-id'}
                    label="Email/Username"
                    variant="outlined"
                    fullWidth
                    name="invite-friend-name"
                    onChange={handleInviteFriendUserNameChange}
                  />
                </form>
              </DialogContent>
              <DialogActions >
                <Button className="share-code-start-game-btn mb-3" type="submit" onClick={(e) => onSubmitInviteFriend(e)}>Submit</Button>
              </DialogActions>
            </Dialog>

          </Container>
        </div>
      )}
    </div>
  )
}
