import api_route_config from "./api-route-config";

const api_path = {
  changeKidPassword: api_route_config.membersAccessPath + "/changeKidPassword",
  updateProfileImage: api_route_config.memberPath + "/updateProfileImage",
  deleteDocumentId: api_route_config.memberPath + "/deleteDocumentId",
  //AFFILIATE MEMBERS
  getAffiliateMemberByRefCode:
    "/affiliate-members/getAffiliateMemberByRefCode/",

  //MEMBERS
  m_activate: api_route_config.memberPath + "/activate",
  forgot_password: api_route_config.memberPath + "/forgetpassword",
  m_reset_password: api_route_config.memberPath + "/changepassword",
  getMembersById: api_route_config.memberPath + "/",
  updateMembers: api_route_config.memberPath + "/",
  changeNewPassword: api_route_config.memberPath + "/ChangeNewPassword" + "/",
  updateKidDetails: api_route_config.memberPath + "/updateKidDetails",

  getUserDetailsByUserId: api_route_config.memberPath + "/getUserDetailsByUserId" + "/",

  //MEMBERCOURSES/SESSIONS START/END
  startSessionByExpert:
    api_route_config.memberCoursePath + "/membercourse/startsession",
  stopSessionByExpert:
    api_route_config.memberCoursePath + "/membercourse/stopsession",
  joinSessionByLearner:
    api_route_config.memberCoursePath + "/membercourse/joinsession",
  endSessionByLearner:
    api_route_config.memberCoursePath + "/membercourse/endsession",
  getCourseContentBySessionId:
    api_route_config.expertPath + "/GetCourseContentBySessionId/",
  getCourseContentBymembercoursestreamdetailid:
    api_route_config.learnerPath +
    "/GetCourseContentBymembercoursestreamdetailid/",
  getSessionDocuments:
    api_route_config.memberCoursePath +
    "/membercoursegroupdocuments/GetByMemberCourseGroupId/",
  uploadDocumnetInSession:
    api_route_config.memberCoursePath + "/membercoursegroupdocuments/create",
  getPendingSchedules:
    "membercourse/membercoursestreamdetails/getPendingSchedules/",
  getPendingSchedulesExpert:
    "membercourse/membercoursestreamdetails/getPendingSchedulesExpert/",

  //GROUP SPACE TASK
  m_task_list: "/membercourses/membercoursetask/GetTaskListByStatusAndStream",
  createTask: api_route_config.memberCoursePath + "/membercoursetask/create",
  changeStatus:
    api_route_config.memberCoursePath + "/membercoursetask/ChangeStatus/",
  deleteTask: api_route_config.memberCoursePath + "/membercoursetask/",
  getMemberCourseGroupByStreamId:
    api_route_config.memberCourseGroup + "/getMemberCourseGroupByStreamId/",
  getMemberCourseGroupByStreamIdNew:
    api_route_config.memberCourseGroup + "/getMemberCourseGroupByStreamIdNew/",
  getMemberCourseGroupByStreamAndLearnerId:
    api_route_config.memberCourseGroup +
    "/getMemberCourseGroupByStreamAndLearnerId",
  createCourseGroup: "/coursegroup",
  editGroupName: "/coursegroup/editGroupName",
  createMemberCourseGroup: api_route_config.memberCourseGroup,
  getMemberOnlyCourseGroupByStreamId:
    api_route_config.memberCourseGroup + "/getMemberOnlyCourseGroupByStreamId/",
  updateMemberGroup: api_route_config.memberCourseGroup + "/UpdateMemberGroup",
  deleteMemberCourseGroup: api_route_config.memberCourseGroup + "/",
  deleteCourseGroup: "/coursegroup/",

  getAllActiveMemberStreamBoard:
    api_route_config.memberStreamBoard + "/getAllActiveByUser/",

  getAllActiveByGroupId:
    api_route_config.memberStreamBoard + "/getAllActiveByGroupId/",
  addMemberStreamBoard: api_route_config.memberStreamBoard,
  softDeleteMemberStreamBoard: api_route_config.memberStreamBoard + "/delete/",
  //ADMIN
  a_getallparents: api_route_config.adminPath + "/getallparents",
  a_getalllearners: api_route_config.adminPath + "/getalllearners",
  a_getallexperts: api_route_config.adminPath + "/getallexperts",
  a_getAllActiveExperts: api_route_config.adminPath + "/getAllActiveExperts",
  a_login: api_route_config.adminPath + "/login",
  a_getParentProfileById: api_route_config.adminPath + "/getParentProfileById",
  a_inviteexperts: api_route_config.adminPath + "/invite-expert",
  a_changeCustomerPassword:
    api_route_config.adminPath + "/changeCustomerPassword",
  a_getAllExpertStreamDetails:
    api_route_config.adminPath + "/getAllExpertStreamDetails",
  a_getLearnerStreamDetailsById:
    api_route_config.adminPath + "/getLearnerStreamDetailsById",

  //COURSE
  getAllPublishedCourses: "/util/getAllPublishedCourses",
  a_getCourses: api_route_config.coursePath + "/course/getCourses",
  a_createCourseInfo: api_route_config.coursePath + "/course/createcourseinfo",
  a_updateCourseInfo: api_route_config.coursePath + "/course/updatecourseinfo",
  a_createcoursedetail:
    api_route_config.coursePath + "/course/createcoursedetail",
  a_updatecoursedetail:
    api_route_config.coursePath + "/course/updatecoursedetail",
  a_createpreassessment:
    api_route_config.coursePath + "/course/createpreassessment",
  a_createcontent: api_route_config.coursePath + "/course/createcontent",
  a_updatecontent: api_route_config.coursePath + "/course/updatecontent/",
  a_createstream: api_route_config.coursePath + "/course/createstream",
  a_updatestream: api_route_config.coursePath + "/course/updatestream/",
  a_deleteCourseStream:
    api_route_config.coursePath + "/course/deleteCourseStream/",

  a_GetCourseDetailsByCourseId:
    api_route_config.coursePath + "/course/GetCourseDetailsByCourseId",
  a_GetCourseInfoByCourseId:
    api_route_config.coursePath + "/course/GetCourseInfoByCourseId",
  a_deleteCourseInfoCoverPicture:
    api_route_config.coursePath + "/course/deleteCourseInfoCoverPicture",
  a_GetCoursesDetailsById:
    api_route_config.coursePath + "/course/GetCoursesDetailsById/",
  a_deleteCourseDescriptionDocument:
    api_route_config.coursePath + "/course/deleteCourseDescriptionDocument",

  a_GetCourseContentByCourseId:
    api_route_config.coursePath + "/course/GetCourseContentByCourseId",

  //QUIZTEMPLATE
  quiz_getAllActiveQuizTemplate:
    api_route_config.quizTemplate + "/getAllActive",
  quiz_createQuizTemplate: api_route_config.quizTemplate + "/create",

  //WHITEBOARD TEMPLATES
  createWhiteBoardTemplates: api_route_config.whiteboardTemplates,
  getAllActiveWhiteboardTemplates:
    api_route_config.whiteboardTemplates + "/getAllActive",
  deleteWhiteboardTemplate: api_route_config.whiteboardTemplates,

  //DOCUMENTS
  documents: api_route_config.documentPath,
  upload_document: api_route_config.documentPath + "/upload",

  //EXPERTS
  e_login: api_route_config.expertPath + "/login",
  e_addexpert: api_route_config.expertPath + "/activate-expert",
  e_get_my_courses: api_route_config.expertPath + "/GetMyCourses",
  e_get_todays_schedule:
    api_route_config.expertPath + "/getTodayScheduledClasses",
  e_get_tommorows_schedule:
    api_route_config.expertPath + "/getTomorrowScheduledClasses",
  e_get_thisweek_schedule:
    api_route_config.expertPath + "/getThisWeekScheduledClasses",
  e_get_thismonth_schedule:
    api_route_config.expertPath + "/getThisMonthScheduledClasses",
  e_getAllScheduledClasses:
    api_route_config.expertPath + "/getAllScheduledClasses",
  e_GetPodDetailByCourseId:
    api_route_config.expertPath + "/GetPodDetailByCourseId",
  e_getcurriculum: api_route_config.expertPath + "/GetCurriculumByCourseId/",

  //LEARNERS
  l_login: api_route_config.learnerPath + "/login",
  l_get_my_course: api_route_config.learnerPath + "/GetMyCoursesByLearnerId",
  l_get_todays_schedule:
    api_route_config.learnerPath + "/getTodayScheduledClasses",
  l_get_tommorows_schedule:
    api_route_config.learnerPath + "/getTomorrowScheduledClasses",
  l_get_thisweek_schedule:
    api_route_config.learnerPath + "/getThisWeekScheduledClasses",
  l_getAllScheduledClasses:
    api_route_config.learnerPath + "/getAllScheduledClasses",
  createLearner: api_route_config.learnerPath + "/create-learner",
  // wall post
  getAllWallPost: api_route_config.wallPostPath + "/getAllwallPosts",
  getAllPublishedWallPosts: api_route_config.wallPostPath + "/getAllPublishedWallPosts",
  updatePostState: api_route_config.wallPostPath + "/updatePostState",
  deletePost: api_route_config.wallPostPath + "/:id",


  //PARENTS
  p_get_mykids_by_parentid: api_route_config.parentPath + "/getKidsByParentId",
  p_addkid: api_route_config.parentPath + "/addkid",
  p_addDemoSessionKid: api_route_config.parentPath + "/addDemoSessionKid",
  p_getkidbykidid: api_route_config.parentPath + "/GetKidByKidId",
  parent_registration: api_route_config.parentPath + "/registeration",
  p_login: api_route_config.parentPath + "/login",
  p_deletekid: api_route_config.parentPath + "/softdelete",
  p_updatekid: api_route_config.parentPath + "/updatekid",
  p_get_course_by_parent:
    api_route_config.parentPath + "/GetAllPurchasedCoursesByParentId",
  p_get_allcourse_by_kid: api_route_config.parentPath + "/getAllCoursesByKidId",

  //otp
  verifyOtp: api_route_config.userVerificationOtpsPath + "/verifyOpt",
  generateOtp: api_route_config.userVerificationOtpsPath + "/generate",

  //MEMBERCOURSEASSIGNMENT
  course_assign_to_kid: api_route_config.memberCourseAssignment + "/assign",

  //LOOKUPS
  lookups_CourseCategory: api_route_config.activeLookUpPath + "/CourseCategory",
  lookups_get_task_status: api_route_config.activeLookUpPath + "/TaskStatus",

  //GAMES
  getAllActiveGames: api_route_config.puzzle + "/getAllActive",
  deployGame: api_route_config.puzzle + "/onestepdeploy",
  upload_games: api_route_config.puzzle,
  deleteGame: api_route_config.puzzle + "/delete",

  //DDLISTS
  getActiveCountries: api_route_config.lookups + "/countries/getAllActive",

  //SESSION CHATS
  addChats: api_route_config.sessionChats + "/add",
  getChatsByStreamId:
    api_route_config.sessionChats + "/getChatsByStreamId?courseStreamId=",

  //SESSION QUIZ
  updateQuizBySlugAndLearner:
    api_route_config.sessionQuiz + "/updateQuizBySlugAndLearner?slug=",
  getQuizBySlugAndLearner:
    api_route_config.sessionQuiz + "/getQuizBySlugAndLearner?slug=",
  getQuizBySlug: api_route_config.sessionQuiz + "/getQuizBySlug?slug=",

  //SUBSCRIBE USER
  subscribeUser: "/landingpageonboard",

  //GET CURRENT IP ADDRESS
  getCurrentIpAddress: "https://api.ipify.org/?format=json",
  getCurrentCountryInfoByIpAddress: "https://ipapi.co/json/",

  imageLibraries: api_route_config.imageLibraries,
  tempCustomersRegister:
    api_route_config.tempCustomersPath + "/tempCustomersRegister",
  shareLicensesRegister:
    api_route_config.shareLicensesPath + "/shareLicensesRegister",
  verifyShareLicensesToken:
    api_route_config.shareLicensesPath + "/verifyShareLicensesToken",
  shareLicensesCustomerRegisteration:
    api_route_config.shareLicensesPath + "/shareLicensesCustomerRegisteration",
  getShareLicensesDetailsById:
    api_route_config.shareLicensesPath + "/getShareLicensesDetailsById",
  getAllShareLicensesDetails:
    api_route_config.shareLicensesPath + "/getAllShareLicensesDetails",
  zoomSessionLogsRegister:
    api_route_config.zoomSessionLogsPath + "/zoomSessionLogsRegister",
  getZoomSessionLogsBySlug:
    api_route_config.zoomSessionLogsPath + "/getZoomSessionLogsBySlug",

  //SELF LEARNING
  getCourseDetailByLearnerId: api_route_config.selfLearningPath + "/getCourseDetailByLearnerId?learnerId=",
  getCourseDetailByParentId: api_route_config.selfLearningPath + "/getCourseDetailByParentId?parentId=",

  shareCode: api_route_config.selfLearningPath + "/shareCode",
  joinWithCode: api_route_config.selfLearningPath + "/joinWithCode",
  openBoards: api_route_config.selfLearningPath + "/openBoards",

  getSelfLearningGroupDetailByCode: api_route_config.selfLearningPath + "/getSelfLearningGroupDetailByCode?code=",
  getSelfLearningGroupDetailByCourseIdAndLearnerId: api_route_config.selfLearningPath + "/getSelfLearningGroupDetailByCourseIdAndLearnerId?course_id=",
  getBoardsTemplatesById: api_route_config.selfLearningPath + "/getBoardsTemplatesById?id=",
  getIntractedFriends: api_route_config.selfLearningPath + "/getIntractedFriends?id=",
  removeLearnerFromGroup: api_route_config.selfLearningPath + "/removeLearnerFromGroup",
  getLeaderBoardForGamesByKid: api_route_config.selfLearningPath + "/getLeaderBoardForGamesByKid?learner_id=",


  createSelfLearningLearners: api_route_config.selfLearningPath + "/createSelfLearningLearners",
  //INDIVIDUAL CHATS
  sendPrivateMessage: api_route_config.selfLearningPath + "/sendPrivateMessage",
  getMyPrivateMessageByRecipientId: api_route_config.selfLearningPath + "/getMyPrivateMessageByRecipientId?sender_id=",
  inviteFriendToChat: api_route_config.selfLearningPath + "/chat/invite-friend",

  getMyInvites: api_route_config.selfLearningPath + "/chat/getMyInvites?learner_id=",
  getChatFriends: api_route_config.selfLearningPath + "/chat/getChatFriends?learner_id=",
  acceptChatInvite: api_route_config.selfLearningPath + "/chat/acceptChatInvite",
  getMyInvitesByParent: api_route_config.selfLearningPath + "/chat/getMyInvitesByParent?email=",
  rejectChatInvite: api_route_config.selfLearningPath + "/chat/rejectChatInvite",
  getCurrentUserPrivateMessageById: api_route_config.selfLearningPath + "/getCurrentUserPrivateMessageById?sender_id=",

  getCurrentUserGroupMessageById: api_route_config.selfLearningPath + "/getCurrentUserGroupMessageById?sender_id=",
};

export default api_path;
