import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as Yup from "yup";
import moment from "moment";
import {
  Autocomplete,
  Grid,
  FormControl,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Container,
  TextField,
  Typography,
  Box,
  FormHelperText,
  Link,
} from "@mui/material";


import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  DialogActions,


  Avatar,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import Badge from "@mui/material/Badge";
import assets from "../../config/assets";

import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

import Card from "@mui/material/Card";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Paper from "@mui/material/Paper";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import roleConfig from "../../config/conditional_config"
import ParentService from "../../services/parent-service";
import "./user-profile.css";

//Configs
import conditional_configs from "../../config/conditional_config";
import DeleteIcon from "@mui/icons-material/Delete";
//Utils
import {
  getCurrentUserData,
  getCharacterValidationError,
  convertEpochToDate,
} from "../../utils/util";

//SERVICES
import MemberService from "../../services/member-service";
import LookUpService from "../../services/look-up-service";
import { snackbar_options } from "../../config/snackbar_options";
import error_messages from "../../config/error_messages";
import DocumentService from "../../services/document-service";
import ParentAPIService from "../../services/parent-service";

//FILE UPLOAD
import DropFileInput from "../../components/drop-file-input/DropFileInput";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

import PaymentService from "../../services/payment-service";
import url_configs from "../../config/url";
import OtpPopup from "../../components/verify-otp-popup/verify-otp-popup";
import SelfLearningService from "../../services/self-learning-service";

import { IconButton, InputAdornment } from '@mui/material';

import Iconify from '../../components/Iconify'; // Assuming Iconify is used for icons

import { useLocation } from 'react-router-dom';



//Dialog End

//TAB
const infoTabList = [
  {
    id: 1,
    name: "Personal Information",
  },
  {
    id: 2,
    name: "Documents",
  },
  {
    id: 3,
    name: "Change Password",
  },
  {
    id: 4,
    name: "Payment Account",
  },
  {
    id: 5,
    name: "My Kids",
  },
  {
    id: 6,
    name: "My Invites",
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserProfile() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [infoTabs, setInfoTabs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("USA");
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [userDetail, setUserDeail] = useState();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showKidPassword, setShowKidPassword] = useState(false);
  const [showAddKidPassword, setShowAddKidPassword] = useState(false);

  const [image, setImage] = useState(null);
  const [imageDocumentPath, setImageDocumentPath] = useState(null);

  const [userFileUpload, setUserFileUpload] = React.useState("");

  //KIDS
  const [myKidsList, setMyKidsList] = useState([]);
  const ParentData = JSON.parse(localStorage.getItem("user"));
  const [no_of_kids, setNo_of_kids] = useState(0);
  const [myChatInvites, setMyChatInvites] = useState([]);

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "#f8f9fa",
      padding: "3px",
      backgroundColor: '#0d6efd'
    },
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 1024 * 1024) {
        // File size exceeds 1MB, set error message
        enqueueSnackbar(
          "File size exceeds 1MB. Please upload a smaller image.",
          snackbar_options.error
        );
        return;
      } else {
        setSelectedKidPath(null);

        DocumentService.upload_document(file)
          .then((response) => {
            enqueueSnackbar(
              "Profile image uploaded successfully",
              snackbar_options.success
            );

            if (response && response.data && response.data.id) {
              console.log('response test', response)
              DocumentService.getDocumentById(response.data.id)
                .then((response) => {

                  console.log('response full', response.data)
                  setDocumentInfoInLocalStorage(
                    response.data.path,
                    response.data.id,
                    response.data.filename
                  );
                  // window.location.reload();
                })
                .catch((e) => {
                  console.log(e);
                });

              MemberService.updateProfileImage(
                getCurrentUserData().id,
                response.data.id
              )
                .then((response) => {
                  console.log("Profile image update successfully");
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setImageDocumentPath("");
    }
  };

  const handleRemoveImage = () => {
    console.log("remove")
    if (getCurrentUserData() && getCurrentUserData().profile_documentid) {
      MemberService.deleteDocumentId(
        getCurrentUserData().id,
        getCurrentUserData().profile_documentid
      )
        .then(() => {
          const userData = JSON.parse(localStorage.getItem("user"));

          // Check if userData exists
          if (userData) {
            // Remove document_path and profile_documentid properties
            userData.document_path = null;
            userData.profile_documentid = null;
            userData.document_file_name = null;
            // Update userData in localStorage
            localStorage.setItem("user", JSON.stringify(userData));
          }
          enqueueSnackbar(
            "profile image deleted successfully",
            snackbar_options.success
          );
          window.location.reload();
          setImageDocumentPath(null);
        })
        .catch((error) => {
          console.error("Failed to delete document:", error);
          // Handle error appropriately, e.g., display an error message to the user
        });
    }

    setImage(null);
  };

  const setDocumentInfoInLocalStorage = (
    documentPath,
    profileDocumentId,
    documentFileName
  ) => {
    // Get userData from localStorage
    let userData = JSON.parse(localStorage.getItem("user"));

    // Check if userData exists, if not, initialize it as an empty object
    if (!userData) {
      userData = {};
    }
    // Set document_path and profile_documentid fields to the desired values
    userData.document_path = documentPath;
    userData.profile_documentid = profileDocumentId;
    userData.document_file_name = documentFileName;

    // Update userData in localStorage
    localStorage.setItem("user", JSON.stringify(userData));
  };

  // const [tabValue, setTabValue] = React.useState(0);

  const getCountries = () => {
    LookUpService.getAllActiveCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMembersById = () => {
    MemberService.getMembersById(getCurrentUserData().id)
      .then((response) => {
        setUserDeail(response.data);
        console.log(response.data);
        if (response && response.data) {
          //PERSONAL INFORMATION
          setValue("firstname", response.data.firstname);
          setValue("lastname", response.data.lastname);
          setValue("email", response.data.email);
          setValue("country", response.data.country);
          setValue("timezone", response.data.timezone);
        }
        getCountries();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  //#region PERSONAL INFORMATION
  const personalInfoFormSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    country: Yup.string().required("Country is required"),
    timezone: Yup.string().required("Timezone is required"),
  });

  const changePasswordSchema = Yup.object().shape({
    oldpassword: Yup.string()
      .required("Current password is required")
      .min(6, "Current password must be at least 6 characters")
      .max(40, "Current password must not exceed 40 characters"),
    newpassword: Yup.string()
      .required("New password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
    repeatNewPassword: Yup.string()
      .required("Repeat new password is required")
      .oneOf(
        [Yup.ref("newpassword"), null],
        "Repeat new password does not match"
      ),
  });

  const kidsSchema = Yup.object().shape({
    // kidname: Yup.string().required("kid name is required"),
    kidfirstname: Yup.string().required("First name is required"),
    kidlastname: Yup.string().required("Last name is required"),
  });

  const kidsPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const addKidSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    username: Yup.string().required("User name is required").matches(/^[a-zA-Z0-9_]{4,15}$/, "Username must be 4-15 characters and alphanumeric"), // Corrected validation,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  // SUBMIT PERSONAL INFORMATION
  const onPersonalInfoSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
    console.log(data);

    MemberService.updateMembers(data)
      .then((response) => {
        enqueueSnackbar(
          "Profile updated successfully",
          snackbar_options.success
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(personalInfoFormSchema),
  });

  const {
    register: changePwdFormReg,
    control: changePwdFormCtrl,
    handleSubmit: changePwdFormSubmit,
    reset: changePwdFormReset,
    formState: { errors: changePwdFormErrors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const {
    register: kidsFormReg,
    control: kidsFormCtrl,
    handleSubmit: kidsFormSubmit,
    reset: KidUpdateFormReset,
    setValue: kidUpdateSetValue,
    formState: { errors: kidsFormErrors },
  } = useForm({
    resolver: yupResolver(kidsSchema),
  });

  const {
    register: kidsFormPasswordReg,
    control: kidsFormPasswordCtrl,
    handleSubmit: kidsFormPasswordSubmit,
    reset: KidFormReset,
    formState: { errors: kidsFormPasswordErrors },
  } = useForm({
    resolver: yupResolver(kidsPasswordSchema),
  });

  const {
    register: addKidFormReg,
    control: addKidFormCtrl,
    handleSubmit: addKidFormSubmit,
    reset: addKidFormReset,
    formState: { errors: addKidFormErrors },
  } = useForm({
    resolver: yupResolver(addKidSchema),
  });

  const onKidsPasswordSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    try {
      // Update the kid's password using the MemberService
      await MemberService.changeKidPassword(selectedKidId, data.password);

      // If the password update is successful, show a success message
      enqueueSnackbar(
        "Kid's password updated successfully",
        snackbar_options.success
      );
      KidFormReset();
      // Close the password change dialog
      myKidHandleCloseDialog();
      // setChangeKidPasswordOpen(false);
    } catch (error) {
      // If an error occurs during password update, show an error message
      enqueueSnackbar(
        "Failed to update kid's password. Please try again later.",
        snackbar_options.error
      );

      // Optionally, log the error for debugging purposes
      console.error("Error updating kid's password:", error);
    }
  };

  //#region CHANGE PASSWORD
  const onChangePasswordSubmit = (data) => {
    //console.log(JSON.stringify(data));
    console.log(data);
    if (data) {
      const model = {
        oldpassword: data.oldpassword,
        newpassword: data.newpassword,
      };

      MemberService.changeNewPassword(model)
        .then((response) => {
          if (response && response.success == "true") {
            enqueueSnackbar(
              error_messages.changePasswordSuccess,
              snackbar_options.success
            );
            changePwdFormReset();
          } else {
            enqueueSnackbar(response.data, snackbar_options.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //#endregion

  //File upload

  const handleSubmitDocument = (e) => {
    if (userFileUpload) {
      enqueueSnackbar(
        "Document uploaded successfully",
        snackbar_options.success
      );
      setUserFileUpload(null);
    }
  };

  const handleUserFileUpload = (files) => {
    console.log(files);
    if (files && files.length > 0) {
      var file = files[0];
      DocumentService.upload_document(file)
        .then((response) => {
          console.log(response);
          setUserFileUpload(response.data.id);
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(files);
    }
  };

  const getMySubscriptionDetail = () => {
    PaymentService.getMySubscriptionDetail()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data) {
          setSubscriptionDetail(response.data);
          var no_of_kids = 0;
          for (let i = 0; i < url_configs.plans.length; i++) {
            const checkProducts = response.data.filter(e => e.product.id == url_configs.plans[i].id && (e.subscription.status == "active" || e.subscription.status == "trialing"));
            if (checkProducts && checkProducts.length > 0) {
              no_of_kids = no_of_kids + (url_configs.plans[i].no_of_kids * checkProducts.length)
            }
          }
          setNo_of_kids(no_of_kids)
          console.log("no_of_kids", no_of_kids);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getBillingPlanText = (text) => {
    if (text) {
      if (text == "month") {
        return "Recurring Monthly"
      }
    } else {
      return "";
    }
  }

  const cancelSubscription = (e, obj) => {
    if (obj) {
      Swal.fire({
        title: "Are you sure do you want to cancel the subscription?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(obj.subscription.id);
          PaymentService.cancelSubscription(obj.subscription.id)
            .then((response) => {
              console.log("cancelSubscription", response);
              window.location.reload();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }
  }


  const location = useLocation();


  // Parse tabIndex from query parameters
  const queryParams = new URLSearchParams(location.search);
  const tabIndex = parseInt(queryParams.get('tabIndex'), 10) || 0;

  const [tabValue, setTabValue] = useState(tabIndex); // Set initial tabValue from URL

  useEffect(() => {

    // Synchronize tabValue with URL when tabIndex changes
    setTabValue(tabIndex);

    if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.expert
    ) {
      let tempTabs = [...infoTabList];
      tempTabs = tempTabs.filter((e) => e.id != 5 && e.id != 6);
      setInfoTabs(tempTabs);
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      let tempTabs = [...infoTabList];
      tempTabs = tempTabs.filter((e) => e.id != 2 && e.id != 4);
      setInfoTabs(tempTabs);
      ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id).then(
        (response) => {
          setMyKidsList(response.data);
          console.log("kid list", response.data)
        }
      );
      getMySubscriptionDetail();
      getMyInvitesByParent();
    } else if (
      getCurrentUserData() &&
      getCurrentUserData().role == conditional_configs.role.learner
    ) {
      let tempTabs = [...infoTabList];
      tempTabs = tempTabs.filter((e) => e.id != 2 && e.id != 4 && e.id != 5 && e.id != 6);
      setInfoTabs(tempTabs);
    } else {
      return;
    }
    getMembersById();

    setTimeout(() => {

      const userData = JSON.parse(localStorage.getItem("user"));

      // Check if userData exists and contains document_path
      if (userData && userData.document_path) {
        // Set imageDocumentPath state with document_path value
        setImageDocumentPath(userData.document_path);
      }
    }, 2000);
  }, [tabIndex]);



  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`${location.pathname}?tabIndex=${newValue}`);
  };


  const onKidsSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
    console.log("data", data, selectedKidId);

    MemberService.updateKidDetails(
      selectedKidId,
      data.kidfirstname,
      data.kidlastname
    )
      .then((updatedKid) => {
        console.log("Kid updated successfully:", updatedKid);
        enqueueSnackbar("Kid updated successfully", snackbar_options.success);
        KidUpdateFormReset();
        setKidSelected(false)

        ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id).then(
          (response) => {
            setMyKidsList(response.data);
            myKidHandleCloseDialog();
          }
        );
      })
      .catch((error) => {
        console.error("Error updating kid:", error);
        enqueueSnackbar("Failed to update kid", snackbar_options.error);
      });
  };
  //   kid update  image upload

  const [kidImage, setKidImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileDocumentId, setProfileDocumentId] = React.useState("");

  const handleImageUploadForKid = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // File size exceeds 1MB, set error message
        setErrorMessage(
          "File size exceeds 1MB. Please upload a smaller image."
        );
        return;
      } else {
        setSelectedKidPath(null);
        DocumentService.upload_document(file)
          .then((response) => {
            enqueueSnackbar(
              "Kid's profile image uploaded successfully",
              snackbar_options.success
            );

            if (response && response.data && response.data.id) {
              setProfileDocumentId(response.data.id);
              MemberService.updateProfileImage(selectedKidId, response.data.id)
                .then((response) => {
                  console.log("Kid's profile image update successfully");
                  ParentAPIService.GetMyLidsByParentsId(
                    getCurrentUserData().id
                  ).then((response) => {
                    setMyKidsList(response.data);
                    myKidHandleCloseDialog();
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setKidImage(e.target.result);
        setErrorMessage(""); // Clear error message if image upload is successful
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImageForKid = () => {
    if (selectedKidProfileDocumentId) {
      console.log("selectedKidProfileDocumentId", selectedKidProfileDocumentId);
      MemberService.deleteDocumentId(
        selectedKidId,
        selectedKidProfileDocumentId
      )
        .then(() => {
          enqueueSnackbar(
            "Kid's profile image deleted successfully",
            snackbar_options.success
          );
          ParentAPIService.GetMyLidsByParentsId(getCurrentUserData().id).then(
            (response) => {
              setMyKidsList(response.data);
              setSelectedKidPath(null);
              myKidHandleCloseDialog();
            }
          );
        })
        .catch((error) => {
          console.error("Failed to delete document:", error);
          // Handle error appropriately, e.g., display an error message to the user
        });
    } else {
      setKidImage(null);
      console.log("No document to delete");
    }

    setErrorMessage("");
  };

  const changeKidPasswordPopupOpen = () => {
    setChangeKidPasswordOpen(true);
  };
  const changeKidPasswordPopupClose = () => {
    setChangeKidPasswordOpen(false);
  };

  const [changeKidPasswordOpen, setChangeKidPasswordOpen] = useState(false);
  const [addKidOpen, setAddKidOpen] = useState(false);

  const [kidSelected, setKidSelected] = useState(false);
  const [selectedKidName, setSelectedKidName] = useState(false);
  const [selectedKidId, setSelectedKidId] = useState(false);
  const [selectedKidPath, setSelectedKidPath] = useState(null);
  const [selectedKidProfileDocumentId, setSelectedKidProfileDocumentId] =
    useState(null);


  // State initialization for default selection
  // useEffect(() => {
  //   if (myKidsList?.length > 0) {
  //     kidUpdateSetValue("kidname", myKidsList[0].id);
  //   }
  //   if (myKidsList && myKidsList.length > 0) {
  //     const defaultKid = myKidsList[0]; // Get the first kid as default
  //     const kidName = defaultKid.username
  //       ? `${defaultKid.firstname} (${defaultKid.username})`
  //       : defaultKid.firstname;

  //     setSelectedKidName(kidName);
  //     setKidSelected(true);
  //     setSelectedKidPath(defaultKid.path); // Set path for the default kid
  //     setSelectedKidId(defaultKid.id);
  //     setSelectedKidProfileDocumentId(defaultKid.profile_documentid);

  //     // Populate the default kid's name values
  //     kidUpdateSetValue("kidfirstname", defaultKid.firstname);
  //     kidUpdateSetValue("kidlastname", defaultKid.lastname);
  //   }
  // }, [myKidsList, kidUpdateSetValue]); // Run when `myKidsList` changes  

  const handleKidnameChange = (event) => {
    const selectedKidId = event.target.value;
    const selectedKid = myKidsList.find((kid) => kid.id === selectedKidId);

    if (selectedKid) {
      console.log("selectedKid", selectedKid);
      const kidName = selectedKid.username
        ? `${selectedKid.firstname} (${selectedKid.username})`
        : selectedKid.firstname;
      setSelectedKidName(kidName);
      console.log("Selected kid name:", kidName);
      setKidSelected(true);
      setSelectedKidPath(selectedKid.path); // Update state with the path of the selected kid
      setSelectedKidId(selectedKid.id);
      setSelectedKidProfileDocumentId(selectedKid.profile_documentid);
      console.log("Selected kid path:", selectedKid.path);
      setKidImage(null);

      const kidFirstName = selectedKid.firstname;
      const kidLastName = selectedKid.lastname; // Assuming `lastname` is a property of the kid object
      kidUpdateSetValue("kidfirstname", kidFirstName);
      kidUpdateSetValue("kidlastname", kidLastName);
    } else {
      console.error("Selected kid not found");
      setSelectedKidName(null); // Reset the selected kid name
      setKidSelected(false);
      setSelectedKidPath(null); // Reset the selected kid path
    }
  };

  //   kid update  image upload

  //#region FORMS
  const personaleInfoForm = () => {
    return (
      <form onSubmit={handleSubmit(onPersonalInfoSubmit)}>
        <Grid container spacing={1}>
          {/*    Personal Information */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" margin="dense">
              Personal Information
            </Typography>
          </Grid>
          {/* first_name */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstname"
              rules={{ required: "First name is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="firstname"
                  label="First name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.firstname}
                  helperText={errors.firstname?.message}
                />
              )}
            />
          </Grid>
          {/* LAST NAME TEXT FIELD */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastname"
              rules={{ required: "Last name is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="lastname"
                  label="Last name"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.lastname}
                  helperText={errors.lastname?.message}
                />
              )}
            />
          </Grid>
          {/* EMAIL FIELD */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="email"
              rules={{ required: "Email is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  {...register("email")}
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                  disabled
                />
              )}
            />
            {getCurrentUserData().role === roleConfig.role.parent && getCurrentUserData().status_id === 3 ? (
              <div className="d-flex justify-content-end">
                <a href="#" onClick={handleOpenOtpPopup}>
                  Verify
                </a></div>
            ) : ""}
          </Grid>



          {/* COUNTRY FIELD */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="country"
              rules={{ required: "Country is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl fullWidth required margin="dense">
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    defaultValue={defaultCountry}
                    name="country"
                    label="Country"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...register("country")}
                    error={errors.country ? true : false}
                    helperText={errors.country ? errors.country.message : ""}
                  >
                    {/* {countries?.map((obj, index) => {
                      return (
                        <MenuItem
                          key={`user-profile-countries-key-` + index}
                          value={obj.code}
                        >
                          {obj.name}
                        </MenuItem>
                      );
                    })} */}

                    <MenuItem value="India">India</MenuItem>
                    <MenuItem value="USA">
                      United States of America
                    </MenuItem>
                  </Select>
                  {errors.country ? (
                    <FormHelperText className="requiredField">
                      {errors.country.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
            />
          </Grid>
          {/* TIMEZONE FIELD */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="timezone"
              rules={{ required: "Country is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl fullWidth required margin="dense">
                  <InputLabel id="timezone-label">Timezone</InputLabel>
                  <Select
                    labelId="timezone-label"
                    id="timezone"
                    defaultValue=""
                    name="timeone"
                    label="Timezone"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...register("timezone")}
                    error={errors.timezone ? true : false}
                  >
                    <MenuItem value="IST">Indian Standard Time(IST)</MenuItem>
                    <MenuItem value="EDT">Eastern Daylight Time(EDT)</MenuItem>
                    <MenuItem value="CDT">Central Daylight Time(CDT)</MenuItem>
                    <MenuItem value="MDT">Mountain Daylight Time(MDT)</MenuItem>
                    <MenuItem value="MST">Mountain Standard Time(MST)</MenuItem>
                    <MenuItem value="PDT">Pacific Daylight Time(PDT)</MenuItem>
                    <MenuItem value="ADT">Alaska Daylight Time(ADT)</MenuItem>
                    <MenuItem value="HADT">
                      Hawaii Aleutian Daylight Time(HADT)
                    </MenuItem>
                    <MenuItem value="HST">Hawaii Standard Time(HST)</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Button
          className="mb-3"
          id="submit-button"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </form>
    );
  };

  const documentsForm = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          {/* <Typography variant="h6" margin="dense">
                                            Upload document
                                        </Typography> */}

          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="user_upload_file"
              rules={{ required: false }}
              render={({ field }) => (
                <div className="box">
                  <span>Upload File</span>
                  <DropFileInput
                    {...field}
                    onFileChange={(files) => handleUserFileUpload(files)}
                  />
                </div>
              )}
            />
            <Button
              className="mb-3"
              id="submit-button"
              variant="contained"
              type="button"
              onClick={(e) => handleSubmitDocument(e)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const changePasswordForm = () => {
    return (
      <form onSubmit={changePwdFormSubmit(onChangePasswordSubmit)}>
        <Grid container spacing={1}>
          {/* Price Information */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" margin="dense">
              Change Password
            </Typography>
          </Grid>
          {/* currentPassword */}

          <Grid item xs={12} sm={6}>
            <Controller
              control={changePwdFormCtrl}
              name="oldpassword"
              rules={{ required: "Old password is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="oldpassword"
                  label="Current password"
                  type={showCurrentPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={changePwdFormErrors.oldpassword}
                  {...changePwdFormReg("oldpassword")}
                  helperText={changePwdFormErrors.oldpassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                          <Iconify icon={showCurrentPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* <TextField
                        required
                        id="currentPassword"
                        name="currentPassword"
                        label="Current Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        {...register('currentPassword')}
                        error={errors.currentPassword ? true : false}
                    /> */}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          {/* newPassword */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={changePwdFormCtrl}
              name="newpassword"
              rules={{ required: "New password is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="newpassword"
                  label="New password"
                  type={showNewPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onCopy={(e) => e.preventDefault()} // Disable copy
                  onPaste={(e) => e.preventDefault()} // Disable paste
                  error={changePwdFormErrors.newpassword}
                  {...changePwdFormReg("newpassword")}
                  helperText={changePwdFormErrors.newpassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                          <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* <TextField
                        required
                        id="newPassword"
                        name="newPassword"
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        {...register('newPassword')}
                        error={errors.newPassword ? true : false}
                    /> */}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          {/* repeatNewPassword */}
          <Grid item xs={12} sm={6}>
            <Controller
              control={changePwdFormCtrl}
              name="repeatNewPassword"
              rules={{ required: "Repeat new password is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="repeatNewPassword"
                  label="Confirm password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onCopy={(e) => e.preventDefault()} // Disable copy
                  onPaste={(e) => e.preventDefault()} // Disable paste
                  error={changePwdFormErrors.repeatNewPassword}
                  {...changePwdFormReg("repeatNewPassword")}
                  helperText={changePwdFormErrors.repeatNewPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* <TextField
                        required
                        id="repeatNewPassword"
                        name="repeatNewPassword"
                        label="Repeat New Password"
                        type="password"
                        fullWidth
                        margin="dense"
                        {...register('repeatNewPassword')}
                        error={errors.repeatNewPassword ? true : false}
                    /> */}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
        <Button
          className="mb-3"
          id="submit-button"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </form>
    );
  };

  const paymentForm = () => {
    return (
      <Grid container spacing={1}>
        {/* Price Information */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" margin="dense">
            Payment Account
          </Typography>
          <Paper elevation={0} className="Payment-Account-div-up mt-3">
            <div className="d-flex align-items-center">
              <p className="stripe-text-up-1 m-0">stripe</p>
              <div className="ms-3">
                <p className="stripe-text-up-2 m-0">stripe</p>
                <p className="stripe-text-up-3 m-0">
                  Connect your stripe account to start accepting payments
                </p>
              </div>
              <div className="connect-btn-up ms-auto">
                <span>Connect</span>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const getMyInvitesByParent = () => {
    SelfLearningService.getMyInvitesByParent()
      .then((response) => {
        console.log("getMyInvitesByParent", response);
        if (response && response.data) {
          setMyChatInvites(response.data)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [myKidopenDialog, setMyKidOpenDialog] = useState(false);
  const [myKidDialogType, setMyKidDialogType] = useState(""); // 'edit' or 'change-password'
  const [selectedUser, setSelectedUser] = useState(null);

  const myKidHandleOpenDialog = (type, user) => {
    setMyKidDialogType(type);
    setSelectedUser(user);
    setMyKidOpenDialog(true);

    if (type === "edit-profile-picture") {
      console.log("Updated profile picture");
      const selectedKidId = user.id;
      const selectedKid = myKidsList.find((kid) => kid.id === selectedKidId);

      if (selectedKid) {
        setSelectedKidPath(selectedKid.path); // Update state with the path of the selected kid
        setSelectedKidId(selectedKid.id);
        setSelectedKidProfileDocumentId(selectedKid.profile_documentid);
        console.log("Selected kid path:", selectedKid.path);
        setKidImage(null);
      } else {
        console.error("Selected kid not found");
        setSelectedKidName(null); // Reset the selected kid name
        setKidSelected(false);
        setSelectedKidPath(null); // Reset the selected kid path
      }
    } else if (type === "change-password") {
      console.log("Password changed for user");

      const selectedKidId = user.id;
      const selectedKid = myKidsList.find((kid) => kid.id === selectedKidId);
      if (selectedKid) {
        setSelectedKidId(selectedKid.id);
      } else {
        console.error("Selected kid not found");
      }
    } else if (type === "edit-profile") {
      console.log("Profile updated for");

      const selectedKidId = user.id;
      const selectedKid = myKidsList.find((kid) => kid.id === selectedKidId);

      if (selectedKid) {

        const kidName = selectedKid.username
          ? `${selectedKid.firstname} (${selectedKid.username})`
          : selectedKid.firstname;
        setSelectedKidName(kidName);
        console.log("Selected kid name:", kidName);
        setSelectedKidId(selectedKid.id);

        const kidFirstName = selectedKid.firstname;
        const kidLastName = selectedKid.lastname; // Assuming `lastname` is a property of the kid object
        kidUpdateSetValue("kidfirstname", kidFirstName);
        kidUpdateSetValue("kidlastname", kidLastName);
      } else {
        console.error("Selected kid not found");
        setSelectedKidName(null); // Reset the selected kid name
        setKidSelected(false);
        setSelectedKidPath(null); // Reset the selected kid path
      }
    }

  };

  const myKidHandleCloseDialog = () => {
    setMyKidOpenDialog(false);
    setSelectedUser(null);
    setMyKidDialogType("");
  };



  const Kids = () => {
    return (
      <div>
        <div className="d-flex">
          <Typography className="kid_count_bage_div" variant="h6" margin="dense">
            Kid Information  {myKidsList?.length ? (
              <Badge
                className="kid_count_bage"
                badgeContent={myKidsList.length}
                sx={badgeStyle}
              />
            ) : (
              ""
            )}

          </Typography>
          {myKidsList.length < no_of_kids ? <Button className="ms-auto" onClick={(e) => changeAddKidPopupOpen(e)}>Add Kid</Button>
            :
            <Button className="ms-auto" disabled title="Please purchase subscription to add kid">Add Kid</Button>}
        </div>


        {/* <div className="row">
          <div className="col-sm-6">


            <form onSubmit={kidsFormSubmit(onKidsSubmit)}>
              <Grid container spacing={1}>
                
                <Grid item xs={12} sm={12}>

                  <div className="d-flex">
                    <Typography className="kid_count_bage_div" variant="h6" margin="dense">
                      Kid Information  {myKidsList?.length ? (
                        <Badge
                          className="kid_count_bage"
                          badgeContent={myKidsList.length}
                          sx={badgeStyle}
                        />
                      ) : (
                        ""
                      )}

                    </Typography>
                    {myKidsList.length < no_of_kids ? <Button className="ms-auto" onClick={(e) => changeAddKidPopupOpen(e)}>Add Kid</Button>
                      :
                      <Button className="ms-auto" disabled title="Please purchase subscription to add kid">Add Kid</Button>}
                  </div>



                  <Typography variant="h9" margin="dense">
                    {myKidsList.length < no_of_kids ? ""
                      :
                      <span className="no_of_kids_exceed_text">No. of kids exceeded!</span>}
                  </Typography>
                </Grid>
              
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={kidsFormCtrl}
                    name="kidname"
                    rules={{ required: "Kid name is required" }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormControl fullWidth margin="dense">
                        <InputLabel id="kids-name">My Kids</InputLabel>
                        <Select
                          labelId="kids-name"
                          id="kidname"

                          name="kidname"
                          label="My Kids"
                          value={value} // Ensure default selection
                          onChange={(event) => {
                            onChange(event);
                            handleKidnameChange(event);
                          }}
                          onBlur={onBlur}
                          error={kidsFormErrors.kidname ? true : false}
                        >
                          {myKidsList?.map((obj, index) => {
                            return (
                              <MenuItem
                                key={`user-profile-kid-key-` + index}
                                value={obj.id}
                              >
                                {obj.firstname}({obj?.username})
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {kidsFormErrors.kidname ? (
                          <FormHelperText className="requiredField">
                            {kidsFormErrors.kidname.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                {kidSelected && (
                  <Grid item xs={12} sm={12}>
                    <Link
                      variant="subtitle2"
                      className="mt-1"
                      underline="hover"
                      onClick={changeKidPasswordPopupOpen}
                      smooth="true"
                      style={{ cursor: "pointer" }}
                    >
                      Change Kid Password
                    </Link>
                  </Grid>
                )}

               
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={kidsFormCtrl}
                    name="kidfirstname"
                    rules={{ required: "First name is required" }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        id="kidfirstname"
                        label="First name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={kidsFormErrors.kidfirstname}
                        {...kidsFormReg("kidfirstname")}
                        helperText={kidsFormErrors.kidfirstname?.message}
                      />
                    )}
                  />
                </Grid>

                
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={kidsFormCtrl}
                    name="kidlastname"
                    rules={{ required: "Last name is required" }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        id="kidlastname"
                        label="Last name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={kidsFormErrors.kidlastname}
                        {...kidsFormReg("kidlastname")}
                        helperText={kidsFormErrors.kidlastname?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                className="mb-3"
                id="submit-button"
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </form>
          </div>
          <div className="col-sm-6">
            {kidSelected && !kidImage && (
              <div className="upload_section">
                <label htmlFor="image-upload-kid" className="profile-image">
                  {selectedKidPath ? (
                    <img src={selectedKidPath} alt="Profile" />
                  ) : (
                    <PhotoCameraIcon sx={{ color: "white" }} />
                  )}
                </label>
                <div>
                  {selectedKidPath ? (
                    <p
                      className="remove-pic-up-kid m-0"
                      onClick={handleRemoveImageForKid}
                    >
                      <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                    </p>
                  ) : (
                    <p className="add-pic-up-kid m-0">Upload Image</p>
                  )}
                </div>
                <input
                  type="file"
                  id="image-upload-kid"
                  accept="image/*"
                  onChange={handleImageUploadForKid}
                  style={{ display: "none" }}
                />
                {errorMessage && (
                  <p className="error-meg-for-img-size">{errorMessage}</p>
                )}
              </div>
            )}

            {kidImage && kidSelected && (
              <div className="upload_section">
                <label htmlFor="image-upload-kid" className="profile-image">
                  {kidImage ? (
                    <img src={kidImage} alt="Profile" />
                  ) : (
                    <PhotoCameraIcon sx={{ color: "white" }} />
                  )}
                </label>
                <div>
                  {kidImage ? (
                    <p
                      className="remove-pic-up-kid m-0"
                      onClick={handleRemoveImageForKid}
                    >
                      <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                    </p>
                  ) : (
                    <p className="add-pic-up-kid m-0">Upload Image</p>
                  )}
                </div>
                <input
                  type="file"
                  id="image-upload-kid"
                  accept="image/*"
                  onChange={handleImageUploadForKid}
                  style={{ display: "none" }}
                />
                {errorMessage && (
                  <p className="error-meg-for-img-size">{errorMessage}</p>
                )}
              </div>
            )}
          </div>
        </div> */}
        <div className="row">
          <TableContainer >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>User Name</strong></TableCell>
                  <TableCell><strong>First Name</strong></TableCell>
                  <TableCell><strong>Last Name</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myKidsList.length === 0 ? (
                  // Display a message if no records are found
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px", fontSize: "18px", fontWeight: "bold" }}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  myKidsList.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          {user.path ? (
                            <Avatar
                              src={user.path}
                              alt={user.username}
                              style={{ width: "50px", height: "50px", marginRight: "2px" }}
                            />
                          ) : (
                            <Avatar
                              style={{ width: "50px", height: "50px", marginRight: "2px" }}
                            />
                          )}
                          <Tooltip title="Edit Profile Picture">
                            <EditIcon
                              color="secondary"
                              className="profile-picture-edit-icon-up"
                              onClick={() => myKidHandleOpenDialog("edit-profile-picture", user)}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                      {/* Make username bold */}
                      <TableCell>
                        <strong>{user.username}</strong>
                      </TableCell>
                      <TableCell>{user.firstname}</TableCell>
                      <TableCell>{user.lastname}</TableCell>
                      <TableCell>
                        <Tooltip title="Change Password">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<LockIcon />}
                            onClick={() => myKidHandleOpenDialog("change-password", user)}
                          >
                            Change Password
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit Profile">
                          <EditIcon color="secondary" className="cursor-pointer"
                            onClick={() => myKidHandleOpenDialog("edit-profile", user)} />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>




          {/* Shared Dialog Box */}
          <Dialog fullWidth open={myKidopenDialog} onClose={myKidHandleCloseDialog}>
            <DialogTitle>
              {myKidDialogType === "edit-profile-picture"
                ? `Edit Profile Picture`
                : myKidDialogType === "change-password"
                  ? `Change Password`
                  : `Edit Profile`}

              <span className="close_icon_cgv4" onClick={myKidHandleCloseDialog}>
                <img
                  className="" width={'30px'}
                  src={assets.course_v4.close_icon}
                ></img>
              </span>
            </DialogTitle>
            <DialogContent>
              {myKidDialogType === "edit-profile-picture" && (
                <div>
                  <strong><p>Username: {selectedUser?.username}</p></strong>
                  <div className="">
                    {!kidImage && (
                      <div className="upload_section">
                        <label htmlFor="image-upload-kid" className="profile-image">
                          {selectedKidPath ? (
                            <img src={selectedKidPath} alt="Profile" />
                          ) : (
                            <PhotoCameraIcon sx={{ color: "white" }} />
                          )}
                        </label>
                        <div>
                          {selectedKidPath ? (
                            <p
                              className="remove-pic-up-kid m-0"
                              onClick={handleRemoveImageForKid}
                            >
                              <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                            </p>
                          ) : (
                            <p className="add-pic-up-kid m-0">Upload Image</p>
                          )}
                        </div>
                        <input
                          type="file"
                          id="image-upload-kid"
                          accept="image/*"
                          onChange={handleImageUploadForKid}
                          style={{ display: "none" }}
                        />
                        {errorMessage && (
                          <p className="error-meg-for-img-size">{errorMessage}</p>
                        )}
                      </div>
                    )}

                    {kidImage && (
                      <div className="upload_section">
                        <label htmlFor="image-upload-kid" className="profile-image">
                          {kidImage ? (
                            <img src={kidImage} alt="Profile" />
                          ) : (
                            <PhotoCameraIcon sx={{ color: "white" }} />
                          )}
                        </label>
                        <div>
                          {kidImage ? (
                            <p
                              className="remove-pic-up-kid m-0"
                              onClick={handleRemoveImageForKid}
                            >
                              <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                            </p>
                          ) : (
                            <p className="add-pic-up-kid m-0">Upload Image</p>
                          )}
                        </div>
                        <input
                          type="file"
                          id="image-upload-kid"
                          accept="image/*"
                          onChange={handleImageUploadForKid}
                          style={{ display: "none" }}
                        />
                        {errorMessage && (
                          <p className="error-meg-for-img-size">{errorMessage}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {myKidDialogType === "change-password" && (
                <div>
                  <strong><p>Username: {selectedUser?.username}</p></strong>
                  <form onSubmit={kidsFormPasswordSubmit(onKidsPasswordSubmit)}>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        control={kidsFormPasswordCtrl}
                        name="password"
                        rules={{ required: "password is required" }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            id="password"
                            label="Password"
                            type={showKidPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            onCopy={(e) => e.preventDefault()} // Disable copy
                            onPaste={(e) => e.preventDefault()} // Disable paste
                            error={kidsFormPasswordErrors.password}
                            {...kidsFormPasswordReg("password")}
                            helperText={kidsFormPasswordErrors.password?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowKidPassword(!showKidPassword)} edge="end">
                                    <Iconify icon={showKidPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Button
                      className="mb-3"
                      id="submit-button"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </Button>
                  </form>
                </div>
              )}
              {myKidDialogType === "edit-profile" && (
                <div>
                  <strong><p>Username: {selectedUser?.username}</p></strong>
                  <form onSubmit={kidsFormSubmit(onKidsSubmit)}>
                    <Grid container spacing={1}>
                      {/* first_name */}
                      <Grid item xs={12} sm={12}>
                        <Controller
                          control={kidsFormCtrl}
                          name="kidfirstname"
                          rules={{ required: "First name is required" }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextField
                              id="kidfirstname"
                              label="First name"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={kidsFormErrors.kidfirstname}
                              {...kidsFormReg("kidfirstname")}
                              helperText={kidsFormErrors.kidfirstname?.message}
                            />
                          )}
                        />
                      </Grid>

                      {/* LAST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12}>
                        <Controller
                          control={kidsFormCtrl}
                          name="kidlastname"
                          rules={{ required: "Last name is required" }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextField
                              id="kidlastname"
                              label="Last name"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={kidsFormErrors.kidlastname}
                              {...kidsFormReg("kidlastname")}
                              helperText={kidsFormErrors.kidlastname?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      className="mb-3"
                      id="submit-button"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </Button>
                  </form>
                </div>
              )}
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={myKidHandleCloseDialog} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSaveChanges} color="primary">
                  Save
                </Button>
              </DialogActions> */}
          </Dialog>
        </div>
      </div>

    );
  };

  // MyInvites
  const MyInvites = () => {
    return (
      <div>
        <Typography variant="h6" margin="dense">
          My Invites
        </Typography>

        <TableContainer className="mt-2">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>First Name</strong></TableCell>
                <TableCell><strong>Last Name</strong></TableCell>
                <TableCell><strong>Invited Date</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myChatInvites.length === 0 ? (
                // Display a message if no records are found
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px", fontSize: "18px", fontWeight: "bold" }}>
                    No Invites Found
                  </TableCell>
                </TableRow>
              ) : (
                myChatInvites.map((obj, index) => (
                  <TableRow key={index}>

                    <TableCell>
                      {obj?.invitedUser?.firstname}
                    </TableCell>
                    <TableCell>{obj?.invitedUser?.lastname}</TableCell>
                    <TableCell> {moment(obj?.inviteDetail?.createdAt).format("D/MM/YYYY")}</TableCell>
                    <TableCell> {obj?.inviteDetail?.status == 1 ? "Invited" : obj?.inviteDetail?.status == 2 ? "Accepted" : "Rejected"}</TableCell>
                    <TableCell>
                      {obj?.inviteDetail?.status == 1 ? <Button className="manage-invite-btn me-2" id="cancel-submit-button" variant="contained" type="button" onClick={(e) => acceptChatInvite(e, obj)}>
                        Accept
                      </Button> : ""}

                      {obj?.inviteDetail?.status == 1 ? <Button className="manage-invite-reject-btn me-2" id="cancel-submit-button" variant="contained" type="button" onClick={(e) => rejectChatInvite(e, obj)}>
                        Reject
                      </Button> : ""}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </div>

    );
  };

  // MyInvites END

  const onAddKidsSubmit = (data) => {
    console.log(data)
    var addKidData = {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      email: getCurrentUserData().email,
      password: data.password,
      parentid: getCurrentUserData().id.toString(),
      relationship: "2",
      createdby: getCurrentUserData().id
    };
    console.log("submit add kid::", addKidData);
    console.log("submit add kid:data:", data);

    MemberService.createLearner(addKidData)
      .then((response) => {
        if (response && response.success === "true") {
          console.log(response);
          const createSelfLearningLearnersDetailModel = {
            course_id: 1,
            learner_id: response.data.id,
            status: 1,
            createdby: getCurrentUserData().id,
          }

          SelfLearningService.createSelfLearningLearners(createSelfLearningLearnersDetailModel).then(response => {
            if (response && response.data && response.success == "true") {
              // variant could be success, error, warning, info, or default
              enqueueSnackbar("Kids Added Sucessfully", {
                variant: "success",
                key: "topright",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              window.location.reload();
            } else {
              enqueueSnackbar("Kids were not added successfully", {
                variant: "warning",
                key: "topright",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          })
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const addKids = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={addKidFormSubmit(onAddKidsSubmit)}>
            <Grid container spacing={1}>
              {/*    Kid Information */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" margin="dense">
                  Kid Information
                </Typography>
              </Grid>

              {/* first_name */}
              <Grid item xs={12} sm={12}>
                <Controller
                  control={addKidFormCtrl}
                  name="firstname"
                  rules={{ required: "First name is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="firstname"
                      label="First name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={addKidFormErrors.firstname}
                      {...addKidFormReg("firstname")}
                      helperText={addKidFormErrors.firstname?.message}
                    />
                  )}
                />
              </Grid>

              {/* LAST NAME TEXT FIELD */}
              <Grid item xs={12} sm={12}>
                <Controller
                  control={addKidFormCtrl}
                  name="lastname"
                  rules={{ required: "Last name is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="lastname"
                      label="Last name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={addKidFormErrors.lastname}
                      {...addKidFormReg("lastname")}
                      helperText={addKidFormErrors.lastname?.message}
                    />
                  )}
                />
              </Grid>

              {/* USER NAME TEXT FIELD */}
              <Grid item xs={12} sm={12}>
                <Controller
                  control={addKidFormCtrl}
                  name="username"
                  rules={{ required: "Username is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="username"
                      label="User name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={addKidFormErrors.username}
                      {...addKidFormReg("username")}
                      helperText={
                        addKidFormErrors.username
                          ? addKidFormErrors.username.message
                          : "Username must be 4-15 characters and alphanumeric"
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controller
                  control={addKidFormCtrl}
                  name="password"
                  rules={{ required: "Password is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="password"
                      label="Password"
                      type={showAddKidPassword ? 'text' : 'password'}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      onCopy={(e) => e.preventDefault()} // Disable copy
                      onPaste={(e) => e.preventDefault()} // Disable paste
                      error={addKidFormErrors.password}
                      {...addKidFormReg("password")}
                      helperText={addKidFormErrors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowAddKidPassword(!showAddKidPassword)} edge="end">
                              <Iconify icon={showAddKidPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              className="mb-3"
              id="submit-button"
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    )
  }
  //#endregion

  const changeAddKidPopupOpen = (e) => {
    setAddKidOpen(true);
  };
  const changeAddKidPopupClose = () => {
    setAddKidOpen(false);
  };

  const navigateTo = (page) => {
    if (page) {
      navigate(page);
    }
  };

  const acceptChatInvite = (e, obj) => {
    if (obj) {
      if (myKidsList.length > 0) {
        for (let i = 0; i < myKidsList.length; i++) {
          console.log(myKidsList[i]);
          // console.log(obj.inviteDetail);
          var inviteDetailModel = obj.inviteDetail
          inviteDetailModel.friend_id = myKidsList[i].id;
          inviteDetailModel.username = myKidsList[i].username;
          console.log(inviteDetailModel);
          SelfLearningService.acceptChatInvite(inviteDetailModel)
            .then(response => {
              // console.log("acceptChatInvite",response)
              localStorage.removeItem("my-invites")
              window.location.reload();
            })
            .catch(error => {
              console.log(error)
            });
        }
      } else {
        enqueueSnackbar("Please add kids from My Kids tab. Then Accept the invite.", {
          variant: "warning",
          key: "topright",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    }
  }

  const rejectChatInvite = (e, obj) => {
    if (obj) {
      Swal.fire({
        title: "Are you sure do you want to reject the invite?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          var inviteDetailModel = obj.inviteDetail;
          inviteDetailModel.updatedby = getCurrentUserData().id;
          SelfLearningService.rejectChatInvite(inviteDetailModel)
            .then(response => {
              localStorage.removeItem("my-invites")
              window.location.reload();
            })
            .catch(error => {
              console.log(error)
            });
        }
      });
    }
  }


  //opt 
  // opt

  const [openOtpPopup, setOpenOtpPopup] = useState(false);

  const [otpError, setOtpError] = useState('');
  // Open the OTP popup

  const handleOpenOtpPopup = () => {
    var userOptData = {
      memberid: getCurrentUserData().id.toString(),
    };

    ParentService.generateOtp(userOptData)
      .then((response) => {
        if (response.success) {
          // Show a success message to prompt the user to check their email for the OTP
          enqueueSnackbar("OTP sent successfully! Please check your registered email.", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          setOtpError('Failed to generate OTP. Please try again.');
        }
      })
      .catch(() => {
        setOtpError('Error generating OTP, please try again.');
      });

    setOpenOtpPopup(true);
  };

  // Close the OTP popup
  const handleCloseOtpPopup = () => {
    setOpenOtpPopup(false);
  };




  return (
    <div>
      <div>
        <Paper elevation={0} className="Account-Information-div-up">
          <Container>
            <p className="Account-Information-text-up mb-0 ms-4">
              Account Information
            </p>


          </Container>
        </Paper>
      </div>
      <Container className="mt-3">
        <div className="row ms-0 me-0">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <Card className="mb-3">
              {imageDocumentPath && (
                <div className="profile-card">
                  <label htmlFor="image-upload" className="profile-image">
                    {imageDocumentPath ? (
                      <img src={imageDocumentPath} alt="Profile" />
                    ) : (
                      <PhotoCameraIcon sx={{ color: "white" }} />
                    )}
                  </label>
                  <div>
                    {imageDocumentPath ? (
                      <p
                        className="remove-pic-up-kid m-0"
                        onClick={handleRemoveImage}
                      >
                        <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <p className="name-up m-0">{getCurrentUserData()?.name}</p>
                  {/* <p className="email-up m-0">Your Bio</p> */}
                </div>
              )}

              {!imageDocumentPath && (
                <div className="profile-card">
                  <label htmlFor="image-upload" className="profile-image">
                    {image ? (
                      <img src={image} alt="Profile" />
                    ) : (
                      <PhotoCameraIcon sx={{ color: "white" }} />
                    )}
                  </label>
                  <div>
                    {image ? (
                      <p
                        className="remove-pic-up-kid m-0"
                        onClick={handleRemoveImage}
                      >
                        <DeleteIcon style={{ fontSize: "16px" }} /> Remove
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <p className="name-up m-0">{getCurrentUserData()?.name}</p>
                  {/* <p className="email-up m-0">Your Bio</p> */}
                </div>
              )}
            </Card>
          </div>

          <div className="col-lg-9 col-md-9 col-sm-12">
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {infoTabs?.map((obj, index) => {
                    return (
                      <Tab
                        key={`profile-tabs-key-` + index}
                        label={obj.name}
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </Box>

              {getCurrentUserData().role == conditional_configs.role.expert ? (
                // EXPERTS PROFILE
                <div>
                  {/* PERSONAL INFORMATION */}
                  <CustomTabPanel value={tabValue} index={0}>
                    {personaleInfoForm()}
                  </CustomTabPanel>

                  {/* File Upload */}
                  <CustomTabPanel value={tabValue} index={1}>
                    {documentsForm()}
                  </CustomTabPanel>

                  {/* Change Password */}
                  <CustomTabPanel value={tabValue} index={2}>
                    {changePasswordForm()}
                  </CustomTabPanel>

                  {/* Payment Account */}
                  <CustomTabPanel value={tabValue} index={3}>
                    {paymentForm()}
                  </CustomTabPanel>
                </div>
              ) : // PARENT
                getCurrentUserData().role == conditional_configs.role.parent ? (
                  <div>
                    {/* PERSONAL INFORMATION */}
                    <CustomTabPanel value={tabValue} index={0}>
                      {personaleInfoForm()}
                    </CustomTabPanel>

                    {/* Change Password */}
                    <CustomTabPanel value={tabValue} index={1}>
                      {changePasswordForm()}
                    </CustomTabPanel>

                    {/* Change Password */}
                    <CustomTabPanel value={tabValue} index={2}>
                      {Kids()}
                    </CustomTabPanel>

                    {/* Change Password */}
                    <CustomTabPanel value={tabValue} index={3}>
                      {MyInvites()}
                    </CustomTabPanel>
                  </div>
                ) : (
                  //LEARNER
                  <div>
                    {/* PERSONAL INFORMATION */}
                    <CustomTabPanel value={tabValue} index={0}>
                      {personaleInfoForm()}
                    </CustomTabPanel>

                    {/* Change Password */}
                    <CustomTabPanel value={tabValue} index={1}>
                      {changePasswordForm()}
                    </CustomTabPanel>
                  </div>
                )}
            </Card>
          </div>

          {/* MY CHAT INVITES */}

          {/* {getCurrentUserData().role == conditional_configs.role.parent ? (
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="manage-subs-text">My Invites</p>
              <div>
                {myChatInvites.length > 0 ?
                  <Card className="row manage-subs-card mb-2">
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">First Name</div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Last Name</div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Invited Date</div>
                      </div>

                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Status</div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-4 col-lg-4 info-pare">

                      </div>
                    </div>
                    {myChatInvites.map((obj, index) => {
                      return (
                        <Card key={`my-invites-detail-` + index} className="row manage-subs-card mb-2">
                          <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                            {obj?.invitedUser?.firstname}
                          </div>
                          <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                            {obj?.invitedUser?.lastname}
                          </div>
                          <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                            {moment(obj?.inviteDetail?.createdAt).format("D/MM/YYYY")}
                          </div>
                          <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                            {obj?.inviteDetail?.status == 1 ? "Invited" : obj?.inviteDetail?.status == 2 ? "Accepted" : "Rejected"}
                          </div>
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 info-pare">
                            {obj?.inviteDetail?.status == 1 ? <Button className="manage-invite-btn" id="cancel-submit-button" variant="contained" type="button" onClick={(e) => acceptChatInvite(e, obj)}>
                              Accept
                            </Button> : ""}
                            {obj?.inviteDetail?.status == 1 ? <Button className="manage-invite-reject-btn me-2" id="cancel-submit-button" variant="contained" type="button" onClick={(e) => rejectChatInvite(e, obj)}>
                              Reject
                            </Button> : ""}
                          </div>
                        </Card>
                      )
                    })}
                  </Card> :
                  <Card className="row manage-subs-card mb-2">
                    <p>No Invites Found</p>
                  </Card>}
              </div>
            </div>
          ) : ""} */}

          {/* SUBSCRIPTOINS DETAIL IN PARENT VIEW */}
          {getCurrentUserData().role == conditional_configs.role.parent ? (
            <div className="col-lg-12 col-md-12 col-sm-12">

              <div className="buy-new-subscription mt-5 mb-5">
                <p className="manage-subs-text mb-0">Manage Subscriptions</p>
                <button className="buy-new-subscription-btn-cv4 ms-auto" onClick={(e) => navigateTo("/subscription")}>
                  Buy new subscription
                </button>
              </div>
              {/* PARENT SUBSCRIPTION DETAILS */}
              <div>
                {subscriptionDetail.map((obj, index) => {
                  return (
                    <Card key={`my-subs-detail-` + index} className="row manage-subs-card mb-2">
                      <div className="col-6 col-sm-6 col-md-4 col-lg-4 info-pare">
                        <div className="info-title">Subscription Type</div>
                        {obj?.product?.name}
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Billing Plan</div>
                        {getBillingPlanText(obj?.subscription?.plan?.interval)}
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Valid From</div>
                        {convertEpochToDate(obj?.subscription?.current_period_start)}
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Next Billing Due</div>
                        {convertEpochToDate(obj?.subscription?.current_period_end)}
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 info-pare">
                        <div className="info-title">Status</div>
                        {obj?.subscription?.status}
                      </div>
                      {obj?.subscription?.status == "active" || obj?.subscription?.status == "trialing" ? <form>
                        <Button className="manage-subs-btn" id="cancel-submit-button" variant="contained" type="button" onClick={(e) => cancelSubscription(e, obj)}>
                          Cancel
                        </Button>
                      </form> : ""}
                    </Card>
                  )
                })}
              </div>
            </div>
          ) : ""}


        </div>
      </Container>

      {/*Add kid Dialog */}
      <div>
        <Dialog
          open={addKidOpen}
          fullWidth
          onClose={changeAddKidPopupClose}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title1">
            {"Add Kid"}
            <span
              className="add_kids_close_icon"
              onClick={changeAddKidPopupClose}
            >
              <CloseIcon />
            </span>
          </DialogTitle>

          <DialogContent>
            {addKids()}
          </DialogContent>
        </Dialog>
      </div>

      {/*EDIT Dialog */}
      <div>
        <Dialog
          open={changeKidPasswordOpen}
          fullWidth
          onClose={changeKidPasswordPopupClose}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title1">
            {"Change kid Password"}
            <span
              className="add_kids_close_icon"
              onClick={changeKidPasswordPopupClose}
            >
              <CloseIcon />
            </span>
          </DialogTitle>

          <DialogContent>
            <p>{selectedKidName}</p>
            <form onSubmit={kidsFormPasswordSubmit(onKidsPasswordSubmit)}>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={kidsFormPasswordCtrl}
                  name="password"
                  rules={{ required: "password is required" }}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      id="password"
                      label="Password"
                      type={showKidPassword ? 'text' : 'password'}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      onCopy={(e) => e.preventDefault()} // Disable copy
                      onPaste={(e) => e.preventDefault()} // Disable paste
                      error={kidsFormPasswordErrors.password}
                      {...kidsFormPasswordReg("password")}
                      helperText={kidsFormPasswordErrors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowKidPassword(!showKidPassword)} edge="end">
                              <Iconify icon={showKidPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Button
                className="mb-3"
                id="submit-button"
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      {/* Reusable OTP Popup Component */}
      <OtpPopup
        open={openOtpPopup}
        onClose={handleCloseOtpPopup}
      />
    </div>

  );
}
