import React, { useEffect, useState, createRef, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./user-name-details.css";
import {
  Menu,
  MenuItem,
  Typography,
  Stack,
  ListItemIcon,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { getCurrentUserData, getInitialsFromFullName } from "../../utils/util";
import account from "../../_mock/account";
import roleConfig from "../../config/conditional_config";
import AuthService from "../../services/auth/auth.service";
import navigate_route_config from "../../config/navigate_route_config";


import ParentService from "../../services/parent-service";
import { SnackbarProvider, useSnackbar } from "notistack";


const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  //   linkTo: '/',
  // },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "/expert/my-profile",
    role: roleConfig.role.expert,
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "#",
    role: roleConfig.role.admin,
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "/parent/my-profile",
    role: roleConfig.role.parent,
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "/learner/my-profile",
    role: roleConfig.role.learner,
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

export default function UserNameDetails() {

  const { enqueueSnackbar } = useSnackbar();

  const [headerCtrlMenu, setHeaderCtrlMenu] = useState(false);

  const zoomClient = useSelector((state) => state.zoom.zoomClient);

  const navigate = useNavigate();

  const handleHeaderControlMenuClick = (event) => {
    setHeaderCtrlMenu(true);
  };
  const handleHeaderControlMenuClose = () => {
    setHeaderCtrlMenu(false);
  };

  const logOut = () => {
    if (
      getCurrentUserData() &&
      getCurrentUserData().role == roleConfig.role.admin
    ) {
      navigate("/admin-login");
    } else if (
      getCurrentUserData() &&
      getCurrentUserData().role == roleConfig.role.expert
    ) {
      if (zoomClient && zoomClient.stream) {
        navigate("/expert-login");
        zoomClient.leave(true);
      } else {
        navigate("/expert-login");
      }
    } else if (
      getCurrentUserData() &&
      getCurrentUserData().role == roleConfig.role.learner
    ) {
      if (zoomClient && zoomClient.stream) {
        navigate("/");
        zoomClient.leave(true);
      } else {
        navigate("/");
      }
    } else if (
      getCurrentUserData() &&
      getCurrentUserData().role == roleConfig.role.parent
    ) {
      navigate("/");
    } else {
      navigate("/admin-login");
    }
    setHeaderCtrlMenu(null);
    AuthService.logout();
  };

  return (
    <div>
      <div className="large-screen-user-name-details">
        <div className="user-name-container-det">
          <div class="btn-group">
            <span
              className="d-flex user-name-text-div align-items-center"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="me-1 user-name-text">
                {getCurrentUserData().name}
              </span>
              {getCurrentUserData().document_path ? (
                <span className="user-name-icon-det">
                  <img src={getCurrentUserData().document_path} alt="Profile" />
                </span>
              ) : (
                <span className="user-name-icon-det">
                  {getInitialsFromFullName(getCurrentUserData().name)}
                </span>
              )}
            </span>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2" noWrap>
                  {account.displayName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {account.email}
                </Typography>

                {getCurrentUserData().role === roleConfig.role.parent && getCurrentUserData().status_id === 3 ? (
                  <p className="active-your-account-v4">Verify and activate your account in the profile page.</p>
                ) : ""}

              </Box>

              {getCurrentUserData().role == roleConfig.role.learner ? (
                <div>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.learner.missions}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">Missions</Typography>
                    </MenuItem>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.learner.challenges}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">Challenges</Typography>
                    </MenuItem>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.learner.wall_posts}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">Wall</Typography>
                    </MenuItem>
                  </Stack>
                </div>
              ) : (
                ""
              )}

              {getCurrentUserData().role == roleConfig.role.expert ? (
                <div>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.expert.my_courses}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">My Courses</Typography>
                    </MenuItem>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.expert.my_schedule}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">My Schedule</Typography>
                    </MenuItem>
                  </Stack>
                </div>
              ) : (
                ""
              )}

              {getCurrentUserData().role == roleConfig.role.parent ? (
                <div>
                  <Divider sx={{ borderStyle: "dashed" }} />

                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.parent.self_learning}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">Missions</Typography>
                    </MenuItem>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.parent.self_learning_boards}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">Challenges</Typography>
                    </MenuItem>
                  </Stack>

                  {/* <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.parent.my_kids}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">My Kids</Typography>
                    </MenuItem>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack sx={{ p: 1 }}>
                    <MenuItem
                      to={navigate_route_config.parent.my_courses}
                      component={RouterLink}
                    >
                      <Typography variant="inherit">My Courses</Typography>
                    </MenuItem>
                  </Stack> */}
                </div>
              ) : (
                ""
              )}

              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack sx={{ p: 1 }}>
                {MENU_OPTIONS.map((option, index) => (
                  <div key={"menus-options-" + index}>
                    {option.role == getCurrentUserData().role ? (
                      <MenuItem
                        key={"m" + index}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleHeaderControlMenuClose}
                      >
                        {option.label}
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </Stack>

              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack sx={{ p: 1 }}>
                <MenuItem onClick={logOut}>
                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Stack>
            </ul>
          </div>

          <Menu
            className="mt-5"
            open={headerCtrlMenu}
            onClose={handleHeaderControlMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          ></Menu>
        </div>
      </div>

      <div className="mobile-screen-user-name-details">
        <div className="user-name-container-det">
          <span className="me-1 user-name-text">
            {getCurrentUserData().name}
          </span>

          {getCurrentUserData().document_path ? (
            <span
              className="user-name-icon-det"
              onClick={handleHeaderControlMenuClick}
            >
              <img src={getCurrentUserData().document_path} alt="Profile" />
            </span>
          ) : (
            <span
              className="user-name-icon-det"
              onClick={handleHeaderControlMenuClick}
            >
              {getInitialsFromFullName(getCurrentUserData().name)}
            </span>
          )}

          <Menu
            className="mt-5"
            open={headerCtrlMenu}
            onClose={handleHeaderControlMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {account.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {account.email}
              </Typography>

              {getCurrentUserData().role === roleConfig.role.parent && getCurrentUserData().status_id === 3 ? (
                <p className="active-your-account-v4">Verify and activate your account in the profile page.</p>
              ) : ""}
            </Box>

            {getCurrentUserData().role == roleConfig.role.learner ? (
              <div>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.learner.missions}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">Missions</Typography>
                  </MenuItem>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.learner.challenges}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">Challenges</Typography>
                  </MenuItem>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.learner.wall_posts}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">Wall</Typography>
                  </MenuItem>
                </Stack>
              </div>
            ) : (
              ""
            )}

            {getCurrentUserData().role == roleConfig.role.expert ? (
              <div>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.expert.my_courses}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">My Courses</Typography>
                  </MenuItem>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.expert.my_schedule}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">My Schedule</Typography>
                  </MenuItem>
                </Stack>
              </div>
            ) : (
              ""
            )}

            {getCurrentUserData().role == roleConfig.role.parent ? (
              <div>
                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.parent.self_learning}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">Missions</Typography>
                  </MenuItem>
                </Stack>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Stack sx={{ p: 1 }}>
                  <MenuItem
                    to={navigate_route_config.parent.self_learning_boards}
                    component={RouterLink}
                  >
                    <Typography variant="inherit">Challenges</Typography>
                  </MenuItem>
                </Stack>
              </div>
            ) : (
              ""
            )}

            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option, index) => (
                <div key={"menus-options-" + index}>
                  {option.role == getCurrentUserData().role ? (
                    <MenuItem
                      key={"m" + index}
                      to={option.linkTo}
                      component={RouterLink}
                      onClick={handleHeaderControlMenuClose}
                    >
                      {option.label}
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: "dashed" }} />
            <Stack sx={{ p: 1 }}>
              <MenuItem onClick={logOut}>
                <Typography variant="inherit">Logout</Typography>
              </MenuItem>
            </Stack>
          </Menu>
        </div>
      </div>
    </div>
  );
}
