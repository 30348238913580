import React, { useState, useEffect } from "react";
import { Grid, InputBase, Paper, IconButton, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Box, Card, Typography, Avatar } from "@mui/material";
import WallPostService from "../../../../services/wall-post-service";
import assets from "../../../../config/assets";

import { useSnackbar } from "notistack";
import { CircularProgress } from '@mui/material'; // For the loader spinner
import { snackbar_options } from "../../../../config/snackbar_options";
import './wall-posts.css';
import WallPostCommonCard from "../../../../components/wall-post-common-card/wall-post-common-card";

export default function WallPost() {
    const [wallPosts, setWallPosts] = useState([]); // Filtered list of posts
    const [originalWallPosts, setOriginalWallPosts] = useState([]); // Original list of posts
    const [searchQuery, setSearchQuery] = useState(""); // Search query
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [isLoading, setIsLoading] = useState(true); // Loader state
    const itemsPerPage = 5; // Posts per page
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        getAllWallPosts();
    }, []);

    // Fetch wall posts
    const getAllWallPosts = () => {
        setIsLoading(true); // Show loader while fetching data
        WallPostService.getAllPublishedWallPosts()
            .then((response) => {
                if (response?.success) {
                    const formattedData = response.data.map((post) => ({
                        ...post,
                        formatted_created_at: formatDateTime(post.created_at),
                    }));
                    setWallPosts(formattedData);
                    setOriginalWallPosts(formattedData);
                } else {
                    console.error("Failed to fetch wall posts:", response?.data || "Unknown error");
                }
            })
            .catch((error) => {
                console.error("Error fetching wall posts:", error);
            }).finally(() => {
                setIsLoading(false); // Hide loader after data is fetched
            });
    };

    // Format date and time
    const formatDateTime = (isoDate) => {
        const date = new Date(isoDate);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
        }).format(date);

        const formattedTime = new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        }).format(date);

        return `${formattedDate}  |  ${formattedTime}`;
    };

    // Handle search input change
    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (!query.trim()) {
            // Reset to original posts if search box is empty
            setWallPosts(originalWallPosts);
            return;
        }

        const filteredPosts = originalWallPosts.filter((post) =>
            post.userName?.toLowerCase().includes(query.toLowerCase())
        );
        setWallPosts(filteredPosts);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    // Handle page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Pagination logic
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedPosts = wallPosts.slice(startIndex, endIndex);



    const deleteYourPost = (id) => {
        WallPostService.deletePost(id)
            .then((data) => {
                console.log(data);
                enqueueSnackbar(
                    "Delete your post successfully",
                    snackbar_options.success
                );
                getAllWallPosts();
            })
            .catch((error) => {
                console.error("There was an error deleting the post:", error);
                // Handle error, show a message to the user
            });
    };

    return (
        <div>
            {isLoading ? (
                // Loader Spinner
                <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                // Main Content
                <div className="wall-post-bg-color">
                    <img
                        className=""
                        width="100%"
                        src={assets.course_v4.wall_post_banner_img}
                        alt="Banner"
                    />
                    <Container className="self-learning-main-container pt-3 pb-3">
                        <div className="d-flex align-items-center">
                            <Typography variant="h4" className="missions-text-title-cgv4 mt-2">
                                WALL
                            </Typography>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="ms-auto">
                                <Paper
                                    component="form"
                                    className="wall-post-search-box"
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <InputBase
                                        className="wall-post-search-input"
                                        placeholder="Search ..."
                                        inputProps={{ "aria-label": "Search ..." }}
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                    />
                                    <IconButton aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </div>

                        <WallPostCommonCard
                            paginatedPosts={paginatedPosts}
                            itemsPerPage={itemsPerPage}
                            wallPosts={wallPosts}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            deleteYourPost={deleteYourPost}
                        />


                    </Container>
                </div>
            )}
        </div>
    );
}
